import styles from './TabMenu.module.scss';

const TabMenu = ({tabList, activeTab, onClick }) => {
  return (
    <div className={styles.container}>
      {tabList.map(item => {
        const style = item.value === activeTab
          ? `${styles.tab} ${styles.active}` : `${styles.tab}`;
        return (
          <div
            className={style}
            key={item.value}
            role="presentation"
            onClick={() => onClick(item.value)}
          >
            {item.label}
          </div>
        );
      })}

    </div>
  );
};

export default TabMenu;
