import axios from 'axios';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

$api.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

    return config;
  }

  return null;
});

$api.interceptors.response.use((config) => {
  return config;
}, async (error) => {
  if (error.code === 'ERR_CANCELED') {
    return;
  }

  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/refresh`, {withCredentials: true});
      localStorage.setItem('token', response.data.accessToken);
      return $api.request(originalRequest);
    } catch (e) {
      console.log('Not authorized!');
    }
  }

  throw error;
});

export default $api;