import { useDispatch, useSelector } from 'react-redux';
import StringBlock from '../../../../common/components/StringBlock/StringBlock';
import { premDescriptionSet, premLinkSet, premLogoSet } from '../../../../store/slices/profile/profileSlice';
import { store } from '../../../../store/store';
import { notifySet } from '../../../../store/slices/modals/modalSlice';
import styles from './PremiumParams.module.css';

const PremiumParams = () => {
  const { premiumParams } = useSelector(state => state.profile);
  const dispatch = useDispatch();

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }
  
  const handleChange = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileExtension = file?.name.split('.').at(-1);
    const allowedFileTypes = ['jpg'];

    if (!allowedFileTypes.includes(fileExtension)) {
      store.dispatch(notifySet({
        type: 'error',
        message: 'Можно добавить только файл .jpg!',
        isShow: true
      }));

      return;
    }

    if (file.size > 200000) {
      store.dispatch(notifySet({
        type: 'error',
        message: 'Файл не должен быть больше 200кб!',
        isShow: true
      }));

      return;
    }

    await getBase64(file)
      .then(res => {
        dispatch(premLogoSet(res));
      })
      .catch(err => console.log(err));
  };

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <StringBlock
          label="Сайт"
          onChange={premLinkSet}
          defaultValue={premiumParams.link || ''}
          placeholder="Введите адрес сайта"
        />
        <StringBlock
          label="Описание"
          onChange={premDescriptionSet}
          defaultValue={premiumParams.description || ''}
          placeholder="Введите описание компании"
        />
      </div>
      <div className={styles.logo}>
        <div className={styles}>Логотип (файл .jpg, не больше 200kb)</div>
        <input type="file" onChange={handleChange} />
        <img className={styles.image} src={premiumParams.logo} alt="логотип" />
      </div>
    </div>
  );
};

export default PremiumParams;

