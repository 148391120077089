const SuccessIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.24219" y="4.24512" width="43.5102" height="43.5102" rx="7" fill="#B1E0DB"/>
      <path d="M18 26.8824L24.8571 33L34 20" stroke="#226457" strokeWidth="1.35" strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
};

export default SuccessIcon;