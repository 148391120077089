import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  liftingHoistType: {
    label: 'Тип тали по конструкции подъема',
    value: 'нормальная высота (стандарт)'
  },

  liftingSpeed: {
    label: 'Скорость подъма, м/мин',
    value: '8 м/мин',
    customLabel: 'Скорость подъма по данным Заказчика, м/мин',
    customValue: ''
  },

  liftingMicroSpeed: {
    label: 'Микроскорость подъема, м/мин',
    value: 'не требуется',
    customLabel: 'Микроскорость подъма по данным Заказчика, м/мин',
    customValue: ''
  },

  hoistSpeed: {
    label: 'Скорость передвижения тали, м/мин',
    value: '20 м/мин',
    customLabel: 'Скорость передвижения тали по данным Заказчика, м/мин',
    customValue: ''
  },

  hoistMicroSpeed: {
    label: 'Микроскорость передвижения тали, м/мин',
    value: 'не требуется',
    customLabel: 'Скорость передвижения тали по данным Заказчика, м/мин',
    customValue: ''
  },

  workingMode: {
    label: 'Режим работы по ИСО 4301/1',
    value: 'не задано',
    customLabel: 'Режим работы по данным Заказчика',
    customValue: ''
  },

  hoistPowerWire: {
    label: 'Токоподвод тали',
    value: 'не задано'
  },

  speedLiftingControl: {
    label: 'Частотное регулирование скорости подъема',
    value: 'нет'
  },

  speedControl: {
    label: 'Частотное регулирование скорости передвижение',
    value: 'нет'
  }
};

export const hoistParamsSlice = createSlice({
  name: 'hoistParams',
  initialState,
  reducers: {
    liftingHoistTypeSet: (state, action) => {
      state.liftingHoistType.value = action.payload;
    },
    liftingSpeedSet: (state, action) => {
      state.liftingSpeed.value = action.payload;
    },
    liftingSpeedCustomSet: (state, action) => {
      state.liftingSpeed.customValue = action.payload;
    },
    liftingMicroSpeedSet: (state, action) => {
      state.liftingMicroSpeed.value = action.payload;
    },
    liftingMicroSpeedCustomSet: (state, action) => {
      state.liftingMicroSpeed.customValue = action.payload;
    },
    hoistSpeedSet: (state, action) => {
      state.hoistSpeed.value = action.payload;
    },
    hoistSpeedCustomSet: (state, action) => {
      state.hoistSpeed.customValue = action.payload;
    },
    hoistMicroSpeedSet: (state, action) => {
      state.hoistMicroSpeed.value = action.payload;
    },
    hoistMicroSpeedCustomSet: (state, action) => {
      state.hoistMicroSpeed.customValue = action.payload;
    },
    workingModeSet: (state, action) => {
      state.workingMode.value = action.payload;
    },
    workingModeCustomSet: (state, action) => {
      state.workingMode.customValue = action.payload;
    },
    hoistPowerWireSet: (state, action) => {
      state.hoistPowerWire.value = action.payload;
    },
    speedLiftingControlSet: (state, action) => {
      state.speedLiftingControl.value = action.payload;
    },
    speedControlSet: (state, action) => {
      state.speedControl.value = action.payload;
    },
    hoistParamsSet: (state, action) => {
      state.liftingHoistType.value = action.payload.liftingHoistType.value;

      state.liftingSpeed.value = action.payload.liftingSpeed.value;
      state.liftingSpeed.customValue = action.payload.liftingSpeed.customValue;

      state.liftingMicroSpeed.value = action.payload.liftingMicroSpeed.value;
      state.liftingMicroSpeed.customValue = action.payload.liftingMicroSpeed.customValue;

      state.hoistSpeed.value = action.payload.hoistSpeed.value;
      state.hoistSpeed.customValue = action.payload.hoistSpeed.customValue;

      state.hoistMicroSpeed.value = action.payload.hoistMicroSpeed.value;
      state.hoistMicroSpeed.customValue = action.payload.hoistMicroSpeed.customValue;

      state.workingMode.value = action.payload.workingMode.value;
      state.workingMode.customValue = action.payload.workingMode.customValue;

      state.hoistPowerWire.value = action.payload.hoistPowerWire.value;
      state.speedLiftingControl.value = action.payload.speedLiftingControl.value;
      state.speedControl.value = action.payload.speedControl.value;
    }
  }
});

export const {
  liftingHoistTypeSet,
  liftingSpeedSet,
  liftingSpeedCustomSet,
  liftingMicroSpeedSet,
  liftingMicroSpeedCustomSet,
  hoistSpeedSet,
  hoistSpeedCustomSet,
  hoistMicroSpeedSet,
  hoistMicroSpeedCustomSet,
  workingModeSet,
  workingModeCustomSet,
  hoistPowerWireSet,
  speedLiftingControlSet,
  speedControlSet,
  hoistParamsSet
} = hoistParamsSlice.actions;

export default hoistParamsSlice.reducer;
