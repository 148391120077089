export const liftingHoistTypeItems = [
  'нормальная высота (стандарт)',
  'уменьшенная строительная высота'
];

export const liftingSpeedItems = [
  '8 м/мин',
  '4 м/мин',
  'другое'
];

export const liftingMicroSpeedItems = [
  'не требуется',
  '1 м/мин',
  'другое'
];

export const hoistSpeedItems = [
  '20 м/мин',
  'другое'
];

export const hoistMicroSpeedItems = [
  'не требуется',
  '1 м/мин',
  'другое'
];

export const workingModeItems = [
  'не задано',
  'М3',
  'М5',
  'М7',
  'М8'
];

export const hoistPowerWireItems = [
  'не задано',
  'кабельный по струне',
  'кабельный по С-профилю'
];

export const speedLiftingControlItems = [
  'нет',
  'есть'
];

export const speedControlItems = [
  'нет',
  'есть'
];
