import {useSelector} from 'react-redux';
import Contacts from './components/Contacts/Contacts';
import Person from './components/Person/Person';
import Password from './components/Password/Password';
import styles from './Profile.module.scss';
import PremiumIcon from '../../common/icons/PremiumIcon/PremiumIcon';
import PremiumParams from './components/PremiumParams/PremiumParams';
import { updateProfile } from '../../service/user';
import { useState } from 'react';

const Profile = ({ guideStep }) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    email,
    newPassword,
    confirmPassword,
    name,
    phone,
    company,
    premiumParams
  } = useSelector(state => state.profile);

  const onChangeCheckbox = (value) => {
    setIsChecked(value);

    if(value){
      localStorage.setItem('isNewsShow', 'false');
    }
    if(!value){
      localStorage.setItem('isNewsShow', 'true');
    }
  };

  const onClickHandler = () => {
    if (newPassword !== confirmPassword) {
      return;
    }

    updateProfile(
      email,
      newPassword,
      name,
      phone,
      company,
      premiumParams
    ).then();
  };

  return (
    <div className={guideStep === 5 ? `${styles.container} ${styles.guide}` : styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Профиль</div>
        <div className={styles.premium}>
          {premiumParams.isActive && <PremiumIcon />}
          {premiumParams.isActive && <div>Премиум-аккаунт</div>}
        </div>
      </div>
      <div className={styles.scroll}>
        {premiumParams.isActive && (
          <PremiumParams />
        )}
        <Person />
        <Contacts />
        <Password />
      </div>
      <div className={styles.footer}>
        <div
          className={styles.button}
          role="presentation"
          onClick={onClickHandler}
        >
          Сохранить
        </div>
        <div
          className={styles.checkbox}
          onClick={() => onChangeCheckbox(!isChecked)}
        >
          <input
            type="checkbox"
            checked={isChecked}
            readOnly={true}
            className={styles.checkboxInput}
          />
          <div>Отключить отображение новостей</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;