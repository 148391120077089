import styles from '../MassTable/MassTable.module.scss';

const MassTable = ({ title, labels, data }) => {

  const dataSource = Object.entries(data).map(([capacityKey, capacityValue]) => {
    return {
      capacityKey,
      capacityValue: Object.entries(capacityValue).map(([spanKey, spanValue], index) => ({spanKey, spanValue, index}))
        .sort((a, b) => parseFloat(a.spanKey) - parseFloat(b.spanKey))
    };
  }).sort((a, b) => parseFloat(a.capacityKey) - parseFloat(b.capacityKey));

  return (
    <div className={styles.panel}>
      <div className={styles.title}>{title}</div>
      <div className={styles.table}>
        <div className={styles.row}>
          {labels.map(item => <div className={styles.label} key={item}>{item}</div>)}
        </div>
        {dataSource.map(row => (
          <div key={row.capacityKey}>
            {row.capacityValue.map(item => (
              <div className={styles.row}  key={item.spanKey}>
                <div className={styles.field}>{row.capacityKey}</div>
                <div className={styles.field}>{item.spanKey}</div>
                <div className={styles.field}>{item.spanValue.craneMass}</div>
                <div className={styles.field}>{item.spanValue.hoistMass}</div>
                <div className={styles.field}>{item.spanValue.wheelLoad}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MassTable;
