const ExportIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 9C18.1046 9 19 9.89543 19 11V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046
       5 19V11C5 9.89543 5.89543 9 7 9M12 3L12.0001 15M12.0001 15L9 12.1364M12.0001 15L15 12.1364"
      stroke="#3D487C" strokeWidth="1.25" strokeLinecap="round"/>
    </svg>
  );
};

export default ExportIcon;
