import styles from './FeedbackList.module.scss';
import DownIcon from '../../../../common/icons/DownIcon/DownIcon';
import ListItem from './ListItem/ListItem';

const FeedbackList = ({ data, activeItemId, onClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Все сообщения
        <DownIcon />
      </div>
      <div className={styles.list}>
        {data.map(item => {
          return (
            <div
              key={item.id}
              onClick={() => onClick(item)}
            >
              <ListItem
                data={item}
                activeItemId={activeItemId}
                hasReadByUser={item.hasReadByUser}
              />
            </div>  
          );
        })}
      </div>
    </div>
  );
};

export default FeedbackList;