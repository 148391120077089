import {useEffect, useState} from 'react';
import styles from './ProfileMenu.module.scss';
import ProfileIcon from './icons/ProfileIcon/ProfileIcon';
import UpIcon from '../../common/icons/UpIcon/UpIcon';
import DownIcon from '../../common/icons/DownIcon/DownIcon';
import { logout } from '../../service/auth';
import { store } from '../../store/store';
import { notifySet } from '../../store/slices/modals/modalSlice';
import { useSelector } from 'react-redux';
import {stringLimiter} from '../../common/utils/stringLimiter';
import SettingsIcon from './icons/SettingsIcon/SettingsIcon';
import QlistsIcon from './icons/QlistsIcon/QlistsIcon';
import FeedbackIcon from './icons/FeedbackIcon/FeedbackIcon';
import ExitIcon from './icons/ExitIcon/ExitIcon';

const ProfileMenu = ({ onMenuClick, isSideOpen, guideStep }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const { name, email } = useSelector(state => state.profile);
  const { feedbackList } = useSelector(state => state.feedback);

  const containerStyle = isProfileOpen ? `${styles.container} ${styles.open}` : `${styles.container} ${styles.close}`;
  const unreadFeedbackCount = feedbackList.filter(item => item.hasReadByUser === false).length;
  const labelStyle = isSideOpen ? styles.fadeIn : styles.fadeOut;

  useEffect(() => {
    guideStep === 4 && setIsProfileOpen(true);
    guideStep === 5 && setIsProfileOpen(false);
  }, [guideStep]);

  const onFeedbackMenuHandler = () => {
    if (name) {
      onMenuClick('feedback');

      return;
    }

    store.dispatch(notifySet({
      type: 'warning',
      message: 'Для отправки обратной связи укажите, пожалуйста,в профиле свое имя',
      isShow: true
    }));
    onMenuClick('profile');
  };

  return (
    <div className={isSideOpen ? containerStyle : `${containerStyle} ${styles.containerClose}`}>
      <div className={isSideOpen ? styles.line : `${styles.line} ${styles.lineClose}`}/>
      <div
        className={styles.header}
        role="presentation"
        onClick={() => isSideOpen && setIsProfileOpen(!isProfileOpen)}
      >
        <div
          className={styles.icon}
          onClick={() => !isSideOpen && setIsProfileOpen(!isProfileOpen)}
        >
          <ProfileIcon />
        </div>
        <div className={`${styles.name} ${labelStyle}`}>
          {stringLimiter(name || email, 20)}
          <div>{isProfileOpen ? <DownIcon /> : <UpIcon/>}</div>
        </div>
      </div>
      <div className={isSideOpen ? styles.line : `${styles.line} ${styles.lineClose}`}/>
      <div className={isSideOpen ? styles.menu : `${styles.menu} ${styles.menuClose}`}>
        <div
          className={styles.item}
          onClick={() => onMenuClick('profile')}
        >
          <SettingsIcon />
          <div className={labelStyle}>Профиль</div>
        </div>
        <div
          className={styles.item}
          onClick={() => onMenuClick('questionList')}
        >
          <QlistsIcon />
          <div className={labelStyle}>Опросные листы</div>
        </div>
        <div
          className={styles.item}
          onClick={onFeedbackMenuHandler}
        >
          <FeedbackIcon />
          <div className={labelStyle}>Обратная связь</div>
          {unreadFeedbackCount > 0 && (<div className={styles.circle}>{unreadFeedbackCount}</div>)}
        </div>
        <div
          className={`${styles.item} ${styles.exit}`}
          onClick={logout}
        >
          <ExitIcon />
          <div className={labelStyle}>Выход</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileMenu;