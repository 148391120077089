export const overheadOneSpanGeometricParams = {
  '0.5': {
    a: 1710,
    b: 700,
    h: 600,
    tai: 840,
    h1: 1440,
    c1: 215
  },
  '1': {
    a: 1710,
    b: 700,
    h: 600,
    tai: 840,
    h1: 1440,
    c1: 215
  },
  '2': {
    a: 1710,
    b: 700,
    h: 780,
    tai: 840,
    h1: 1620,
    c1: 225
  },
  '3.2': {
    a: 2100,
    b: 1050,
    h: 1025,
    tai: 1200,
    h1: 2225,
    c1: 240
  },
  '5': {
    a: 2100,
    b: 1050,
    h: 1025,
    tai: 1200,
    h1: 2225,
    c1: 240
  },
  '10': {
    a: 2900,
    b: 2200,
    h: 1100,
    tai: 1350,
    h1: 2450,
    c1: 250
  },
  '12.5': {
    a: 2900,
    b: 2200,
    h: 1100,
    tai: 2100,
    h1: 3200,
    c1: 250
  },
  '16': {
    a: 2900,
    b: 2500,
    h: 1300,
    tai: 2100,
    h1: 2100,
    c1: 260
  }
};

export const pylonGeometricParameters = {
  '0.5': {
    a: 3300,
    b: 2600,
    h: 990,
    tai: 840,
    h1: 840,
    c1: 150
  },
  '1': {
    a: 3300,
    b: 2600,
    h: 990,
    tai: 840,
    h1: 840,
    c1: 150
  },
  '2': {
    a: 3300,
    b: 2600,
    h: 990,
    tai: 840,
    h1: 840,
    c1: 150
  },
  '3.2': {
    a: 3300,
    b: 2600,
    h: 1100,
    tai: 1200,
    h1: 1200,
    c1: 150
  },
  '5': {
    a: 3300,
    b: 2600,
    h: 1100,
    tai: 1200,
    h1: 1200,
    c1: 150
  },
  '10': {
    a: 3300,
    b: 2500,
    h: 1100,
    tai: 1350,
    h1: 1350,
    c1: 340
  },
  '12.5': {
    a: 3100,
    b: 2500,
    h: 1200,
    tai: 2100,
    h1: 2100,
    c1: 340
  },
  '16': {
    a: 3100,
    b: 2500,
    h: 1300,
    tai: 2100,
    h1: 2100,
    c1: 350
  }
};

export const overheadTwoSpanGeometricParams = {
  '0.5': {
    a: 1710,
    b: 700,
    h: 600,
    tai: 840,
    h1: 1440,
    c1: 215
  },
  '1': {
    a: 1710,
    b: 700,
    h: 600,
    tai: 840,
    h1: 1440,
    c1: 215
  },
  '2': {
    a: 1710,
    b: 700,
    h: 780,
    tai: 840,
    h1: 1620,
    c1: 225
  },
  '3.2': {
    a: 2100,
    b: 1050,
    h: 1025,
    tai: 1200,
    h1: 2225,
    c1: 240
  },
  '5': {
    a: 2100,
    b: 1050,
    h: 1025,
    tai: 1200,
    h1: 2225,
    c1: 240
  },
  '10': {
    a: 2900,
    b: 2200,
    h: 1100,
    tai: 1350,
    h1: 2450,
    c1: 250
  },
  '12.5': {
    a: 2900,
    b: 2200,
    h: 1100,
    tai: 2100,
    h1: 3200,
    c1: 250
  },
  '16': {
    a: 2900,
    b: 2200,
    h: 1100,
    tai: 2100,
    h1: 32100,
    c1: 260
  }
};

