import styles from './SubMenu.module.css';

const SubMenu = ({ subMenu, markedSubMenuIndex, onSubMenuClick, activeMenuIndex }) => {
  return (
    <div className={styles.container}>
      {subMenu.map(item => {
        const style = item.id === markedSubMenuIndex ? `${styles.item} ${styles.active}` : `${styles.item}`;

        return (
          <div
            key={item.id}
            className={style}
            role="presentation"
            onClick={() => onSubMenuClick({ ...activeMenuIndex, next: item.id})}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default SubMenu;
