import axios from 'axios';
import $api from '../http/api';
import { isAuthSet, userSet } from '../store/slices/profile/profileSlice';
import { modalWindowSet, notifySet } from '../store/slices/modals/modalSlice';
import { store } from '../store/store';

export const login = async (email, password) => {
  let response = {};
  
  try {
    response = await $api.post('/auth/login', { email, password });
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message,
      isShow: true
    }));
    return e.response?.data?.message;
  }
  
  const user = { ...response.data.user};

  if (!user.isActivated) {
    store.dispatch(modalWindowSet({
      type: 'ok',
      page: 'auth',
      title: 'Активируйте учётную запись',
      message: 'Ранее мы отправили вам на почту письмо для подтверждения регистрации. Пожалуйста, перейдите по ссылке в письме.',
      isShow: true
    }));

    return;
  }

  if (user.premiumParams.isActive && user.premiumParams.logo) {
    user.premiumParams.logo = String.fromCharCode.apply(null, user.premiumParams.logo.data);
  }

  localStorage.setItem('token', response.data.accessToken);
  store.dispatch(isAuthSet(true));
  store.dispatch(userSet(user));
};

export const registration = async (email, password) => {
  try {
    const response = await $api.post('/auth/registration', { email, password });
    localStorage.setItem('token', response.data.accessToken);
    store.dispatch(isAuthSet(true));
    store.dispatch(userSet(response.data.user));
    store.dispatch(notifySet({
      type: 'success',
      message: `Успешно!
       Для подтверждения регистрации перейдите по ссылке, отправленной на указанный e-mail`,
      isShow: true
    }));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message,
      isShow: true
    }));
  }
};

export const logout = () => {
  try {
    localStorage.removeItem('token');
    store.dispatch(isAuthSet(false));
    store.dispatch(userSet(null));
  } catch (e) {
    console.log(e.response?.data?.message);
  }
};

export const checkAuth = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/refresh`, { withCredentials: true });
    const user = { ...response.data.user};

    if (!user.isActivated) {
      store.dispatch(modalWindowSet({
        type: 'ok',
        page: 'auth',
        title: 'Активируйте учётную запись',
        message: 'Ранее мы отправили вам на почту письмо для подтверждения регистрации. Пожалуйста, перейдите по ссылке в письме.',
        isShow: true
      }));

      return;
    }

    if (user.premiumParams.isActive && user.premiumParams.logo) {
      user.premiumParams.logo = String.fromCharCode.apply(null, user.premiumParams.logo.data);
    }

    localStorage.setItem('token', response.data.accessToken);
    store.dispatch(isAuthSet(true));
    store.dispatch(userSet(user));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Непредвиденная ошибка при проверке авторизации',
      isShow: true
    }));
  } finally {
    // store.dispatch(setLoading(false));
  }
};