import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  powerWireType: {
    label: 'Тип токоподвода крана',
    value: 'не задано',
    customLabel: 'Тип токоподвода крана по данным Заказчика',
    customValue: ''
  },

  powerWireLength: {
    label: 'Длина токоподвода/ длина подкрановых путей, м',
    value: '0'
  },

  supplyInputCabinet: {
    label: 'Наличие шкафа ввода токоподвода крана',
    value: 'не требуется'
  }
};

export const powerWireSlice = createSlice({
  name: 'powerWire',
  initialState,
  reducers:{
    powerWireTypeSet: (state, action) => {
      state.powerWireType.value = action.payload;
    },
    powerWireTypeCustomSet: (state, action) => {
      state.powerWireType.customValue = action.payload;
    },
    powerWireLengthSet: (state, action) => {
      state.powerWireLength.value = action.payload;
    },
    supplyInputCabinetSet: (state, action) => {
      state.supplyInputCabinet.value = action.payload;
    },
    powerWireSet: (state, action) => {
      state.powerWireType.value = action.payload.powerWireType.value;
      state.powerWireType.customValue = action.payload.powerWireType.customValue;
      state.powerWireLength.value = action.payload.powerWireLength.value;
      state.supplyInputCabinet.value = action.payload.supplyInputCabinet.value;
    }
  }
});

export const {
  powerWireTypeSet,
  powerWireTypeCustomSet,
  powerWireLengthSet,
  supplyInputCabinetSet,
  powerWireSet
} = powerWireSlice.actions;

export default powerWireSlice.reducer;
