import ButtonBlock from '../../../../../../common/components/ButtonBlock/ButtonBlock';
import {
  liftingSpeedItems,
  hoistSpeedItems,
  hoistMicroSpeedItems,
  workingModeItems,
  hoistPowerWireItems,
  speedLiftingControlItems,
  speedControlItems,
  liftingHoistTypeItems,
  liftingMicroSpeedItems
} from '../../../../../../common/consts/hoistParams';
import {
  liftingSpeedSet,
  hoistSpeedSet,
  workingModeSet,
  speedLiftingControlSet,
  liftingHoistTypeSet,
  liftingSpeedCustomSet,
  liftingMicroSpeedCustomSet,
  liftingMicroSpeedSet,
  hoistSpeedCustomSet,
  hoistMicroSpeedSet,
  hoistMicroSpeedCustomSet,
  hoistPowerWireSet,
  speedControlSet,
  workingModeCustomSet
} from '../../../../../../store/slices/params/hoistParamsSlice';
import ParameterBlock from '../../../../../../common/components/ParameterBlock/ParameterBlock';
import { useSelector } from 'react-redux';
import styles from '../../Content.module.scss';
import NumericBlock from '../../../../../../common/components/NumericBlock/NumericBlock';

const HoistParams = () => {
  const {
    liftingHoistType,
    liftingSpeed,
    liftingMicroSpeed,
    hoistSpeed,
    hoistMicroSpeed,
    workingMode,
    speedLiftingControl,
    speedControl,
    hoistPowerWire
  } = useSelector(state => state.hoistParams);

  const {
    loadCapacity,
    liftingHeight
  } = useSelector(state => state.metalConstruction);

  return (
    <>
      <ParameterBlock title={'Параметры тали'}>
        <div className={styles.title}>Грузоподъемность</div>
        <NumericBlock
          defaultValue={loadCapacity.value}
          disabled
        />
        <div className={styles.title}>{liftingHeight.label}</div>
        <NumericBlock
          defaultValue={(liftingHeight.value && liftingHeight.value !== 'другое') ? liftingHeight.value : liftingHeight.customValue}
          disabled
        />
      </ParameterBlock>
      <ParameterBlock
        title={liftingHoistType.label}
        isValid={!!liftingHoistType.value}
      >
        <ButtonBlock
          buttons={liftingHoistTypeItems}
          onChange={liftingHoistTypeSet}
          defaultValue={liftingHoistType.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={liftingSpeed.label}
        isValid={(!!liftingSpeed.value && liftingSpeed.value !== 'другое') || !!liftingSpeed.customValue}
      >
        <ButtonBlock
          buttons={liftingSpeedItems}
          onChange={liftingSpeedSet}
          defaultValue={liftingSpeed.value}
        />
        {liftingSpeed.value === 'другое' && (
          <>
            <div className={styles.title}>{liftingSpeed.customLabel}</div>
            <NumericBlock
              onChange={liftingSpeedCustomSet}
              defaultValue={liftingSpeed.customValue}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={liftingMicroSpeed.label}
        isValid={(!!liftingMicroSpeed.value && liftingMicroSpeed.value !== 'другое') || !!liftingMicroSpeed.customValue}
      >
        <ButtonBlock
          buttons={liftingMicroSpeedItems}
          onChange={liftingMicroSpeedSet}
          defaultValue={liftingMicroSpeed.value}
        />
        {liftingMicroSpeed.value === 'другое' && (
          <>
            <div className={styles.title}>{liftingMicroSpeed.customLabel}</div>
            <NumericBlock
              onChange={liftingMicroSpeedCustomSet}
              defaultValue={liftingMicroSpeed.customValue}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={hoistSpeed.label}
        isValid={(!!hoistSpeed.value && hoistSpeed.value !== 'другое') || !!hoistSpeed.customValue}
      >
        <ButtonBlock
          buttons={hoistSpeedItems}
          onChange={hoistSpeedSet}
          defaultValue={hoistSpeed.value}
        />
        {hoistSpeed.value === 'другое' && (
          <>
            <div className={styles.title}>{hoistSpeed.customLabel}</div>
            <NumericBlock
              onChange={hoistSpeedCustomSet}
              defaultValue={hoistSpeed.customValue}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={hoistMicroSpeed.label}
        isValid={(!!hoistMicroSpeed.value && hoistMicroSpeed.value !== 'другое') || !!hoistMicroSpeed.customValue}
      >
        <ButtonBlock
          buttons={hoistMicroSpeedItems}
          onChange={hoistMicroSpeedSet}
          defaultValue={hoistMicroSpeed.value}
        />
        {hoistMicroSpeed.value === 'другое' && (
          <>
            <div className={styles.title}>{hoistMicroSpeed.customLabel}</div>
            <NumericBlock
              onChange={hoistMicroSpeedCustomSet}
              defaultValue={hoistMicroSpeed.customValue}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={workingMode.label}
        isValid={!!workingMode.value}
      >
        <ButtonBlock
          label={workingMode.label}
          buttons={workingModeItems}
          onChange={workingModeSet}
          defaultValue={workingMode.value}
        />
        {workingMode.value === 'другое' && (
          <>
            <div className={styles.title}>{workingMode.customLabel}</div>
            <NumericBlock
              onChange={workingModeCustomSet}
              defaultValue={workingMode.customValue}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={hoistPowerWire.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={hoistPowerWireItems}
          onChange={hoistPowerWireSet}
          defaultValue={hoistPowerWire.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={speedLiftingControl.label}
        isValid={!!speedLiftingControl.value}
      >
        <ButtonBlock
          buttons={speedLiftingControlItems}
          onChange={speedLiftingControlSet}
          defaultValue={speedLiftingControl.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={speedControl.label}
        isValid={!!speedControl.value}
      >
        <ButtonBlock
          buttons={speedControlItems}
          onChange={speedControlSet}
          defaultValue={speedControl.value}
        />
      </ParameterBlock>
    </>
  );
};

export default HoistParams;
