import styles from './NotifyBlock.module.scss';
import ErrorIcon from './Icons/ErrorIcon';
import WarningIcon from './Icons/WarningIcon';
import SuccessIcon from './Icons/SuccessIcon';
import { notifySet } from '../../../store/slices/modals/modalSlice';
import {useDispatch} from 'react-redux';
import CloseButtonIcon from './Icons/CloseButtonIcon';
import {useEffect, useState} from 'react';

const NotifyBlock = ({ params }) => {
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const showBlockTimeout = setTimeout(() => {
      setAnimation(false);
    }, 4000);

    setTimeout(() => {
      dispatch(notifySet({...params, isShow: false}));
    }, 5000);

    return () => {
      clearTimeout(showBlockTimeout);
    };
  }, []);

  let notifyColor = '';
  let icon = null;
  let closeButtonColor = '';

  if (params.type === 'error') {
    notifyColor = styles.error;
    closeButtonColor = '#FD4264';
    icon = <ErrorIcon />;
  }

  if (params.type === 'warning') {
    notifyColor = styles.warning;
    closeButtonColor = '#ffa703';
    icon = <WarningIcon />;
  }

  if (params.type === 'success') {
    notifyColor = styles.success;
    closeButtonColor = '#226457';
    icon = <SuccessIcon />;
  }

  const onCloseButtonClickHandler = () => {
    dispatch(notifySet({...params, isShow: false}));
  };

  const containerStyle = `${styles.container} ${notifyColor} ${animation ? `${styles.show}` : `${styles.hide}` }`;

  return (
    <div className={containerStyle}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.message}>{params.message}</div>
      <div
        className={styles.close}
        onClick={() => onCloseButtonClickHandler()}
      >
        <CloseButtonIcon color={closeButtonColor} />
      </div>
    </div>
  );
};

export default NotifyBlock;