import styles from './Feedback.module.scss';
import FeedbackList from './components/FeedbackList/FeedbackList';
import FeedbackContent from './components/FeedbackContent/FeedbackContent';
import {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { feedbackUpdate, readFeedbackByUser } from '../../service/feedback';
import UserIcon from '../../common/icons/UserIcon/UserIcon';
import MainFeedbackContent from './components/MainFeedbackContent/MainFeedbackContent';
import CreateFeedback from './components/CreateFeedback/CreateFeedback';

const Feedback = ({ data, guideStep }) => {
  const [datasource, setDatasource] = useState([]);
  const [activeBlock, setActiveBlock] = useState('main');
  const [activeContent, setActiveContent] = useState(null);
  const { email, name } = useSelector(state => state.profile);
  const containerRef = useRef();
  const [containerWidth, setContainerWidth] = useState(null);
  const [isListShow, setIsListShow] = useState(true);

  useEffect(() => {
    if (data.length) {
      setDatasource(data);
    }
  },[data.length]);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      setContainerWidth(width);
    }
  }, [containerRef]);

  const onSendMessage = (feedbackId, message) => {
    const paramsToUpdate = {
      email,
      id: feedbackId,
      name,
      message
    };

    feedbackUpdate(paramsToUpdate).then();

    const updatedDatasource = datasource.map(item => {
      if (item.id === feedbackId) {
        const updatedItem = { ...item};
        const lastContentId = updatedItem.content.length - 1;
        
        const messageToFeedback = {
          id: updatedItem.content[lastContentId].id + 1,
          message: message,
          email: email,
          name,
          date: new Date().toISOString()
        };
        
        updatedItem.content = [...updatedItem.content, messageToFeedback];
        setActiveContent(updatedItem);

        return updatedItem;
      }

      return item;
    });

    setDatasource(updatedDatasource);
  };

  const onListItemClickHandler = (listItem) => {
    if (listItem.id) {
      const updatedDatasource = [...datasource].map(item => {
        if (item.id === listItem.id) {
          if (item.hasReadByUser === false) {
            readFeedbackByUser(email, listItem.id).then();

            const updatedItem = {...item};
            updatedItem.hasReadByUser = true;

            return updatedItem;
          }
        }

        return item;
      });

      setDatasource(updatedDatasource);
    }

    if (activeContent && activeContent.id === listItem.id) {
      setActiveContent(null);
      setActiveBlock('main');

      return;
    }

    setActiveContent(listItem);
    setActiveBlock('content');
    containerWidth <= 768 && setIsListShow(false);
  };

  const onCloseClickHandler = () => {
    setActiveBlock('main');
    setIsListShow(true);
  };

  return (
    <div ref={containerRef} className={guideStep === 8 ? `${styles.container} ${styles.guide}` : styles.container}>
      {(isListShow || containerWidth > 768) && (
        <FeedbackList
          data={datasource}
          activeItemId={activeContent?.id || null}
          onClick={onListItemClickHandler}
        />
      )}
      {containerWidth > 768 && (
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.wrapper}>
              <UserIcon/>
              <div>{name}</div>
            </div>
          </div>
          {activeBlock === 'main' &&
            <MainFeedbackContent onClick={setActiveBlock}/>
          }
          {activeBlock === 'create' &&
            <CreateFeedback
              email={email}
              name={name}
              onClick={onCloseClickHandler}
            />
          }
          {activeContent && activeBlock === 'content' &&
            <FeedbackContent
              data={activeContent}
              onSendMessage={onSendMessage}
              width={containerWidth}
              onClose={onCloseClickHandler}
            />
          }
        </div>
      )}
      {(activeBlock === 'main' && !datasource && containerWidth <= 768) &&
        <MainFeedbackContent onClick={setActiveBlock}/>
      }
      {(activeBlock === 'create' && containerWidth <= 768) &&
        <CreateFeedback
          email={email}
          name={name}
          onClick={setActiveBlock}
        />
      }
      {(activeContent && containerWidth <= 768) && activeBlock === 'content' &&
        <FeedbackContent
          data={activeContent}
          onSendMessage={onSendMessage}
          width={containerWidth}
          close={onCloseClickHandler}
        />
      }
    </div>
  );
};

export default Feedback;