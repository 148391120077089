import styles from './CapacityTable.module.scss';

const CapacityTable = ({ title, labels, data }) => {
  const dataSource = Object.entries(data).map(([spanKey, spanValue]) => (
    {
      spanKey,
      spanValue: Object.entries(spanValue).map(([capacityKey, capacityValue]) => (
        {
          capacityKey,
          capacityValue
        }
      ))
        .sort((a, b) => parseFloat(a.capacityKey) - parseFloat(b.capacityKey))
    }))
    .sort((a, b) => parseFloat(a.spanKey) - parseFloat(b.spanKey));

  return (
    <div className={styles.panel}>
      <div className={styles.title}>{title}</div>
      <div className={styles.table}>
        <div className={styles.row}>
          {labels.map(item => <div className={styles.label} key={item}>{item}</div>)}
        </div>
        {dataSource.map(row => (
          <div className={styles.row} key={row.spanKey}>
            <div className={styles.label}>{row.spanKey}</div>
            {row.spanValue.map(item => <div className={styles.field} key={item.capacityKey}>{item.capacityValue}</div>)
              .sort((a, b) => parseFloat(a.capacityKey) - parseFloat(b.capacityKey))
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default CapacityTable;
