import styles from './ControlModal.module.scss';
import DeleteIcon from '../../../../common/icons/DeleteIcon/DeleteIcon';
import LoadIcon from '../../../../common/icons/LoadIcon/LoadIcon';
import ViewIcon from '../../../../common/icons/ViewIcon/ViewIcon';
import { useEffect, useRef } from 'react';
import CalculationIcon from '../../../../common/icons/CalculationIcon/CalculationIcon';
import { useSelector } from 'react-redux';

const ControlModal  = ({ id, onShow, onLoad, onDelete, onClose, onCalculateClickHandler }) => {
  const ref = useRef();
  const { coefficients, params } = useSelector(state => state.calculation);

  const onShowClickHandler = () => {
    onShow(id);
    onClose();
  };

  const onLoadClickHandler = () => {
    onLoad(id);
    onClose();
  };
  
  const onDeleteClickHandler = () => {
    onDelete(id);
    onClose();
  };


  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.row} onClick={onShowClickHandler}>
        <ViewIcon />
        Просмотреть
      </div>
      <div className={styles.row} onClick={onLoadClickHandler}>
        <LoadIcon />
        Загрузить
      </div>
      <div className={styles.row} onClick={() => onCalculateClickHandler(coefficients, id, params)}>
        <CalculationIcon size={24} />
        Расчитать
      </div>
      <div
        className={styles.row}
        onClick={onDeleteClickHandler}>
        <DeleteIcon />
        Удалить
      </div>
    </div>
  );
};

export default ControlModal;