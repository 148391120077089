const LoadIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 9C14.3285 9 15 9.67155 15 10.5V12C15 12.8285 14.3285 13.5 13.5 13.5H4.5C3.67157
       13.5 3 12.8285 3 12V10.5C3 9.67155 3.67157 9 4.5 9M9 3.75L9.00008 9.75M9.00008 9.75L6.75 7.6023M9.00008
       9.75L11.25 7.6023M13.5 11.25C13.5 11.4571 13.3321 11.625 13.125 11.625C12.9179 11.625 12.75 11.4571
       12.75 11.25C12.75 11.0429 12.9179 10.875 13.125 10.875C13.3321 10.875 13.5 11.0429 13.5 11.25Z"
      stroke="#3D487C" strokeLinecap="round"/>
    </svg>
  );
};

export default LoadIcon;
