import styles from '../../Profile.module.scss';
import StringBlock from '../../../../common/components/StringBlock/StringBlock';
import { confirmPasswordSet, newPasswordSet } from '../../../../store/slices/profile/profileSlice';
import { useSelector } from 'react-redux';

const Password = () => {
  const { newPassword, confirmPassword } = useSelector(state => state.profile);

  const isShowWarningMessage = (newPassword.length || confirmPassword.length) && newPassword !== confirmPassword;

  return(
    <div className={styles.block}>
      <div className={styles.input}>
        <StringBlock
          label="Новый пароль"
          inputType="password"
          onChange={newPasswordSet}
          placeholder="Введите пароль"
          defaultValue={newPassword}
        />
      </div>
      <div className={styles.input}>
        <StringBlock
          label="Подтвердите пароль"
          inputType="password"
          onChange={confirmPasswordSet}
          placeholder="Введите пароль"
          defaultValue={confirmPassword}
        />
      </div>
      <div className={styles.warning}>
        {isShowWarningMessage ? 'Пароли должны совпадать' : ''}
      </div>
    </div>
  );
};

export default Password;
