const ExitIcon = () => {
  return(
    <svg width="24" height="24" viewBox="12 13 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29 30C29 31.1046 28.1046 32 27 32L19 32C17.8954 32 17 31.1046 17 30L17 20C17 18.8954 17.8954 18 19 18L27
      18C28.1046 18 29 18.8954 29 20M27 25L35 25.0001M35 25.0001L32.1364 22M35 25.0001L32.1364
      28" stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
};

export default ExitIcon;