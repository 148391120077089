import styles from './FeedbackContent.module.scss';
import FeedbackMessage from './FeedbackMessage/FeedbackMessage';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import SendIcon from '../../icons/SendIcon/SendIcon';
import LeftIcon from '../../../../common/icons/LeftIcon/LeftIcon';

const FeedbackContent = ({ data, onSendMessage, width, onClose }) => {
  const [message, setMessage] = useState('');
  const { email } = useSelector(state => state.profile);
  const bottomRef = useRef(null);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [data.content.length]);

  const onSendClickHandler = (value) => {
    onSendMessage(data.id, value);
    setMessage('');
  };

  const onKeyDownHandler = (key) => {
    if (key === 'Enter') {
      onSendMessage(data.id, message);
      setMessage('');
    }
  };

  return (
    <div className={styles.container}>
      {width <= 768 && (
        <div className={styles.header}>
          <div
            className={styles.close}
            onClick={onClose}
          >
            <LeftIcon/>
          </div>
          <div className={styles.name}>{data.name}</div>
        </div>
      )}
      <div className={styles.messages}>
        {data.content.map(item => {
          return (
            <div key={item.id}>
              <FeedbackMessage
                date={item.date}
                message={item.message}
                isOwner={item.email === email}
                name={item.name}
              />
            </div>
          );
        })}
        <div ref={bottomRef} />
      </div>
      <div className={styles.footer}>
        <input
          className={styles.text}
          value={message}
          placeholder="Напишите сообщение..."
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => onKeyDownHandler(e.key)}
        />
        <div
          className={styles.send}
          onClick={() => onSendClickHandler(message)}
        >
          <SendIcon />
        </div>
      </div>
    </div>
  );
};

export default FeedbackContent;