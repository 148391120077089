import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  walkwayGallery: {
    label: 'Проходная галерея',
    value: 'не задано'
  },

  walkwayGalleryConstruction: {
    label: 'Конструкция проходной галлереи',
    value: 'не задано',
    dependencies: {
      walkwayGallery: 'требуется'
    }
  },

  craneLimitSwitch: {
    label: 'Наличие концевого выключателя на ход крана',
    value: 'не задано'
  },
  hoistLimitSwitch: {
    label: 'Наличие концевого выключателя на ход тали',
    value: 'не задано'
  },
  additionalCallButton: {
    label: 'Звонок (дополнительная кнопка на звонок)',
    value: 'не задано'
  },
  additionalLightButton: {
    label: 'Свет (дополнительная кнопка на свет)',
    value: 'не задано'
  },
  liftingBrake: {
    label: 'Дополнительный тормоз на подьем',
    value: 'не задано'
  },
  loadCapacityLimiter: {
    label: 'Ограничитель грузоподъемности',
    value: 'не задано'
  },
  parametersLogger: {
    label: 'Регистратор параметров',
    value: 'не задано'
  },
  hourCounter: {
    label: 'Счетчик моточасов',
    value: 'не задано'
  },
  brandColor: {
    label: 'Фирменный цвет Заказчика для покраски',
    value: 'не задано'
  },
  additionalRequirements: {
    label: 'Дополнительные требования заказчика',
    value: 'не задано'
  }
};

export const additionalParamsSlice = createSlice({
  name: 'additionalParams',
  initialState,
  reducers: {
    walkwayGallerySet:  (state, action) => {
      state.walkwayGallery.value = action.payload;
    },
    walkwayGalleryConstructionSet:  (state, action) => {
      state.walkwayGalleryConstruction.value = action.payload;
    },
    craneLimitSwitchSet:  (state, action) => {
      state.craneLimitSwitch.value = action.payload;
    },
    hoistLimitSwitchSet:  (state, action) => {
      state.hoistLimitSwitch.value = action.payload;
    },
    additionalCallButtonSet:  (state, action) => {
      state.additionalCallButton.value = action.payload;
    },
    additionalLightButtonSet:  (state, action) => {
      state.additionalLightButton.value = action.payload;
    },
    liftingBrakeSet:  (state, action) => {
      state.liftingBrake.value = action.payload;
    },
    loadCapacityLimiterSet:  (state, action) => {
      state.loadCapacityLimiter.value = action.payload;
    },
    parametersLoggerSet:  (state, action) => {
      state.parametersLogger.value = action.payload;
    },
    hourCounterSet:  (state, action) => {
      state.hourCounter.value = action.payload;
    },
    brandColorSet:  (state, action) => {
      state.brandColor.value = action.payload;
    },
    additionalRequirementsSet:  (state, action) => {
      state.additionalRequirements.value = action.payload;
    },
    additionalParamsSet: (state, action) => {
      state.additionalRequirements.value = action.payload.additionalRequirements.value;
      state.walkwayGalleryConstruction.value = action.payload.walkwayGalleryConstruction.value;
      state.craneLimitSwitch.value = action.payload.craneLimitSwitch.value;
      state.hoistLimitSwitch.value = action.payload.hoistLimitSwitch.value;
      state.additionalCallButton.value = action.payload.additionalCallButton.value;
      state.additionalLightButton.value = action.payload.additionalLightButton.value;
      state.liftingBrake.value = action.payload.liftingBrake.value;
      state.loadCapacityLimiter.value = action.payload.loadCapacityLimiter.value;
      state.parametersLogger.value = action.payload.parametersLogger.value;
      state.hourCounter.value = action.payload.hourCounter.value;
      state.brandColor.value = action.payload.brandColor.value;
      state.additionalRequirements.value = action.payload.additionalRequirements.value;
    }
  }
});

export const {
  walkwayGallerySet,
  walkwayGalleryConstructionSet,
  craneLimitSwitchSet,
  hoistLimitSwitchSet,
  additionalCallButtonSet,
  additionalLightButtonSet,
  liftingBrakeSet,
  loadCapacityLimiterSet,
  parametersLoggerSet,
  hourCounterSet,
  brandColorSet,
  additionalRequirementsSet,
  additionalParamsSet
} = additionalParamsSlice.actions;

export default additionalParamsSlice.reducer;
