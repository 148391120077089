import styles from './MainFeedbackContent.module.scss';

const MainFeedbackContent = ({ onClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.info}>
          Вы можете оставить здесь
          <span
            className={styles.create}
            role="presentation"
            onClick={() => onClick('create')}>
            {' обратную связь '} 
          </span>
           о работе сайта или задать любой вопрос
        </div>
      </div>
    </div>
  );
};

export default MainFeedbackContent;