import styles from './SideMenu.module.scss';
import CraneTypeIcon from './icons/CraneTypeIcon/CraneTypeIcon';
import CraneSectionsIcon from './icons/CraneSectionsIcon/CraneSectionsIcon';
import SubMenu from './SubMenu/SubMenu';
import MenuIconLabelBlock from '../../common/components/MenuIconLabelBlock/MenuIconLabelBlock';
import GostReferenceIcon from './icons/GostReferenceIcon/GostReferenceIcon';
import {useEffect, useState} from 'react';
import CalculationIcon from '../../common/icons/CalculationIcon/CalculationIcon';
import { useSelector } from 'react-redux';

export const sideMenuItem = [
  {
    id: 1,
    icon: <CraneTypeIcon />,
    label: 'Тип крана',
    isTest: false,
    access: ['user'],
    subMenu: null
  },
  {
    id: 2,
    icon: <CraneSectionsIcon />,
    label: 'Характеристики',
    isTest: false,
    access: ['user'],
    subMenu: [
      {
        id: 1,
        label: 'Металлоконструкция',
      },
      {
        id: 2,
        label: 'Электрооборудование',
      },
      {
        id: 3,
        label: 'Таль',
      },
      {
        id: 4,
        label: 'Токоподвод',
      },
      {
        id: 5,
        label: 'Габариты',
      },
      {
        id: 6,
        label: 'Опции'
      },
      {
        id: 7,
        label: 'Заказчик'
      }
    ]
  },
  {
    id: 3,
    icon: <GostReferenceIcon />,
    label: 'Справочник ГОСТов',
    isTest: false,
    access: ['user'],
    subMenu: null
  },
  {
    id: 4,
    icon: <CalculationIcon size={51}/>,
    label: 'Расчет стоимости',
    isTest: true,
    access: ['manager', 'admin'],
    subMenu: null
  }
];

const SideMenu = ({
  activeMenuIndex,
  onMenuClick,
  onSubMenuClick,
  markedSubMenuIndex,
  isLabelShow
}) => {
  const [style, setStyle] = useState('');
  const { roles } = useSelector(state => state.profile);

  useEffect(() => {
    if (isLabelShow) {
      setStyle('');
    }

    if (!isLabelShow) {
      setTimeout(() => {
        setStyle(styles.disappear);
      }, 1000);
    }
  });

  return (
    <div className={isLabelShow ? styles.container : `${styles.container} ${styles.close}`}>
      <div className={styles.menu}>
        {sideMenuItem.reduce((acc, item) => {
          const itemStyle = (item.id === activeMenuIndex) ? `${styles.item} ${styles.active}` : styles.item;

          roles.some(role => item.access.includes(role)) && acc.push(
            <div
              key={item.id}
              className={itemStyle}
              role="presentation"
              onClick={() => onMenuClick(item.id)}
            >
              <MenuIconLabelBlock
                icon={item.icon}
                label={item.label}
                isLabelShow={isLabelShow}
              />
              {item.isTest && <div className={`${styles.test} ${!isLabelShow && styles.testClose}`}>тест</div>}
              {item.subMenu && item.id === activeMenuIndex &&
                <div className={isLabelShow ? `${styles.submenu}` : `${styles.submenu} ${styles.fadeOut} ${style}`}>
                  <SubMenu
                    subMenu={item.subMenu}
                    activeMenuIndex={activeMenuIndex}
                    markedSubMenuIndex={markedSubMenuIndex}
                    onSubMenuClick={onSubMenuClick}
                  />
                </div>
              }
            </div>
          );

          return acc;
        }, [])}
      </div>
    </div>
  );
};

export default SideMenu;