import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  company: {
    id: '',
    name: {
      label: 'Компания',
      value: '',
    },
    inn: {
      label: 'ИНН компании',
      value: '',
    },
    phone: {
      label: 'Телефоны компании',
      value: '',
    },
    email: {
      label: 'E-Mail компании',
      value: '',
    }
  },
  contact: {
    id: '',
    name: {
      label: 'ФИО контакта',
      value: '',
    },
    phone: {
      label: 'Телефон контакта',
      value: '',
    },
    email: {
      label: 'E-Mail контакта',
      value: '',
    }
  },
  companyList: [],
  contactList: []
};

export const customerParamsSlice = createSlice({
  name: 'customerParams',
  initialState,
  reducers: {
    companyNameSet:  (state, action) => {
      state.company.name.value = action.payload;
      state.company.id = '';
    },
    companyInnSet:  (state, action) => {
      state.company.inn.value = action.payload;
      state.company.id = '';
    },
    companyPhoneSet:  (state, action) => {
      state.company.phone.value = action.payload;
      state.company.id = '';
    },
    companyEmailSet:  (state, action) => {
      state.company.email.value = action.payload;
      state.company.id = '';
    },
    contactNameSet:  (state, action) => {
      state.contact.name.value = action.payload;
      state.contact.id = '';
    },
    contactPhoneSet:  (state, action) => {
      state.contact.phone.value = action.payload;
      state.contact.id = '';
    },
    contactEmailSet:  (state, action) => {
      state.contact.email.value = action.payload;
      state.contact.id = '';
    },
    companyParamsSet: (state, action) => {
      state.company.id = action.payload.id;
      state.company.name.value = action.payload.name;
      state.company.inn.value = action.payload.inn;
      state.company.phone.value = action.payload.phone ;
      state.company.email.value = action.payload.email;
    },
    contactParamsSet: (state, action) => {
      state.contact.id = action.payload.id;
      state.contact.name.value = action.payload.name;
      state.contact.phone.value = action.payload.phone;
      state.contact.email.value = action.payload.email;
    },
    companyListSet:  (state, action) => {
      state.companyList = action.payload;
    },
    contactListSet:  (state, action) => {
      state.contactList = action.payload;
    },
    companyInitialValueSet: (state) => {
      state.company = initialState.company;
    },
    contactInitialValueSet: (state) => {
      state.contact = initialState.contact;
    },
  }
});

export const {
  companyNameSet,
  companyInnSet,
  companyPhoneSet,
  companyEmailSet,
  companyParamsSet,
  contactNameSet,
  contactPhoneSet,
  contactEmailSet,
  contactParamsSet,
  companyListSet,
  contactListSet
} = customerParamsSlice.actions;

export default customerParamsSlice.reducer;
