import styles from './Calculation.module.scss';
import { useSelector } from 'react-redux';
import NumericBlock from '../../common/components/NumericBlock/NumericBlock';
import { coefficientsSet } from '../../store/slices/calculation/calculationSlice';
import ButtonBlock from '../../common/components/ButtonBlock/ButtonBlock';
import { useEffect, useRef, useState } from 'react';
import UpIcon from '../../common/icons/UpIcon/UpIcon';
import DownIcon from '../../common/icons/DownIcon/DownIcon';
import { calculation } from '../../service/calcualtion';

const Calculation = () => {
  const { coefficients, params } = useSelector(state => state.calculation);
  const { qlistId } = useSelector(state => state.qlistParams);
  const qlistParams = {
    metalConstruction: useSelector(state => state.metalConstruction),
    electroEquip: useSelector(state => state.electroEquip),
    hoistParams: useSelector(state => state.hoistParams),
    powerWire: useSelector(state => state.powerWire),
    craneInstallationSizes: useSelector(state => state.craneInstallationSizes),
    additionalParams: useSelector(state => state.additionalParams),
    profile: useSelector(state => state.profile),
    qlistParams: useSelector(state => state.qlistParams)
  };
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [openTabs, setOpenTabs] = useState([]);

  const containerRef = useRef();

  useEffect(() => {
    containerRef.current.focus();
  }, []);

  const buttons = {
    general: [
      'Да',
      'Нет'
    ],
    conditioner: [
      'отсутствует',
      'Промышленный',
      'Бытовой'
    ],
    walkingGalleryOption: [
      'Нет',
      'Одностороняя',
      'Двустороняя'
    ]
  };
  
  const data = Object.entries(coefficients).map(([groupKey, groupValue]) => {
    const isTabOpen = openTabs.includes(groupKey);

    const onGroupClickHandler = (key) => {
      isTabOpen && setOpenTabs(openTabs.filter((item) => item !== key));
      !isTabOpen && setOpenTabs([...openTabs, key]);
    };

    return (
      <div key={groupKey}>
        <div className={styles.groupTitle} onClick={() => onGroupClickHandler(groupKey)}>
          <div>{groupValue.label}</div>
          {isTabOpen ? <UpIcon /> : <DownIcon />}
        </div>
        {isTabOpen &&
          <div className={styles.group}>
            {Object.entries(groupValue)
              .filter(([key, value]) => key !== 'label' && typeof value === 'object')
              .map(([key, value]) => {
                const valueType = typeof value.value;

                const onChangeCoefficentHandler = (newValue) => {
                  return coefficientsSet({
                    ...coefficients,
                    [groupKey]: {
                      ...groupValue,
                      [key]: {
                        ...value,
                        value: newValue
                      }
                    }
                  });
                };

                return (
                  <div className={styles.item} key={key}>
                    <div className={styles.label}>{value.label}</div>
                    {valueType === 'number' && (
                      <NumericBlock defaultValue={value.value} onChange={onChangeCoefficentHandler} />
                    )}
                    {(valueType === 'string') && (
                      <ButtonBlock
                        defaultValue={value.value}
                        buttons={buttons?.[key] || buttons.general}
                        onChange={onChangeCoefficentHandler}
                      />
                    )}
                    {[value]?.subItems && (
                      Object.entries([value].subItems).map(([block, blockValue]) => {
                        const onChangeSubCoefficentHandler = (newValue) => {
                          return coefficientsSet({
                            ...coefficients,
                            [groupKey]: {
                              ...groupValue,
                              [key]: {
                                ...value,
                                subItems: {
                                  ...[value].subItems,
                                  [block]: {
                                    ...blockValue,
                                    value: newValue
                                  }
                                }
                                
                              }
                            }
                          });
                        };

                        return (
                          <div key={block.label} className={styles.subItem}>
                            <div>{block.label}</div>
                            <NumericBlock
                              defaultValue={blockValue.value}
                              onChange={onChangeSubCoefficentHandler}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                );
              })
            }</div>
        }
      </div>
    );
  });

  return(
    <div className={styles.container}
      onKeyDown={(event) => (event.key === 'Escape' && isPopupShow) && setIsPopupShow(false)}
      tabIndex={0}
      ref={containerRef}
    >
      <div className={styles.content}>
        <div className={styles.title}>Расчет стоимости</div>
        {data}
      </div>
      <div className={styles.footer}>
        <div className={styles.total}>
          Итого: {(params.options.values.total.value + params.endBeam.values.total.value + params.cabin.values.totalWithOptions.value + params.spanBeam.values.nds.value)
            .toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })
          }
          <div
            className={styles.more}
            onClick={() => setIsPopupShow(!isPopupShow)}
          >Подробнее</div>
        </div>
        <div
          className={styles.calculate}
          onClick={() => calculation(coefficients, qlistId || null, qlistParams, params)}
        >Рассчитать</div>
      </div>
      {isPopupShow && (
        <>
          <div className={styles.popup}>
            <div className={styles.table}>
              {Object.entries(params).map(([key, value]) => {

                return(
                  <div key={key}>
                    <div className={styles.header}>{value.label}</div>
                    {Object.values(value.values).map((item) => {
                      return(
                        <div key={item.label} className={styles.param}>
                          <div className={styles.cell}>{item.label}</div>
                          <div className={styles.cell}>{typeof item.value === 'number' ? item.value.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }) : item.value}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className={styles.popupFooter}>
              <div
                className={styles.close}
                onClick={() => setIsPopupShow(false)}
              >Закрыть</div>
            </div>
          </div>
          <div className={styles.background}/>
        </>
      )}
    </div>
  );
};

export default Calculation;