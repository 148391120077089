import styles from './FeedbackMessage.module.scss';
import FeedbackDateBlock from '../../FeedbackDateBlock/FeedbackDateBlock';

const FeedbackMessage = ({ date, message, isOwner, name }) => {
  const containerStyle = isOwner ? `${styles.container} ${styles.right}` : `${styles.container}`;
  const messageStyle = isOwner ? `${styles.message} ${styles.owner}` : `${styles.message}`;
  return (
    <div className={containerStyle}>
      <div className={messageStyle}>
        <div className={styles.block}>
          <div className={styles.name}>
            {isOwner ? name : `${name} (support)`}
          </div>
          {message}
        </div>
      </div>
      <FeedbackDateBlock date={date} />
    </div>
  );
};

export default FeedbackMessage;
