const UserIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill="#F3F4F8"/>
      <path d="M31.9993 31.5491C24.3816 31.5491 17.8288 34.8703 14.9216 40.3246C13.1534 43.642 16.3603 46.873 20.1119
       46.873H43.8867C47.6384 46.873 50.8453 43.642 49.0771 40.3246C46.1698 34.8703 39.6171 31.5491 31.9993 31.5491Z"
      stroke="#3D487C" strokeWidth="2" strokeLinecap="round"/>
      <path d="M38.7603 20.7322C38.7603 24.2171 35.9353 27.0421 32.4505 27.0421C28.9657 27.0421 26.1406 24.2171 26.1406
       20.7322C26.1406 17.2474 28.9657 14.4224 32.4505 14.4224C35.9353 14.4224 38.7603 17.2474 38.7603 20.7322Z"
      stroke="#3D487C" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
};

export default UserIcon;
