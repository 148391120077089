import styles from './DatesBlock.module.scss';
import { useEffect, useRef, useState } from 'react';
import { startDateSet, endDateSet } from '../../../../store/slices/filters/filter';
import {useDispatch, useSelector} from 'react-redux';
import CalendarIcon from './icons/CalendarIcon';
import DownIcon from '../../../../common/icons/DownIcon/DownIcon';
import {getFormattedDate} from '../../utils/date';

const DatesBlock = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {startDate, endDate} = useSelector(state => state.filters);
  const dispatch = useDispatch();

  const ref = useRef();
  const date = new Date();

  const handleClickOutside = () => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const onClickSetToday = () => {
    setIsOpen(!isOpen);

    const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    dispatch(startDateSet(getFormattedDate(currentDate)));
    dispatch(endDateSet(getFormattedDate(currentDate)));
  };

  const onClickSetYesterday = () => {
    setIsOpen(!isOpen);

    const dayBeforeDate = new Date(date.getFullYear(),date.getMonth(),date.getDate() - 1);
    dispatch(startDateSet(getFormattedDate(dayBeforeDate)));
    dispatch(endDateSet(getFormattedDate(dayBeforeDate)));
  };

  const onClickSetThisMonth = () => {
    setIsOpen(!isOpen);

    const firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastMonthDay = new Date(date.getFullYear(),date.getMonth() + 1,0);

    dispatch(startDateSet(getFormattedDate(firstMonthDay)));
    dispatch(endDateSet(getFormattedDate(lastMonthDay)));
  };

  return (
    <>
      <div className={styles.container}>
        <div
          role="presentation"
          onClick={() => setIsOpen(!isOpen)}
          className={styles.drop}
        >
          <div className={styles.range}>
            <CalendarIcon />
            {startDate.replace(/-/g, '.').split('.').reverse().join('.')} - {endDate.replace(/-/g, '.').split('.').reverse().join('.')}
          </div>
          <DownIcon />
        </div>
        {isOpen &&
          <div className={styles.popup} ref={ref}>
            <div className={styles.date}>
              <input
                type="date"
                onChange={(e) => dispatch(startDateSet(e.target.value))}
                defaultValue={startDate}
                className={styles.item}
              />

              <div className={styles.separator}>-</div>

              <input
                type="date"
                onChange={(e) => dispatch(endDateSet(e.target.value))}
                defaultValue={endDate}
                className={styles.item}
              />

            </div>
            <div
              className={styles.button}
              role="presentation"
              onClick={onClickSetToday}
            >
              Сегодня
            </div>

            <div
              className={styles.button}
              role="presentation"
              onClick={onClickSetYesterday}
            >
              Вчера
            </div>

            <div
              className={styles.button}
              role="presentation"
              onClick={onClickSetThisMonth}
            >
              В этом месяце
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default DatesBlock;