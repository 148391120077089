import styles from './StringBlock.module.scss';
import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { hasChangesSet } from '../../../store/slices/qlistParams/qlistParamsSlice';

const StringBlock = ({ label, onChange, defaultValue, inputType, placeholder, disabled }) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onInputChange = (data) => {
    setValue(data);
  };

  const onInputBlur = () => {
    if (value) {
      setValue(value);
      dispatch(onChange(value));
    } else {
      setValue('');
      dispatch(onChange(''));
    }
    dispatch(hasChangesSet(true));
  };

  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <input
        className={styles.input}
        type={inputType}
        onChange={(e) => onInputChange(e.target.value)}
        onBlur={onInputBlur}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e) => e.key === 'Enter' && onInputBlur()}
        disabled={disabled}
      />
    </div>
  );
};

export default StringBlock;
