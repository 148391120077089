import { StyleSheet, View, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
    padding: 10,
    border: '1px solid grey',
    width: '40%'
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: '900',
    fontSize: '16px',
    marginBottom: '5px'
  }
});

const List = () => {
  return (
    <View style={styles.column}>
      <Text style={styles.label}>ОПРОСНЫЙ ЛИСТ</Text>
      <Text style={styles.text}>НА КРАН МОСТОВОЙ</Text>
      <Text style={styles.text}>ЭЛЕКТРИЧЕСКИЙ</Text>
      <Text style={styles.text}>ОДНОБАЛОЧНЫЙ</Text>
    </View>
  );
};

export default List;
