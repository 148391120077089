const SendIcon = () => {
  return (
    <svg width="110" height="45" viewBox="0 0 167 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="167" height="54" rx="7" fill="#F57C4A"/>
      <path d="M69 27.5H98M98 27.5L88.3333 18M98 27.5L88.3333 37" stroke="white" strokeWidth="2"
        strokeLinecap="round"/>
    </svg>
  );
};

export default SendIcon;
