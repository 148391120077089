import ButtonBlock from '../../../../../../common/components/ButtonBlock/ButtonBlock';
import {
  powerWireTypeItems,
  powerWireLengthItems,
  supplyInputCabinetItems
} from '../../../../../../common/consts/powerWire';
import {
  powerWireTypeSet,
  powerWireLengthSet,
  supplyInputCabinetSet,
  powerWireTypeCustomSet
} from '../../../../../../store/slices/params/powerWireSlice';
import ParameterBlock from '../../../../../../common/components/ParameterBlock/ParameterBlock';
import { useSelector } from 'react-redux';
import NumericBlock from '../../../../../../common/components/NumericBlock/NumericBlock';
import styles from '../../Content.module.scss';
import StringBlock from '../../../../../../common/components/StringBlock/StringBlock';
import DropDownBlock from '../../../../../../common/components/DropDownBlock/DropDownBlock';

const PowerWire = () => {
  const {
    powerWireType,
    powerWireLength,
    supplyInputCabinet
  } = useSelector(state => state.powerWire);

  return(
    <>
      <ParameterBlock
        title={powerWireType.label}
        isValid={!!powerWireType.value}
      >
        <DropDownBlock
          list={powerWireTypeItems}
          onChange={powerWireTypeSet}
          defaultValue={powerWireType.value && powerWireType.value !== 'другое' ? powerWireType.value : powerWireType.customValue}
        />
        {powerWireType.value === 'другое' && (
          <>
            <div className={styles.title}>{powerWireType.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={powerWireTypeCustomSet}
                defaultValue={powerWireType.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={powerWireLength.label}
        isValid={!!powerWireLength.value}
      >
        <NumericBlock
          buttons={powerWireLengthItems}
          onChange={powerWireLengthSet}
          defaultValue={powerWireLength.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={supplyInputCabinet.label}
        isValid={!!supplyInputCabinet.value}
      >
        <ButtonBlock
          buttons={supplyInputCabinetItems}
          onChange={supplyInputCabinetSet}
          defaultValue={supplyInputCabinet.value}
        />
      </ParameterBlock>
    </>
  );
};

export default PowerWire;
