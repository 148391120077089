import { configureStore } from '@reduxjs/toolkit';
import metalConstructionReducer from './slices/params/metalConstructionSlice';
import electroEquipReducer from './slices/params/electroEquipSlice';
import hoistParamsReducer from './slices/params/hoistParamsSlice';
import powerWireReducer from './slices/params/powerWireSlice';
import craneInstallationSizes from './slices/params/craneInstallationSizesSlice';
import profileReducer from './slices/profile/profileSlice';
import additionalParamsReducer from './slices/params/additionalParamsSlice';
import filterReducer from './slices/filters/filter';
import modalReducer from './slices/modals/modalSlice';
import qlistParamsReducer from './slices/qlistParams/qlistParamsSlice';
import feedbackReducer from './slices/feedback/feedbackSlice';
import newsReducer from './slices/news/newsSlice';
import appParamsReducer from './slices/appParams/appParamsSlice';
import customerParamsReducer from './slices/params/customerParamsSlice';
import calclationReducer from './slices/calculation/calculationSlice';

export const store = configureStore({
  reducer: {
    metalConstruction: metalConstructionReducer,
    electroEquip: electroEquipReducer,
    hoistParams: hoistParamsReducer,
    powerWire: powerWireReducer,
    craneInstallationSizes: craneInstallationSizes,
    additionalParams: additionalParamsReducer,
    profile: profileReducer,
    filters: filterReducer,
    modals: modalReducer,
    qlistParams: qlistParamsReducer,
    feedback: feedbackReducer,
    news: newsReducer,
    appParams: appParamsReducer,
    calculation: calclationReducer,
    customerParams: customerParamsReducer,
  },
});
