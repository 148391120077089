import styles from '../../Content.module.scss';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getStandardGeometricParams } from '../../../../utils/getStandardParams';
import { installationSizesItems } from '../../../../../../common/consts/InstallationSizes';
import {
  craneBaseCustomSet,
  craneBaseSet, endBeamLengthCustomSet,
  endBeamLengthSet, floorToTracksHeightCustomSet,
  floorToTracksHeightSet, heightFromRailHeadCustomSet,
  heightFromRailHeadSet, heightFromRailToBufferCustomSet,
  heightFromRailToBufferSet,
  railHeadToHookHeightCustomSet,
  railHeadToHookHeightSet
} from '../../../../../../store/slices/params/craneInstallationSizesSlice';
import NumericBlock from '../../../../../../common/components/NumericBlock/NumericBlock';
import ParameterBlock from '../../../../../../common/components/ParameterBlock/ParameterBlock';
import ButtonBlock from '../../../../../../common/components/ButtonBlock/ButtonBlock';
import StringBlock from '../../../../../../common/components/StringBlock/StringBlock';
import { useEffect } from 'react';


const CraneInstallationSizes = () => {
  const {
    floorToTracksHeight,
    heightFromRailHead,
    railHeadToHookHeight,
    craneBase,
    endBeamLength,
    heightFromRailToBuffer
  } = useSelector(state => state.craneInstallationSizes);

  const {
    craneType,
    loadCapacity
  } = useSelector(state => state.metalConstruction);
  const standardGeometricParams = getStandardGeometricParams(craneType.value, loadCapacity.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (heightFromRailHead.value !== 'другое' && heightFromRailHead.customValue !== standardGeometricParams.h) {
      dispatch(heightFromRailHeadCustomSet(standardGeometricParams.h));
    }

    if (railHeadToHookHeight.value !== 'другое' && railHeadToHookHeight.customValue !== standardGeometricParams.h1) {
      dispatch(railHeadToHookHeightCustomSet(standardGeometricParams.h1));
    }

    if (craneBase.value !== 'другое' && craneBase.customValue !== standardGeometricParams.b) {
      dispatch(craneBaseCustomSet(standardGeometricParams.b));
    }

    if (endBeamLength.value !== 'другое' && endBeamLength.customValue !== standardGeometricParams.a){
      dispatch(endBeamLengthCustomSet(standardGeometricParams.a));
    }

    if (heightFromRailToBuffer.value !== 'другое' && heightFromRailToBuffer.customValue !== standardGeometricParams.c1) {
      dispatch(heightFromRailToBufferCustomSet(standardGeometricParams.c1));
    }
  }, []);

  return(
    <>
      <ParameterBlock
        title={floorToTracksHeight.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={installationSizesItems}
          onChange={floorToTracksHeightSet}
          defaultValue={floorToTracksHeight.value}
        />
        {floorToTracksHeight.value === 'другое' && (
          <>
            <div className={styles.title}>{floorToTracksHeight.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={floorToTracksHeightCustomSet}
                defaultValue={floorToTracksHeight.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={heightFromRailHead.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={installationSizesItems}
          onChange={heightFromRailHeadSet}
          defaultValue={heightFromRailHead.value}
        />
        {heightFromRailHead.value !== 'другое' && (
          <>
            <div className={styles.title}>стандартное значение по ГОСТ</div>
            <NumericBlock
              defaultValue={standardGeometricParams.h}
              disabled
            />
          </>
        )}
        
        {heightFromRailHead.value === 'другое' && (
          <>
            <div className={styles.title}>{heightFromRailHead.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={heightFromRailHeadCustomSet}
                defaultValue={heightFromRailHead.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={railHeadToHookHeight.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={installationSizesItems}
          onChange={railHeadToHookHeightSet}
          defaultValue={railHeadToHookHeight.value}
        />
        {railHeadToHookHeight.value !== 'другое' && (
          <>
            <div className={styles.title}>стандартное значение по ГОСТ</div>
            <NumericBlock
              defaultValue={standardGeometricParams.h1}
              disabled
            />
          </>
        )}
        {railHeadToHookHeight.value === 'другое' && (
          <>
            <div className={styles.title}>{railHeadToHookHeight.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={railHeadToHookHeightCustomSet}
                defaultValue={railHeadToHookHeight.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={craneBase.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={installationSizesItems}
          onChange={craneBaseSet}
          defaultValue={craneBase.value}
        />
        {craneBase.value !== 'другое' && (
          <>
            <div className={styles.title}>стандартное значение по ГОСТ</div>
            <NumericBlock
              defaultValue={standardGeometricParams.b}
              disabled
            />
          </>
        )}
        {craneBase.value === 'другое' && (
          <>
            <div className={styles.title}>{craneBase.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={craneBaseCustomSet}
                defaultValue={craneBase.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={endBeamLength.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={installationSizesItems}
          onChange={endBeamLengthSet}
          defaultValue={endBeamLength.value}
        />
        {endBeamLength.value !== 'другое' && (
          <>
            <div className={styles.title}>стандартное значение по ГОСТ</div>
            <NumericBlock
              defaultValue={standardGeometricParams.a}
              disabled
            />
          </>
        )}
        {endBeamLength.value === 'другое' && (
          <>
            <div className={styles.title}>{endBeamLength.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={endBeamLengthCustomSet}
                defaultValue={endBeamLength.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={heightFromRailToBuffer.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={installationSizesItems}
          onChange={heightFromRailToBufferSet}
          defaultValue={heightFromRailToBuffer.value}
          isDisabled={standardGeometricParams === 'данные заказчика'}
        />
        {heightFromRailToBuffer.value !== 'другое' && (
          <>
            <div className={styles.title}>стандартное значение по ГОСТ</div>
            <NumericBlock
              defaultValue={standardGeometricParams.c1}
              disabled
            />
          </>
        )}
        {heightFromRailToBuffer.value === 'другое' && (
          <>
            <div className={styles.title}>{heightFromRailToBuffer.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={heightFromRailToBufferCustomSet}
                defaultValue={heightFromRailToBuffer.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
    </>
  );
};

export default CraneInstallationSizes;
