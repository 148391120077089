import {
  Page,
  Document,
  StyleSheet,
  Font,
  Text
} from '@react-pdf/renderer';
import font from '../../../fonts/Manrope/Manrope-Regular.ttf';
import Header from './Header/Header';
import Params from './Content/Params';
import Img from './Content/Img';

Font.register({ family: 'Manrope', fonts: [
  { src: font },
]});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Manrope',
    fontSize: '10px',
    padding: 20,
    orientation: 'portrait',
    width: '100vh',
  },
  text: {
    fontSize: '8px',
  }
});

const PdfDocument = ({ params }) => {
  const craneType = params.metalConstruction.craneType;
  const implementation = params.metalConstruction.implementation;
  const isPremiumUser = params.profile.premiumParams && params.profile.premiumParams.isActive;
  const pdfPremiumParams = {
    ...params.profile.premiumParams,
    company: params.profile.company
  };

  const footer = `Опросный лист является:
    1. Основным техническим документом, на основании которого изготавливается кран.
    2. Неотъемлемой частью договора (не полностью заполненный и не заверенный печатью заказчика опросный лист недействителен).
    3. Исправление параметров крана в опросном листе не допускается. Отклонение от параметров, указанных в опросном листе, подлежит согласованию с заводом-изготовителем.
    4. Заполненный опросный лист заверить подписью, печатью и возвратить. Завод - изготовитель оставляет за собой право вносить изменения в конструктив крана, не изменяя основные параметры.
  
  
      Наименование Заказчика: ${params.customerParams.company.name.value || ''}
      Ответственный специалист: ${params.customerParams.contact.name.value || ''}
      Контактный тел: ${params.customerParams.contact.phone.value || params.customerParams.company.phone.value || ''}
      Адрес электронной почты: ${params.customerParams.contact.email.value || params.customerParams.company.email.value || ''}
  
      Заказчик: ____________________________________
      
      ${pdfPremiumParams.company || 'Поставщик'}: ____________________________________
      `;

  return (
    <Document onContextMenu={(e) => e.preventDefault()}>
      <Page style={styles.page}>
        <Header
          craneType={craneType.value}
          implementation={implementation.value}
          isPremiumUser={isPremiumUser}
          premiumParams={pdfPremiumParams}
        />
        <Img craneType={craneType}/>
        <Params params={params} />
        <Text wrap={false}>
          {`Базовая конструкция:
             - стандартная строительная высота подъема тали электрической;
             - тормоз на передвижение крана и тали;
             - конечные выключатели на передвижение крана и тали;
             - буфера на концевых балках и тали;
             - частотный привод на передвижение крана;
             - вторая скорость на передвижение крана и тали за счет частотного привода;
             - токоподвод тали кабельный по струне, стандартные скорости передвижения крана и тали – 20м/мин;
             - стандартный тип управления - с пола пультом с ключ – маркой + кнопка «Стоп»;
             - степень защиты IP 54, напряжение управления 24В (36В,42В), Питание 380 В, 50Гц;
             - паспорт на кран, руководство по монтажу и эксплуатации.
             
           ${isPremiumUser ? footer : ''}
          `}
        </Text>
      </Page>
    </Document>
  );
};

export default PdfDocument;
