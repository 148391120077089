export const CRANE_TYPE = {
  overheadOneSpan: 'Подвесной однопролетный',
  overheadTwoSpan: 'Подвесной двухпролетный',
  pylon: 'Опорный'
};

export const CRANE_IMPLEMENTATION = {
  industrial: 'Общепромышленный',
  explosionProof: 'Взрывозащищенный',
  chemicalResistant: 'Химостойкий',
  fireProof: 'Пожаробезопасный'
};
