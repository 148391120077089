import styles from './QlistSaveForm.module.scss';
import QlistParams from './QlistParams/QlistParams';
import { qlistCreate } from '../../../service/qlist';
import { useSelector } from 'react-redux';
import { CRANE_TYPE } from '../../consts/enums';

const QlistSaveForm = ({ onClose }) => {
  const { email } = useSelector(store => store.profile);
  const pdfParams = {
    metalConstruction: useSelector(state => state.metalConstruction),
    electroEquip: useSelector(state => state.electroEquip),
    hoistParams: useSelector(state => state.hoistParams),
    powerWire: useSelector(state => state.powerWire),
    craneInstallationSizes: useSelector(state => state.craneInstallationSizes),
    additionalParams: useSelector(state => state.additionalParams),
    profile: useSelector(state => state.profile),
    qlistParams: useSelector(state => state.qlistParams),
    customerParams: useSelector(state => state.customerParams)
  };

  const handleClickHandler = () => {
    const liftingHeight = pdfParams.metalConstruction.liftingHeight.value !== 'другое'
      ? pdfParams.metalConstruction.liftingHeight.value
      : pdfParams.metalConstruction.liftingHeight.customValue;

    const getSpanLength = () => {
      if (pdfParams.metalConstruction.craneType.value === CRANE_TYPE.overheadTwoSpan) {
        return pdfParams.metalConstruction.twoSpanLength.value !== 'другое'
          ? pdfParams.metalConstruction.twoSpanLength.value
          : pdfParams.metalConstruction.twoSpanLength.customValue;
      }

      return pdfParams.metalConstruction.oneSpanLength.value !== 'другое'
        ? pdfParams.metalConstruction.oneSpanLength.value
        : pdfParams.metalConstruction.oneSpanLength.customValue;
    };

    const workingModeByIso = pdfParams.metalConstruction.workingModeByIso.value !== 'другое'
      ? pdfParams.metalConstruction.workingModeByIso.value
      : pdfParams.metalConstruction.workingModeByIso.customValue;

    qlistCreate(
      {
        email,
        craneType: pdfParams.metalConstruction.craneType.value,
        spanLength: getSpanLength(),
        liftingHeight,
        workingModeByIso,
        customerParams: {
          company: {
            id: pdfParams.customerParams.company.id,
            name: pdfParams.customerParams.company.name.value,
            inn: pdfParams.customerParams.company.inn.value,
            phone: pdfParams.customerParams.company.phone.value,
            email: pdfParams.customerParams.company.email.value,
          },
          contact: {
            id: pdfParams.customerParams.contact.id,
            name: pdfParams.customerParams.contact.name.value,
            phone: pdfParams.customerParams.contact.phone.value,
            email: pdfParams.customerParams.contact.email.value,
          },
        },
        craneParams: JSON.stringify({
          metalConstruction: pdfParams.metalConstruction,
          electroEquip: pdfParams.electroEquip,
          hoistParams: pdfParams.hoistParams,
          powerWire: pdfParams.powerWire,
          craneInstallationSizes: pdfParams.craneInstallationSizes,
          additionalParams: pdfParams.additionalParams
        })
      }
    ).then(() => onClose());
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.title}>Сохранение опросного листа</div>
      <QlistParams />
      <div className={styles.footer}>
        <div
          className={`${styles.button} ${styles.confirm}`}
          role="presentation"
          onClick={handleClickHandler}
        >
          Сохранить
        </div>
        <div
          className={`${styles.button} ${styles.cancel}`}
          role="presentation"
          onClick={onClose}
        >
          Отменить
        </div>
      </div>
    </div>
  );
};

export default QlistSaveForm;