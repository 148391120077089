import styles from './Newslog.module.scss';
import {useSelector} from 'react-redux';
import {getFormattedFeedbackData} from '../../../common/utils/date';

const NewsLog = () => {
  const { newsList } = useSelector((state) => state.news);

  return(
    <div className={styles.container}>
      {newsList.map((item) => {
        return(
          <div key={item.id} className={styles.block}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.content}>{item.content}</div>
            <div className={styles.date}>{getFormattedFeedbackData(item.date)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default NewsLog;