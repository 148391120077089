import styles from '../../QuestionLists.module.scss';

const EmptyTable = ({ isFilteredDataEmpty }) => {
  return(
    <div className={styles.empty}>
      {isFilteredDataEmpty ? 'Листов удовлетворяющих условиям поиска нет' : 'Здесь будут отображаться ваши опросные листы'}
    </div>
  );
};

export default EmptyTable;
