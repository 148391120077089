import { createSlice } from '@reduxjs/toolkit';

export const initialFilterValues = {
  startDate: '2023-09-01',
  endDate: new Date().toISOString().substring(0, 10),
  craneType: null,
  spanLength: 0,
  customer: null,
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState: {...initialFilterValues},
  reducers: {
    startDateSet:  (state, action) => {
      state.startDate = action.payload;
    },
    endDateSet:  (state, action) => {
      state.endDate = action.payload;
    },
    craneTypeSet:  (state, action) => {
      state.craneType = action.payload;
    },
    spanLengthSet:  (state, action) => {
      state.spanLength = action.payload;
    },
    customerSet:  (state, action) => {
      state.customer = action.payload;
    },
    filterValuesSet: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.craneType = action.payload.craneType;
      state.spanLength = action.payload.spanLength;
      state.customer = action.payload.customer;
    },
  }
});

export const {
  startDateSet,
  endDateSet,
  craneTypeSet,
  spanLengthSet,
  customerSet,
  filterValuesSet
} = filterSlice.actions;

export default filterSlice.reducer;
