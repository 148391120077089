import styles from './ButtonBlock.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hasChangesSet } from '../../../store/slices/qlistParams/qlistParamsSlice';

const ButtonBlock = ({ buttons, defaultValue, onChange }) => {
  const [activeButton, setActiveButton] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveButton(defaultValue);
  }, []);

  const onSelectChange = (data) => {
    setActiveButton(data);
    dispatch(onChange(data));
    dispatch(hasChangesSet(true));
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {buttons.map(item => {
          const buttonStyle = item === activeButton ? `${styles.button} ${styles.active}` : `${styles.button}`;

          return (
            <div
              className={buttonStyle}
              key={item}
              role="presentation"
              onClick={() => onSelectChange(item)}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonBlock;
