const LeftIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.9844 13.9877L14.9844 18.9877L20.9844 23.9877" stroke="#3D487C" strokeWidth="1.25"
        strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default LeftIcon;