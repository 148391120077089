const GostReferenceIcon = () => {
  return (
    <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="51.3345" height="51" rx="7" fill="#F3F4F8"/>
      <path d="M30.3599 23.1308L33.1263 24.5398C33.9106 24.9295 33.9347 26.0328 33.1681 26.4558L26.2205
        30.2905C25.5619 30.6541 24.761 30.6541 24.1022 30.2905L17.1546 26.4558C16.3881 26.0328 16.4122 24.9295
        17.1965 24.5398L19.9629 23.1308M30.3599 28.0058L33.1263 29.4148C33.9106 29.8045 33.9347 30.9078 33.1681
        31.3308L26.2205 35.1655C25.5619 35.5291 24.761 35.5291 24.1022 35.1655L17.1546 31.3308C16.3881 30.9078
        16.4122 29.8045 17.1965 29.4148L19.9629 28.0058M26.1368 16.1929L33.1263 19.6649C33.9106 20.0545 33.9347
        21.1577 33.1681 21.5808L26.2205 25.4155C25.5619 25.7791 24.761 25.7791 24.1022 25.4155L17.1546 21.5808C16.3881
        21.1577 16.4122 20.0545 17.1965 19.6649L24.1861 16.1929C24.8001 15.8879 25.5227 15.8879 26.1368 16.1929Z"
      stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"
      />
    </svg>

  );
};

export default GostReferenceIcon;