import $api from '../http/api';
import { store } from '../store/store';
import { notifySet } from '../store/slices/modals/modalSlice';
import { paramsSet } from '../store/slices/calculation/calculationSlice';

export const calculation = async (data, qlistId, qlistParams, params) => {
  try {
    const editedData = Object.values(data).reduce((accumulator, item) => {
      return {
        ...accumulator,
        ...Object.entries(item).reduce((innerAccumulator, [key, value]) => {
          if (typeof value === 'object') {
            return { ...innerAccumulator, [key]: value.value };
          }

          return innerAccumulator;
        }, {})
      };
    }, {});

    const response = await $api.post(
      '/manager/calculate',
      { ...editedData, qlistId: qlistId, qlistParams: qlistParams},
      { withCredentials: true }
    );

    const editedResponse = Object.entries(params).reduce((accumulator, [key, value]) => {
      return {
        ...accumulator,
        [key]: {
          ...value,
          values: Object.entries(value.values).reduce((innerAccumulator, [ param, paramValue ]) => {
            if (typeof paramValue === 'object') {
              return {
                ...innerAccumulator,
                [param]: { ...paramValue, value: response.data[key][param] }
              };
            }
          }, {})
        }
      };
    }, {});

    store.dispatch(paramsSet(editedResponse));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении данных расчета',
      isShow: true
    }));
  }
};