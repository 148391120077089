import styles from './TextBlock.module.scss';
import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { hasChangesSet } from '../../../store/slices/qlistParams/qlistParamsSlice';

const TextBlock = ({ label, onChange, defaultValue, placeholder}) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(defaultValue);
  }, []);

  const onInputChange = (data) => {
    setValue(data);
  };

  const onInputBlur = () => {
    if (value) {
      setValue(value);
      dispatch(onChange(value));
    } else {
      setValue('');
      dispatch(onChange(null));
    }
    dispatch(hasChangesSet(true));
  };

  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <textarea
        className={styles.text}
        onChange={(e) => onInputChange(e.target.value)}
        onBlur={onInputBlur}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default TextBlock;
