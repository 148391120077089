import ButtonBlock from '../../../../../../common/components/ButtonBlock/ButtonBlock';
import {
  climaticDesignItems,
  implementationButtons,
  craneRailTypeItems,
  explosionsProtectionRequirementsItem,
  fireSafetyRequirementsItem,
  liftingHeightItems,
  loadCapacityItems,
  mainBeamTypeButtons,
  movementSpeedItems,
  secondMicroSpeedItems,
  seismicDesignItems,
  workingModeByIsoItems,
  workingTemperatureItems,
  spanLengthDoubleItems,
  fireSafetyClassItem,
  explosionsProtectionGroupItems,
  explosionsProtectionTemperatureItems,
  spanLengthOneItems,
  deliveryFeaturesButtons
} from '../../../../../../common/consts/metalConstruction';
import {
  climaticDesignSet,
  implementationSet,
  consoleLengthSet,
  craneRailTypeSet,
  explosionsProtectionRequirementsSet,
  fireSafetyRequirementsSet,
  fullCraneLengthSet,
  liftingHeightSet,
  loadCapacitySet,
  mainBeamTypeSet,
  movementSpeedSet,
  secondMicroSpeedSet,
  seismicDesignSet,
  wheelLoadSet,
  workingModeByIsoSet,
  workingTemperatureSet,
  liftingHeightCustomSet,
  workingTemperatureCustomSet,
  climaticDesignCustomSet,
  fireSafetyClassSet,
  explosionsProtectionGroupSet,
  explosionsProtectionTemperatureSet,
  seismicDesignCustomSet,
  movementSpeedCustomSet,
  secondMicroSpeedCustomSet,
  wheelLoadCustomSet,
  deliveryFeaturesSet,
  deliveryFeaturesCustomSet,
  workingModeByIsoCustomSet,
  craneRailTypeCustomSet,
  specifiedCraneCapacitySet,
  specifiedCraneCapacityCustomSet,
  craneWeightCustomSet,
  craneWeightSet,
  twoSpanLengthSet,
  twoSpanLengthCustomSet,
  oneSpanLengthCustomSet,
  oneSpanLengthSet
} from '../../../../../../store/slices/params/metalConstructionSlice';
import DropDownBlock from '../../../../../../common/components/DropDownBlock/DropDownBlock';
import NumericBlock from '../../../../../../common/components/NumericBlock/NumericBlock';
import { CRANE_IMPLEMENTATION, CRANE_TYPE } from '../../../../../../common/consts/enums';
import {useDispatch, useSelector} from 'react-redux';
import ParameterBlock from '../../../../../../common/components/ParameterBlock/ParameterBlock';
import styles from '../../Content.module.scss';
import StringBlock from '../../../../../../common/components/StringBlock/StringBlock';
import { commonItems } from '../../../../../../common/consts/common';
import {
  getCraneCapacity,
  getCustomParamLabel,
  getStandardMassParams
} from '../../../../utils/getStandardParams';
import {useEffect} from 'react';

const MetalConstruction = () => {
  const {
    craneType,
    implementation,
    loadCapacity,
    oneSpanLength,
    twoSpanLength,
    consoleLength,
    fullCraneLength,
    liftingHeight,
    mainBeamType,
    deliveryFeatures,
    workingModeByIso,
    craneRailType,
    workingTemperature,
    climaticDesign,
    fireSafetyRequirements,
    fireSafetyClass,
    explosionsProtectionRequirements,
    explosionsProtectionGroup,
    explosionsProtectionTemperature,
    seismicDesign,
    movementSpeed,
    secondMicroSpeed,
    specifiedCraneCapacity,
    craneWeight,
    wheelLoad
  } = useSelector(state => state.metalConstruction);
  const dispatch = useDispatch();

  const twoSpanLengthFinal =  twoSpanLength.value === 'другое' ? twoSpanLength.customValue : twoSpanLength.value;
  const oneSpanLengthFinal =  oneSpanLength.value === 'другое' ? oneSpanLength.customValue : oneSpanLength.value;
  const overheadFullLength = craneType.value === CRANE_TYPE.overheadTwoSpan
    ? parseInt(twoSpanLengthFinal.split('+')[0]) * 2 + (consoleLength.value * 2)
    : (consoleLength.value * 2) + parseInt(oneSpanLengthFinal);

  const spanLength = craneType.value === CRANE_TYPE.overheadTwoSpan ? twoSpanLengthFinal : oneSpanLengthFinal;
  const standardCraneParams = getStandardMassParams(craneType.value, loadCapacity.value, spanLength);
  const craneCapacity = getCraneCapacity(spanLength, loadCapacity.value);

  useEffect(() => {
    if (craneType.value !== CRANE_TYPE.pylon && fullCraneLength.value !== overheadFullLength && consoleLength.value) {
      dispatch(fullCraneLengthSet(overheadFullLength));
    }

    if (craneType.value === CRANE_TYPE.pylon && !consoleLength.value){
      dispatch(fullCraneLengthSet(''));
    }

    if (specifiedCraneCapacity.value === 'не задано' && specifiedCraneCapacity.customValue !== craneCapacity) {
      dispatch(specifiedCraneCapacityCustomSet(craneCapacity));
    }

    if (craneWeight.value === 'не задано' && craneWeight.customValue !== standardCraneParams.craneMass) {
      dispatch(craneWeightCustomSet(standardCraneParams.craneMass));
    }

    if (wheelLoad.value === 'не задано' && wheelLoad.customValue !== standardCraneParams.wheelLoad) {
      dispatch(wheelLoadCustomSet(standardCraneParams.wheelLoad));
    }
  }, [twoSpanLengthFinal, oneSpanLengthFinal, overheadFullLength]);

  return (
    <>
      <ParameterBlock
        title={implementation.label}
        isValid={!!implementation.value}
      >
        <ButtonBlock
          buttons={implementationButtons}
          onChange={implementationSet}
          defaultValue={implementation.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={loadCapacity.label}
        isValid={!!loadCapacity.value}
      >
        <DropDownBlock
          list={loadCapacityItems}
          onChange={loadCapacitySet}
          defaultValue={loadCapacity.value}
        />
      </ParameterBlock>
      {(craneType.value !== CRANE_TYPE.overheadTwoSpan) && (
        <ParameterBlock
          title={oneSpanLength.label}
          isValid={!!oneSpanLength.value && oneSpanLength.value !== 'другое' && !!oneSpanLength.customValue }
        >
          <DropDownBlock
            list={spanLengthOneItems}
            onChange={oneSpanLengthSet}
            defaultValue={oneSpanLength.value}
          />
          {oneSpanLength.value === 'другое' && (
            <>
              <div className={styles.title}>{oneSpanLength.customLabel}</div>
              <NumericBlock
                onChange={oneSpanLengthCustomSet}
                defaultValue={oneSpanLength.customValue}
              />
            </>
          )}
        </ParameterBlock>
      )}
      {craneType.value === CRANE_TYPE.overheadTwoSpan && <ParameterBlock
        title={twoSpanLength.label}
        isValid={!!twoSpanLength.value && twoSpanLength.value !== 'другое' && !!twoSpanLength.customValue }
      >
        <DropDownBlock
          list={spanLengthDoubleItems}
          onChange={twoSpanLengthSet}
          defaultValue={twoSpanLength.value}
        />
        {twoSpanLength.value === 'другое' && craneType.value === CRANE_TYPE.overheadTwoSpan && (
          <>
            <div className={styles.title}>{twoSpanLength.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={twoSpanLengthCustomSet}
                defaultValue={twoSpanLength.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>}
      {(craneType.value === CRANE_TYPE.overheadOneSpan || craneType.value === CRANE_TYPE.overheadTwoSpan) && (
        <>
          <ParameterBlock
            title={consoleLength.label}
            isValid={!!consoleLength.value}
          >
            <NumericBlock
              onChange={consoleLengthSet}
              defaultValue={consoleLength.value}
            />
          </ParameterBlock>
        </>
      )}

      <ParameterBlock
        title={fullCraneLength.label}
        isValid={!!fullCraneLength.value}
      >
        <NumericBlock
          onChange={fullCraneLengthSet}
          defaultValue={fullCraneLength.value}
          disabled={craneType.value !== CRANE_TYPE.pylon}
          placeholder={craneType.value !== CRANE_TYPE.pylon && 'Введите длину консолей'}
        />
      </ParameterBlock>

      <ParameterBlock
        title={liftingHeight.label}
        isValid={(!!liftingHeight.value && liftingHeight.value !== 'другое') || !!liftingHeight.customValue}
      >
        <DropDownBlock
          list={liftingHeightItems}
          onChange={liftingHeightSet}
          defaultValue={liftingHeight.value}
        />
        {liftingHeight.value === 'другое' && (
          <>
            <div className={styles.title}>{liftingHeight.customLabel}</div>
            <NumericBlock
              onChange={liftingHeightCustomSet}
              defaultValue={liftingHeight.customValue}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={mainBeamType.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={mainBeamTypeButtons}
          onChange={mainBeamTypeSet}
          defaultValue={mainBeamType.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={deliveryFeatures.label}
        isValid={true}
      >
        <ButtonBlock
          buttons={deliveryFeaturesButtons}
          onChange={deliveryFeaturesSet}
          defaultValue={deliveryFeatures.value}
        />
        {deliveryFeatures.value === 'другое' && (
          <>
            <div className={styles.title}>{deliveryFeatures.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={deliveryFeaturesCustomSet}
                defaultValue={deliveryFeatures.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={workingModeByIso.label}
        isValid={!!workingModeByIso.value}
      >
        <ButtonBlock
          buttons={workingModeByIsoItems}
          onChange={workingModeByIsoSet}
          defaultValue={workingModeByIso.value}
        />
        {workingModeByIso.value === 'другое' && (
          <>
            <div className={styles.title}>{workingModeByIso.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={workingModeByIsoCustomSet}
                defaultValue={workingModeByIso.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={craneRailType.label}
        isValid={!!craneRailType.value}
      >
        <DropDownBlock
          list={craneRailTypeItems}
          onChange={craneRailTypeSet}
          defaultValue={craneRailType.value}
        />
        {craneRailType.value === 'другое' && (
          <>
            <div className={styles.title}>{craneRailType.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={craneRailTypeCustomSet}
                defaultValue={craneRailType.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={workingTemperature.label}
        isValid={(!!workingTemperature.value && workingTemperature.value !== 'другое') || !!workingTemperature.customValue}
      >
        <ButtonBlock
          buttons={workingTemperatureItems}
          onChange={workingTemperatureSet}
          defaultValue={workingTemperature.value}
        />
        {workingTemperature.value === 'другое' && (
          <>
            <div className={styles.title}>{workingTemperature.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={workingTemperatureCustomSet}
                defaultValue={workingTemperature.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={climaticDesign.label}
        isValid={(!!climaticDesign.value && climaticDesign.value !== 'другое') || !!climaticDesign.customValue}
      >
        <ButtonBlock
          buttons={climaticDesignItems}
          onChange={climaticDesignSet}
          defaultValue={climaticDesign.value}
        />
        {climaticDesign.value === 'другое' && (
          <>
            <div className={styles.title}>{climaticDesign.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={climaticDesignCustomSet}
                defaultValue={climaticDesign.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      {implementation.value === CRANE_IMPLEMENTATION.fireProof && (
        <ParameterBlock
          title={fireSafetyRequirements.label}
          isValid={(!!fireSafetyRequirements.value && fireSafetyRequirements.value !== 'требуется') || !!fireSafetyClass.value}
        >
          <ButtonBlock
            label={fireSafetyRequirements.label}
            buttons={fireSafetyRequirementsItem}
            onChange={fireSafetyRequirementsSet}
            defaultValue={fireSafetyRequirements.value}
          />
          {fireSafetyRequirements.value === 'требуется' && (
            <>
              <div className={styles.title}>{fireSafetyClass.value}</div>
              <DropDownBlock
                list={fireSafetyClassItem}
                onChange={fireSafetyClassSet}
                defaultValue={fireSafetyClass.value}
              />
            </>
          )}
        </ParameterBlock>
      )}
      {implementation.value === CRANE_IMPLEMENTATION.explosionProof && (
        <ParameterBlock
          title={explosionsProtectionRequirements.label}
          isValid={true}
        >
          <ButtonBlock
            buttons={explosionsProtectionRequirementsItem}
            onChange={explosionsProtectionRequirementsSet}
            defaultValue={explosionsProtectionRequirements.value}
          />
          {explosionsProtectionRequirements.value === 'требуется' && (
            <>
              <div className={styles.title}>{explosionsProtectionGroup.label}</div>
              <DropDownBlock
                list={explosionsProtectionGroupItems}
                onChange={explosionsProtectionGroupSet}
                defaultValue={explosionsProtectionGroup.value}
              />
              <div className={styles.title}>{explosionsProtectionTemperature.label}</div>
              <DropDownBlock
                list={explosionsProtectionTemperatureItems}
                onChange={explosionsProtectionTemperatureSet}
                defaultValue={explosionsProtectionTemperature.value}
              />
            </>
          )}
        </ParameterBlock>
      )}
      <ParameterBlock
        title={seismicDesign.label}
        isValid={(!!seismicDesign.value && seismicDesign.value !== 'другое') || !!seismicDesign.customValue}
      >
        <ButtonBlock
          buttons={seismicDesignItems}
          onChange={seismicDesignSet}
          defaultValue={seismicDesign.value}
        />
        {seismicDesign.value === 'другое' && (
          <>
            <div className={styles.title}>{seismicDesign.customLabel}</div>
            <NumericBlock
              onChange={seismicDesignCustomSet}
              defaultValue={seismicDesign.customValue}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={movementSpeed.label}
        isValid={(!!movementSpeed.value && movementSpeed.value !== 'другое') || !!movementSpeed.customValue}
      >
        <ButtonBlock
          buttons={movementSpeedItems}
          onChange={movementSpeedSet}
          defaultValue={movementSpeed.value}
        />
        {movementSpeed.value === 'другое' && (
          <>
            <div className={styles.title}>{movementSpeed.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={movementSpeedCustomSet}
                defaultValue={movementSpeed.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={secondMicroSpeed.label}
        isValid={(!!secondMicroSpeed.value && secondMicroSpeed.value !== 'другое') || !!secondMicroSpeed.customValue}
      >
        <ButtonBlock
          buttons={secondMicroSpeedItems}
          onChange={secondMicroSpeedSet}
          defaultValue={secondMicroSpeed.value}
        />
        {secondMicroSpeed.value === 'другое' && (
          <>
            <div className={styles.title}>{secondMicroSpeed.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={secondMicroSpeedCustomSet}
                defaultValue={secondMicroSpeed.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={specifiedCraneCapacity.label}
        isValid={specifiedCraneCapacity.value !== 'не задано' || specifiedCraneCapacity.customValue !== ''}
      >
        <ButtonBlock
          buttons={commonItems}
          onChange={specifiedCraneCapacitySet}
          defaultValue={specifiedCraneCapacity.value}
        />
        {craneCapacity !== 'Нет значения по ГОСТ' && (
          <>
            {(specifiedCraneCapacity.value === 'не задано' && craneCapacity !== 'Нет значения по ГОСТ') && (
              <>
                <div className={styles.title}>стандартное значение по ГОСТ</div>
                <NumericBlock
                  defaultValue={craneCapacity}
                  disabled
                />
              </>
            )}
          </>
        )}
        {(specifiedCraneCapacity.value === 'другое' || craneCapacity === 'Нет значения по ГОСТ') && (
          <>
            <div className={styles.title}>{getCustomParamLabel(specifiedCraneCapacity, craneCapacity, 'мощности')}</div>
            <NumericBlock
              onChange={specifiedCraneCapacityCustomSet}
              defaultValue={specifiedCraneCapacity.customValue === 'Нет значения по ГОСТ' ? '' : specifiedCraneCapacity.customValue}
              disabled={specifiedCraneCapacity.value === 'не задано'}
              placeholder={specifiedCraneCapacity.value === 'не задано' ? craneCapacity : ''}
            />
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={craneWeight.label}
        isValid={craneWeight.customValue}
      >
        <ButtonBlock
          buttons={commonItems}
          onChange={craneWeightSet}
          defaultValue={craneWeight.value}
        />
        {standardCraneParams.craneMass !== 'Нет значения по ГОСТ' && (
          <>
            {(craneWeight.value === 'не задано' && craneWeight.customValue) && (
              <>
                <div className={styles.title}>стандартное значение по ГОСТ</div>
                <NumericBlock
                  defaultValue={craneWeight.customValue}
                  disabled
                />
              </>
            )}
          </>
        )}
        {(craneWeight.value === 'другое' || standardCraneParams.craneMass === 'Нет значения по ГОСТ') && (
          <>
            <div className={styles.title}>{getCustomParamLabel(craneWeight, standardCraneParams.craneMass,
              'массы крана')}</div>
            <NumericBlock
              onChange={craneWeightCustomSet}
              defaultValue={craneWeight.customValue === 'Нет значения по ГОСТ' ? '' : craneWeight.customValue}
              disabled={craneWeight.value === 'не задано'}
              placeholder={craneWeight.value === 'не задано' ? standardCraneParams.craneMass : ''}
            />
          </>
        )}
      </ParameterBlock>

      <ParameterBlock
        title={wheelLoad.label}
        isValid={wheelLoad.customValue}
      >
        <ButtonBlock
          buttons={commonItems}
          onChange={wheelLoadSet}
          defaultValue={wheelLoad.value}
        />
        {standardCraneParams.wheelLoad !== 'Нет значения по ГОСТ' && (
          <>
            {(wheelLoad.value === 'не задано' && wheelLoad.customValue) && (
              <>
                <div className={styles.title}>стандартное значение по ГОСТ</div>
                <NumericBlock
                  defaultValue={wheelLoad.customValue}
                  disabled
                />
              </>
            )}
          </>
        )}
        {(wheelLoad.value === 'другое' || standardCraneParams.wheelLoad === 'Нет значения по ГОСТ') && (
          <>
            <div className={styles.title}>{getCustomParamLabel(wheelLoad, standardCraneParams.wheelLoad, 'нагрузки на колесо')}</div>
            <NumericBlock
              onChange={wheelLoadCustomSet}
              defaultValue={wheelLoad.customValue === 'Нет значения по ГОСТ' ? '' : wheelLoad.customValue}
              disabled={wheelLoad.value === 'не задано'}
              placeholder={wheelLoad.value === 'не задано' ? standardCraneParams.wheelLoad : ''}
            />
          </>
        )}
      </ParameterBlock>
    </>
  );
};

export default MetalConstruction;
