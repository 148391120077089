import styles from './Guide.module.scss';

const titles = {
  1: 'Приветствуем вас в нашем приложении! Мы создали его для удобного заполнения опросных листов грузпоподъемных кранов. Хотите пройти обучение?',
  2: 'Это основная часть приложения, здесь можно выбрать и указать характеристики крана',
  3: 'В этом блоке располжены кнопки предварительного просмотра опросного листа, скачивания его в формате PDF или сохранения в ваш персональный список',
  4: 'Это боковая панель и кнопки навигации по разделам',
  5: 'Это ваш профиль, здесь можно добавить или изменить свои данные, а если активна премиум-подписка -' +
    ' изменить шапку опросного листа',
  6: 'Здесь будут отображаться ваши сохраненные опросные листы, каждый из которых можно будет просмотреть,' +
    ' загрузить или удалить',
  7: 'В нашем приложении есть таблицы ГОСТ-ов, при необходимости в них можно найти и сверить некоторые параметры',
  8: 'Если вы знаете как улучшить приложение или обнаружили ошибку, напишите нам в разделе "Обратная связь"',
  9: 'На этом все, удачной работы!'
};

const Guide = ({ guideStep, setGuideStep }) => {
  const onNextStageClickHandler = () => {
    guideStep !== 9 && setGuideStep(guideStep + 1);
    guideStep === 9 && setGuideStep(null);
  };

  const onPreviousStageClickHandler = () => {
    guideStep !== 1 && setGuideStep(guideStep - 1);
    guideStep === 1 && setGuideStep(null);
  };

  return(
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          {titles[guideStep]}
        </div>
        <div className={styles.buttonbar}>
          <div
            className={`${styles.button} ${styles.orange}`}
            onClick={onNextStageClickHandler}
          >
            {(guideStep !== 9 && guideStep !== 1) && 'Далее'}
            {guideStep === 1 && 'Да'}
            {guideStep === 9 && 'Завершить'}
          </div>
          <div
            className={styles.button}
            onClick={onPreviousStageClickHandler}
          >
            {guideStep === 1 && 'Нет'}
            {guideStep !== 1 && 'Назад'}
          </div>
        </div>
      </div>
      <div className={styles.background}/>
    </>
  );
};

export default Guide;