const FeedbackIcon = () => {
  return(
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1126_667)">
        <path d="M5.88522 7.28799L6.83526 8.17566L10.6354 11.7263C11.4038 12.4435 12.5963 12.4435 13.3647
         11.7263L18.1254 7.28799M5 18H19C20.1046 18 21 17.1046 21 16V6C21 4.89543 20.1046 4 19 4H5C3.89543 4 3 4.89543
         3 6V16C3 17.1046 3.89543 18 5 18Z" stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1126_667">
          <rect width="24" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default FeedbackIcon;