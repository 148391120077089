const CalculationIcon = ({size}) => {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={`${size}`} height={`${size}`} viewBox="0 0 51 51"
      preserveAspectRatio="xMidYMid meet">
      {size === 51 && <rect width="51" height="51" rx="7" fill="#F3F4F8"/>}
      <g transform="translate(7.000000,43.000000) scale(0.07,-0.07)"
        fill="#3D487C" stroke="none">
        <path d="M85 447 c-3 -7 -4 -100 -3 -207 l3 -195 103 -3 c65 -2 102 1 102 8 0
      6 -36 10 -95 10 l-95 0 0 190 0 190 150 0 150 0 0 -110 c0 -69 4 -110 10 -110
      7 0 10 42 8 118 l-3 117 -163 3 c-126 2 -164 0 -167 -11z"/>
        <path d="M120 370 l0 -50 130 0 130 0 0 50 0 50 -130 0 -130 0 0 -50z m240 0
      l0 -30 -110 0 -110 0 0 30 0 30 110 0 110 0 0 -30z"/>
        <path d="M240 370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
        -10 -4 -10 -10z"/>
        <path d="M280 370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
        -10 -4 -10 -10z"/>
        <path d="M320 370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
        -10 -4 -10 -10z"/>
        <path d="M120 180 l0 -100 80 0 c47 0 80 4 80 10 0 6 -4 10 -10 10 -5 0 -10 9
        -10 20 0 11 7 20 15 20 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 9 -15 20 0
      26 40 28 40 3 1 -17 1 -17 14 -1 7 10 25 22 39 27 21 8 27 17 27 41 l0 30
      -130 0 -130 0 0 -100z m60 60 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13
      7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20
      20 0 13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20
      7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13 -7 -20 -20 -20 -13
      0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-180 -60 c0 -13 -7 -20
      -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m60 0 c0 -13
      -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-60
      -60 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20
      -20z m60 0 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20
      -7 20 -20z"/>
        <path d="M351 186 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83 124
        -149 87z m104 -31 c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 70 86 105
      135 55z"/>
        <path d="M387 149 c-23 -13 -22 -36 3 -51 11 -7 20 -18 20 -25 0 -17 -17 -17
        -23 0 -2 6 -8 9 -13 4 -12 -12 20 -39 39 -31 24 9 22 51 -3 59 -11 3 -20 11
      -20 16 0 6 9 7 20 4 22 -7 27 7 7 23 -8 7 -18 7 -30 1z"/>
      </g>
    </svg>
  );
};

export default CalculationIcon;