import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  coefficients: {
    general: {
      label: 'Основные',
      insuranceCoefficient: {
        value: 0.32,
        label: 'Коэффицент отчислений на страховые выплаты, %'
      },
      overheadCoefficient: {
        value: 0.015,
        label: 'Коэффицент накладных расходов, %'
      },
      profitCoefficient: {
        value: 0.2,
        label: 'Коэффицент прибыли, %'
      },
      totalCoefficient: {
        value: 0.2,
        label: 'Коэффицент итого, %'
      },
      tCost: {
        value: 130000,
        label: 'Стоимость тонны, руб'
      },
      totalWithoutNds: {
        value: 0.2,
        label: 'Наценка без ндс, %'
      }
    },

    materials: {
      label: 'Материалы',
      metalCuttingCoefficient: {
        value: 0.05,
        label: 'Коэффицент резки металла, %'
      },
      transportCoefficient: {
        value: 0.05,
        label: 'Коэффицент транспортных расходов, %'
      },
      paintingCoefficient: {
        value: 0.1,
        label: 'Коэффицент малярных работ, %'
      },
      weldingCoefficient: {
        value: 0.05,
        label: 'Коэффицент сварочных работ, %'
      }
    },

    beams: {
      label: 'Балки',
      turnerCooperationCoefficient: {
        value: 0.3,
        label: 'Токарка кооперация коэффицент, %'
      },
      sheetBox: {
        value: 0.05,
        label: 'Коэффицент лист для коробки, %'
      }
    },

    workers: {
      label: 'Рабочие',
      workingDay: {
        value: 8,
        label: 'Рабочий день, ч'
      },
      fotitr: {
        value: 0.2,
        label: 'Коэффицент фонд оплаты труда, %'
      },
      rate: {
        label: 'Ставка, руб',
        value: 400
      }
    },

    cabin: {
      label: 'Кабина',
      frameWeight: {
        value: 0,
        label: 'Вес рамы'
      },
      CFCWeight: {
        value: 0,
        label: 'Вес маеталла кабина пол и потолок'
      },
      wallsWeight: {
        value: 0,
        label: 'Вес стен'
      },
      vestibuleWeight: {
        value: 0,
        label: 'Проходная вес'
      },
      frameCost: {
        value: 0,
        label: 'Стоимость рамы'
      },
      CFCCost: {
        value: 0,
        label: 'Стоимость металла кабина пол и потолок'
      },
      wallsCost: {
        value: 0,
        label: 'Цена стен'
      },
      vestibuleCost: {
        value: 0,
        label: 'Цена проходной'
      },
      hasPanels: {
        value: 'нет',
        label: 'Отделка',
        subItems: {
          panelsCosts: {
            value: 0,
            label: 'Стоимость отделки'
          }
        }
      },
      hasGlass: {
        value: 'нет',
        label: 'Стекла кабины',
        subItems: {
          glassCosts: {
            value: 0,
            label: 'Стоимость стекол'
          },
        }
      }
    },

    options: {
      label: 'Опции',
      conditioner: {
        value: 'Нет',
        label: 'Кондиционер'
      },
      hasVestibule: {
        value: 'Нет',
        label: 'Проходная'
      },
      hasChemicalResistant: {
        value: 'Нет',
        label: 'Химзащита'
      },
      walkingGalleryOption: {
        value: 'Нет',
        label: 'Проходная'
      },
      bracketCollectorCount: {
        value: 0,
        label: 'Кронштейны токосьемников',
        subItems: {
          bracketCollectorCost: {
            value: 0,
            label: 'Стоимость кронштейна токосьемников'
          },
        }
      },
      bracketCount: {
        value: 0,
        label: 'Кронштейны токоотбойников',
        subItems: {
          bracketCost: {
            value: 0,
            label: ''
          }
        }
      },

      cradleCount: {
        value: 0,
        label: 'Люлька для обслживания троллей',
        subItems: {
          cradleCost: {
            value: 0,
            label: ''
          }
        }
      },
    }
  },

  params: {
    options: {
      label: 'Опции',
      values: {
        walkingGallery: {
          label: 'Проходная галерея',
          value: 0
        },
        bracketCollector: {
          label: 'Кронштейны токосьемников',
          value: 0
        },
        bracket: {
          label: 'кронштейны',
          value: 0
        },
        cradle: {
          label: 'Люлька',
          value: 0
        },
        withoutNDS: {
          label: 'Без ндс',
          value: 0
        },
        profit: {
          label: 'Прибыль',
          value: 0
        },
        totalWithoutNDS: {
          label: 'Итого без ндс',
          value: 0
        },
        total: {
          label: 'Итого',
          value: 0
        }
      }
    },

    endBeam: {
      label: 'Концевая балка',
      values: {
        materialsCosts: {
          label: 'Цена материалов',
          value: 0
        },
        endBeams: {
          label: 'Концевые балки',
          value: 0
        },
        turnerCooperation: {
          label: 'Токарка кооперация',
          value: 0
        },
        FOTR: {
          label: 'Фонд оплаты труда',
          value: 0
        },
        insurance: {
          label: 'Отчисления на страховые выплаты',
          value: 0
        },
        direct: {
          label: 'Прямые расходы',
          value: 0
        },
        overhead: {
          label: 'Накладные расходы',
          value: 0
        },
        withoutNDS: {
          label: 'без ндс',
          value: 0
        },
        profit: {
          label: 'Прибыль',
          value: 0
        },
        totalWithoutNds: {
          label: 'Итого без ндс',
          value: 0
        },
        total: {
          label: 'Итого',
          value: 0
        }
      }
    },

    cabin: {
      label: 'Кабина',
      values: {
        materialsCosts: {
          label: 'Цена материалов',
          value: 0
        },
        mainMaterialsCost: {
          label: 'Цена главных материалов',
          value: 0
        },
        frame: {
          label: 'Рама',
          value: 0
        },
        CFC: {
          label: 'Пол и потолок',
          value: 0
        },
        walls: {
          label: 'Стены',
          value: 0
        },
        glass: {
          label: 'Остекление кабины',
          value: 0
        },
        vestibule: {
          label: 'Проходная',
          value: 0
        },
        panels: {
          label: 'Панели',
          value: 0
        },
        metalCutting: {
          label: 'Резка металла',
          value: 0
        },
        transportCosts: {
          label: 'Транспортные расходы',
          value: 0
        },
        painting: {
          label: 'Покрасочные работы',
          value: 0
        },
        welding: {
          label: 'Сварочные работы',
          value: 0
        },
        FOTR: {
          label: 'Фонд оплаты труда',
          value: 0
        },
        insurance: {
          label: 'Отчисления на страховые выплаты рабочим',
          value: 0
        },
        direct: {
          label: 'Прямые расходы',
          value: 0
        },
        overhead: {
          label: 'Накладные расъоды',
          value: 0
        },
        profit: {
          label: 'Прибыль',
          value: 0
        },
        withoutNDS: {
          label: 'Без ндс',
          value: 0
        },
        totalWithoutNds: {
          label: 'Итого без ндс',
          value: 0
        },
        total: {
          label: 'Итого',
          value: 0
        },
        totalWithOptions: {
          label: 'Итого с опциями',
          value: 0
        }
      }
    },

    workingHours: {
      label: 'Рабочие часы',
      values: {
        endBeam: {
          label: 'Концевая балка',
          value: 0
        },
        bridge: {
          label: 'Пролетная балка',
          value: 0
        },
        controlAssembly: {
          label: 'Контрольная проверка',
          value: 0
        },
        painting: {
          label: 'Покрасочные работы',
          value: 0
        },
        preparation: {
          label: 'Подготовка',
          value: 0
        },
        cabin: {
          label: 'Кабина',
          value: 0
        }
      }
    },

    spanBeam: {
      label: 'Пролетная балка',
      values: {
        mainBeamCost: {
          label: 'Стоимость главной балки',
          value: 0
        },
        sheetBox: {
          label: 'Резка лист коробки',
          value: 0
        },
        dvutavr: {
          label: 'Двутавр',
          value: 0
        },
        transportationCost: {
          label: 'Транспортные расходы',
          value: 0
        },
        paintingMaterials: {
          label: 'Покраска',
          value: 0
        },
        weldingMaterials: {
          label: 'Сварка',
          value: 0
        },
        materialsCostSum: {
          label: 'Стоимость материалов',
          value: 0
        },
        fotitr: {
          label: 'Фонд оплаты труда',
          value: 0
        },
        fotirProduction: {
          label: 'Фонд оплаты труда производство',
          value: 0
        },
        fotirSum: {
          label: 'Итого фонд оплаты труда',
          value: 0
        },
        insurancePayments: {
          label: 'Отчисления на страховые рабочим',
          value: 0
        },
        overheadCosts: {
          label: 'Накладные расходы',
          value: 0
        },
        directCosts: {
          label: 'Прямые расходы',
          value: 0
        },
        sumWithoutNds: {
          label: 'Сумма без ндс',
          value: 0
        },
        profit: {
          label: 'Прибыль',
          value: 0
        },
        totalWithoutNds: {
          label: 'Итого без ндс',
          value: 0
        },
        nds: {
          label: 'Итого',
          value: 0
        }
      }
    }
  }
};

export const calculationSlice = createSlice({
  name: 'calculation',
  initialState,
  reducers: {
    coefficientsSet: (state, action) => {
      state.coefficients = action.payload;
    },
    paramsSet: (state, action) => {
      state.params = action.payload;
    }
  }
});

export const {
  paramsSet,
  coefficientsSet,
} = calculationSlice.actions;

export default calculationSlice.reducer;