import { CRANE_TYPE } from '../../../common/consts/enums';
import {
  overheadOneSpanGeometricParams,
  overheadTwoSpanGeometricParams,
  pylonGeometricParameters
} from '../consts/standardGeometricParams';
import { standardCraneCapacityParams } from '../consts/standardCraneCapacityParams';
import {
  overheadOneSpanMassParams,
  overheadTwoSpanMassParams,
  pylonOneSpanMassParams
} from '../consts/standardMassParams';

export const getStandardGeometricParams = (type, loadCapacity) => {
  if (type === CRANE_TYPE.overheadOneSpan) {
    return overheadOneSpanGeometricParams[loadCapacity];
  }
  if (type === CRANE_TYPE.overheadTwoSpan) {
    return overheadTwoSpanGeometricParams[loadCapacity];
  }

  if (type === CRANE_TYPE.pylon) {
    return pylonGeometricParameters[loadCapacity];
  }
};

export const getStandardMassParams = (type, loadCapacity, spanLength) => {
  if (type === CRANE_TYPE.overheadOneSpan) {
    return {
      capacity:  standardCraneCapacityParams[loadCapacity] ? standardCraneCapacityParams[loadCapacity] : 'Нет значения по ГОСТ',
      craneMass: (overheadOneSpanMassParams[loadCapacity] && overheadOneSpanMassParams[loadCapacity][spanLength]) ? overheadOneSpanMassParams[loadCapacity][spanLength].craneMass : 'Нет значения по ГОСТ',
      wheelLoad:  (overheadOneSpanMassParams[loadCapacity] && overheadOneSpanMassParams[loadCapacity][spanLength]) ? overheadOneSpanMassParams[loadCapacity][spanLength].wheelLoad : 'Нет значения по ГОСТ'
    };
  }

  if (type === CRANE_TYPE.overheadTwoSpan) {
    return {
      capacity: standardCraneCapacityParams[loadCapacity] ? standardCraneCapacityParams[loadCapacity] : 'Нет значения по ГОСТ',
      craneMass: (overheadTwoSpanMassParams[loadCapacity] && overheadTwoSpanMassParams[loadCapacity][spanLength]) ? overheadTwoSpanMassParams[loadCapacity][spanLength].craneMass : 'Нет значения по ГОСТ',
      wheelLoad: ( overheadTwoSpanMassParams[loadCapacity] &&  overheadTwoSpanMassParams[loadCapacity][spanLength]) ? overheadTwoSpanMassParams[loadCapacity][spanLength].wheelLoad : 'Нет значения по ГОСТ'
    };
  }

  if (type === CRANE_TYPE.pylon) {
    return {
      capacity:  standardCraneCapacityParams[loadCapacity] ? standardCraneCapacityParams[loadCapacity] : 'Нет значения по ГОСТ',
      craneMass: (pylonOneSpanMassParams[loadCapacity] && pylonOneSpanMassParams[loadCapacity][spanLength]) ? pylonOneSpanMassParams[loadCapacity][spanLength].craneMass : 'Нет значения по ГОСТ',
      wheelLoad: (pylonOneSpanMassParams[loadCapacity] && pylonOneSpanMassParams[loadCapacity][spanLength]) ? pylonOneSpanMassParams[loadCapacity][spanLength].wheelLoad : 'Нет значения по ГОСТ'
    };
  }
};

export const getCraneCapacity = (spanLength, loadCapacity) => {
  return (standardCraneCapacityParams[spanLength] && standardCraneCapacityParams[spanLength][loadCapacity]) ? standardCraneCapacityParams[spanLength][loadCapacity] : 'Нет значения по ГОСТ';
};

export const getCustomParamLabel = (param, standardValue, label) => {
  if (param.value === 'не задано' && standardValue === 'Нет значения по ГОСТ') {
    return `Для выбранного пролета и грузоподъемности нет стандартных значений ${label}`;
  }

  if (param.value === 'не задано' && standardValue !== 'Нет значения по ГОСТ') {
    return 'Значение по ГОСТ';
  }

  if (param.value !== 'не задано') {
    return param.customLabel;
  }
};
