import styles from './PdfViewModal.module.scss';
import { useSelector } from 'react-redux';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import PdfDocument from '../PdfDocument/PdfDocument';
import {useEffect, useRef} from 'react';

const PdfViewModal = ({ onClose }) => {
  const pdfParams = {
    metalConstruction: useSelector(state => state.metalConstruction),
    electroEquip: useSelector(state => state.electroEquip),
    hoistParams: useSelector(state => state.hoistParams),
    powerWire: useSelector(state => state.powerWire),
    craneInstallationSizes: useSelector(state => state.craneInstallationSizes),
    additionalParams: useSelector(state => state.additionalParams),
    profile: useSelector(state => state.profile),
    qlistParams: useSelector(state => state.qlistParams),
    customerParams: useSelector(state => state.customerParams)
  };

  const ref = useRef();

  useEffect(() => {
    ref.current.focus();
  }, []);

  const pdfStyles = StyleSheet.create({
    viewer: {
      width: '100%',
      height: '90%'
    }
  });

  return(
    <div
      onKeyDown={(event) => event.key === 'Escape' && onClose(false)}
      tabIndex={0}
      ref={ref}
    >
      <div className={styles.container}>
        <div className={styles.title}>Просмотр опросного листа</div>
        <div className={styles.viewer}>
          <PDFViewer style={pdfStyles.viewer}>
            <PdfDocument params={pdfParams} />
          </PDFViewer>
          <div className={styles.footer}>
            <div
              className={styles.button}
              onClick={() => onClose(false)}
            >
              Закрыть
            </div>
          </div>
        </div>
      </div>
      <div className={styles.background} />
    </div>
  );
};

export default PdfViewModal;