import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newsList: []
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    newsListSet:  (state, action) => {
      state.newsList = [...action.payload];
    }
  }
});

export const {
  newsListSet
} = newsSlice.actions;

export default newsSlice.reducer;