import { useState } from 'react';

const range = (start, end) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({ contentPerPage, siblingCount = 1, count }) => {
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(count / contentPerPage);
  const isShowDots = pageCount > 3 + 2 * siblingCount;
  const lastContentIndex = page * contentPerPage;
  const firstContentIndex = lastContentIndex - contentPerPage;

  const changePage = (direction) => {
    setPage((state) => {
      if (direction) {
        if (state === pageCount) {
          return state;
        }

        return state + 1;
      }

      if (state === 1) {
        return state;
      }

      return state - 1;
    });
  };

  const setPageSafe = (num) => {
    if (num > pageCount) {
      setPage(pageCount);
    }

    if (num < 1) {
      setPage(1);
    }

    setPage(num);
  };

  const leftSiblingIndex = Math.max(page - siblingCount, 2);
  const rightSiblingIndex = Math.min(page + siblingCount, pageCount - 1);
  const isLeftDotsVisible = isShowDots && (page - siblingCount) > siblingCount + 1;
  const isRightDotsVisible = isShowDots && (page + siblingCount) < pageCount - 1;

  const leftBorder = !isRightDotsVisible ? pageCount - (3 + siblingCount) : leftSiblingIndex;
  const rightBorder = !isLeftDotsVisible ? 3 + 2 * siblingCount : rightSiblingIndex;

  const pagesToShow = isShowDots ? range(leftBorder, rightBorder) : range(2, pageCount - 1);

  return {
    totalPages: pageCount,
    nextPage: () => changePage(true),
    prevPage: () => changePage(false),
    setPage: setPageSafe,
    firstContentIndex,
    lastContentIndex,
    page,
    pagesToShow,
    isLeftDotsVisible,
    isRightDotsVisible
  };
};

export default usePagination;
