export const powerWireTypeItems = [
  'не задано',
  'кабельный по струне',
  'кабельный по С-профилю',
  'троллейный закрытый шинопровод, один токосьемник',
  'троллейный закрытый шинопровод, двойной токосьемник',
  'троллейный открытый, токосьемники ТК9А',
  'другое'
];

export const powerWireLengthItems = [
  'не задан',
  'другое'
];

export const  supplyInputCabinetItems = [
  'не требуется',
  'автомат в существующий шкаф',
  'шкаф ввода с рубильником'
];

