export const getFormattedFeedbackData = (date) => {
  const inputDate = new Date(date);
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  if (inputDate.setHours(0,0,0,0) === todayDate.setHours(0,0,0,0)) {
    return `сегодня в ${date.substring(11, 19)}`;
  }

  if (inputDate.setHours(0,0,0,0) === yesterdayDate.setHours(0,0,0,0)) {
    return `вчера в ${date.substring(11, 19)}`;
  }

  return new Date(date).toLocaleString();
};