import styles from './ListItem.module.scss';
import UserIcon from '../../../../../common/icons/UserIcon/UserIcon';
import StarIcon from '../../../../../common/icons/StarIcon/StarIcon';
import FeedbackDateBlock from '../../FeedbackDateBlock/FeedbackDateBlock';

const ListItem = ({ data, activeItemId, hasReadByUser }) => {
  const containerStyle = data.id === activeItemId ? `${styles.container} ${styles.active}` : `${styles.container}`;

  return (
    <div className={containerStyle}>
      <UserIcon />
      <div className={styles.block}>
        <div className={styles.title}>{data.title}</div>
        <FeedbackDateBlock date={data.date} />
      </div>
      <StarIcon isFill={!hasReadByUser}/>
    </div>
  );
};

export default ListItem;