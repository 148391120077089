import styles from './ModalWindow.module.scss';
import { useDispatch } from 'react-redux';
import { modalWindowSet } from '../../../store/slices/modals/modalSlice';
import CloseButtonIcon from '../NotifyBlock/Icons/CloseButtonIcon';

const modalWindow = ({ params, onConfirm }) => {
  const dispatch = useDispatch();
  
  const onCloseHandler = () => {
    dispatch(modalWindowSet({...params, isShow: false}));
  };

  const onConfirmHandler = () => {
    onConfirm();
    dispatch(modalWindowSet({...params, isShow: false}));
  };

  return(
    <div className={`${styles.container} ${params.page === 'auth' ? `${styles.auth}` : `${styles.main}`}`}>
      <div
        className={styles.close}
        onClick={onCloseHandler}
      >
        <CloseButtonIcon color={'#ACB1CD'}/>
      </div>
      <div className={styles.title}>{params.title}</div>
      <div className={styles.message}>{params.message}</div>
      <div className={styles.bar}>
        {params.type === 'confirm' ? (
          <>
            <div
              className={`${styles.button} ${styles.orange}`}
              onClick={onConfirmHandler}
            >
              Да
            </div>
            <div
              className={styles.button}
              onClick={onCloseHandler}
            >
              Нет
            </div>
          </>
        ) : (
          <div
            className={`${styles.button} ${styles.orange}`}
            onClick={onCloseHandler}
          >
            Ок
          </div>
        )}
      </div>
    </div>
  );
};

export default modalWindow;