export const standardCraneCapacityParams = {
  '6': {
    '0.5': 3.5,
    '1': 3.5,
    '2': 5,
    '3.2': 7,
    '5': 10.5,
    '10': 18,
    '12.5': 18,
    '16.5': 18,
    '20': 28
  },
  '7.5+7.5': {
    '0.5': 3.5,
    '1': 3.5,
    '2': 5,
    '3.2': 7,
    '5': 10.5,
    '10': 18,
    '12.5': 18,
    '16.5': 18,
    '20': 28
  },
  '9': {
    '0.5': 3.5,
    '1': 3.5,
    '2': 5,
    '3.2': 7,
    '5': 10.5,
    '10': 18,
    '12.5': 18,
    '16.5': 18,
    '20': 28
  },
  '9+9': {
    '0.5': 3.5,
    '1': 3.5,
    '2': 5,
    '3.2': 7,
    '5': 10.5,
    '10': 18,
    '12.5': 18,
    '16.5': 18,
    '20': 28
  },
  '10.5+10.5': {
    '0.5': 3.5,
    '1': 3.5,
    '2': 5,
    '3.2': 7,
    '5': 10.5,
    '10': 18,
    '12.5': 18,
    '16.5': 18,
    '20': 28
  },
  '12': {
    '0.5': 3.5,
    '1': 3.5,
    '2': 5,
    '3.2': 7,
    '5': 10.5,
    '10': 18,
    '12.5': 18,
    '16.5': 18,
    '20': 28
  },
  '12+12': {
    '0.5': 3.5,
    '1': 3.5,
    '2': 5,
    '3.2': 7,
    '5': 10.5,
    '10': 18,
    '12.5': 18,
    '16.5': 18,
    '20': 28
  },
  '15': {
    '0.5': 5,
    '1': 5,
    '2': 6.5,
    '3.2': 8.5,
    '5': 11.5,
    '10': 19.5,
    '12.5': 19.5,
    '16.5': 19.5,
    '20': 30
  },
  '16.5': {
    '0.5': 5,
    '1': 5,
    '2': 6.5,
    '3.2': 8.5,
    '5': 11.5,
    '10': 19.5,
    '12.5': 19.5,
    '16.5': 19.5,
    '20': 30
  },
  '18': {
    '0.5': 5,
    '1': 5,
    '2': 6.5,
    '3.2': 8.5,
    '5': 11.5,
    '10': 19.5,
    '12.5': 19.5,
    '16.5': 19.5,
    '20': 30
  },
  '22.5': {
    '0.5': 5,
    '1': 5,
    '2': 6.5,
    '3.2': 8.5,
    '5': 11.5,
    '10': 19.5,
    '12.5': 19.5,
    '16.5': 19.5,
    '20': 30
  }
};
