import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notify: {
    params: {
      type: '',
      message: '',
      isShow: false
    }
  },

  modalWindow: {
    params: {
      type: 'confirm',
      page: 'auth',
      title: 'Активируйте учётную запись',
      message: 'Ранее мы отправили вам на почту письмо для подтверждения регистрации. Пожалуйста, перейдите по ссылке в письме.',
      isShow: false
    }
  }
};

export const modalSlice = createSlice({
  name: 'additionalParams',
  initialState,
  reducers: {
    notifySet:  (state, action) => {
      state.notify.params = action.payload;
    },
    modalWindowSet:  (state, action) => {
      state.modalWindow.params = action.payload;
    }
  }
});

export const {
  notifySet,
  modalWindowSet
} = modalSlice.actions;

export default modalSlice.reducer;