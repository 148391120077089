import { CRANE_IMPLEMENTATION, CRANE_TYPE } from './enums';

export const craneTypeButtons = [
  CRANE_TYPE.overheadOneSpan,
  CRANE_TYPE.overheadTwoSpan,
  CRANE_TYPE.pylon
];

export const implementationButtons = [
  CRANE_IMPLEMENTATION.industrial,
  CRANE_IMPLEMENTATION.explosionProof,
  CRANE_IMPLEMENTATION.chemicalResistant,
  CRANE_IMPLEMENTATION.fireProof
];

export const mainBeamTypeButtons = [
  'не задано',
  'Двутавр (стандарт)',
  'Коробчатое решение'
];

export const deliveryFeaturesButtons = [
  'не задано',
  'другое'
];

export const loadCapacityItems = [
  '0.5',
  '1',
  '2',
  '3.2',
  '5',
  '10',
  '12.5',
  '16'
];

export const spanLengthOneItems = [
  '6',
  '9',
  '12',
  '15',
  '16.5',
  '18',
  '22.5',
  'другое'
];

export const spanLengthDoubleItems = [
  '7.5+7.5',
  '9.0+9.0',
  '10.5+10.5',
  '12.0+12.0',
  'другое'
];

export const liftingHeightItems = [
  '6',
  '9',
  '12',
  '18',
  '24',
  '30',
  'другое'
];

export const workingModeByIsoItems = [
  'не задано',
  'A3 (стандарт)',
  'А5',
  'А7',
  'другое'
];

export const craneRailTypeItems = [
  'двутавр 24',
  'двутавр 30',
  'двутавр 36',
  'двутавр 45',
  'двутавр 50',
  'рельс р24',
  'рельс кр70',
  'квадрат 50х50',
  'другое'
];

export const workingTemperatureItems = [
  'не задано',
  '-20 +40',
  '-40 +40',
  'другое'
];

export const climaticDesignItems = [
  'на открытом воздухе (У1)',
  'под навесом (У2)',
  'в закрытом помещении (У3)',
  'в отапливаемом помещении (У4)',
  'другое'
];

export const fireSafetyRequirementsItem = [
  'нет',
  'требуется'
];

export const fireSafetyClassItem = [
  'класс П1',
  'класс П2',
  'класс П2А'
];

export const explosionsProtectionRequirementsItem = [
  'нет',
  'требуется'
];

export const explosionsProtectionGroupItems = [
  'I',
  'IIA',
  'IIB',
  'IIC',
  'III'
];

export const explosionsProtectionTemperatureItems = [
  'Т1 (450°С)',
  'Т2 (300°С)',
  'Т3 (200°С)',
  'Т4 (135°С)',
  'Т5 (100°С)',
  'Т6 (85°С)'
];

export const seismicDesignItems = [
  '6 (стандарт)',
  '9',
  'другое'
];
export const movementSpeedItems = [
  '20 м/мин',
  '40 м/мин',
  'другое'
];

export const secondMicroSpeedItems = [
  'не требуется',
  '5 м/мин',
  '10 м/мин',
  '20 м/мин',
  'другое'
];
