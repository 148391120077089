import styles from './NumericBlock.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hasChangesSet } from '../../../store/slices/qlistParams/qlistParamsSlice';

const NumericBlock = ({ onChange, defaultValue, disabled, placeholder }) => {
  const [value, setValue] = useState('');

  const dispatch = useDispatch();
  const placeholderValue = disabled && placeholder ? placeholder : '';

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onInputChange = (data) => {
    setValue(data.replace(/[^\d.{0},]+/gm, ''));
  };

  const onInputBlur = () => {
    dispatch(hasChangesSet(true));
    if (value) {
      if (typeof value === 'string') {
        value.replace(/,/g, '.');
      }

      setValue(value);
      dispatch(onChange(Number(value)));
    } else {
      setValue('');
      dispatch(onChange(''));
    }
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.number}
        onChange={(e) => onInputChange(e.target.value)}
        onBlur={onInputBlur}
        placeholder={disabled ? placeholderValue : 'Введите значение'}
        value={value}
        disabled={disabled}
        onKeyDown={(e) => e.key === 'Enter' && onInputBlur()}
      />
    </div>
  );
};

export default NumericBlock;
