import styles from './GostParamsSection.module.scss';
import GeometricTable from './components/GeometricTable/GeometricTable';
import {
  overheadOneSpanGeometricParams,
  overheadTwoSpanGeometricParams,
  pylonGeometricParameters
} from '../singleGirder/consts/standardGeometricParams';
import {
  overheadOneSpanMassParams,
  overheadTwoSpanMassParams,
  pylonOneSpanMassParams
} from '../singleGirder/consts/standardMassParams';
import MassTable from './components/MassTable/MassTable';
import CapacityTable from './components/CapacityTable/CapacityTable';
import { standardCraneCapacityParams } from '../singleGirder/consts/standardCraneCapacityParams';

const geometricParams = [
  {
    label: 'A, длина концевой балки',
    param: 'a',
  },
  {
    label: 'B, база концевой балки',
    param: 'b',
  },
  {
    label: 'h, высота крана от головки рельса, мм',
    param: 'h',
  },
  {
    label: 'строит высота таи из каталога, мм',
    param: 'tai',
  },
  {
    label: 'h1, расстояние от головки рельса/двутавра до нижнего положения крюка крана',
    param: 'h1',
  },
  {
    label: 'C1, высота от путей до центра буфера, мм',
    param: 'c1',
  }
];

const massParams = [
  'грузоподъемность',
  'пролет, м',
  'масса крана, тонн',
  'масса тали 12 м, тонн',
  'нагрузка на колесо, кН'
];

const capacityParams = [
  'пролет\\грузопод',
  '0.5',
  '1',
  '2',
  '3.2',
  '5',
  '10',
  '12.5',
  '16.5',
  '20'
];

const GostParamsSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.scroll}>
        <GeometricTable
          title={'Таблица геометрических параметров СТАНДАРТ кранов подвесных однопролетных ГОСТ 7890-93'}
          params={geometricParams}
          data={overheadOneSpanGeometricParams}
        />
        <GeometricTable
          title={'Таблица геометрических параметров СТАНДАРТ кранов опорных однопролетных ГОСТ 22045-89'}
          params={geometricParams}
          data={pylonGeometricParameters}
        />
        <GeometricTable
          title={'Таблица геометрических параметров СТАНДАРТ кранов подвесных двухпролетных ГОСТ 22045-89'}
          params={geometricParams}
          data={overheadTwoSpanGeometricParams}
        />
        <MassTable
          title={'Параметры крана опорного по ГОСТ 22045-89, тонн'}
          labels={massParams}
          data={pylonOneSpanMassParams}
        />
        <MassTable
          title={'Параметры крана подвесного по ГОСТ 7890-93, тонн'}
          labels={massParams}
          data={overheadOneSpanMassParams}
        />
        <MassTable
          title={'Параметры крана опорного по ГОСТ 22045-89, тонн'}
          labels={massParams}
          data={overheadTwoSpanMassParams}
        />
        <CapacityTable
          title={'Установленная мощность кранов однобалочных по ГОСТ 22045-89, кВт '}
          labels={capacityParams}
          data={standardCraneCapacityParams}
        />
      </div>
    </div>
  );
};

export default GostParamsSection;
