import styles from './DropDownBlock.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import UpIcon from '../../icons/UpIcon/UpIcon';
import DownIcon from '../../icons/DownIcon/DownIcon';
import { hasChangesSet } from '../../../store/slices/qlistParams/qlistParamsSlice';

const DropDownBlock = ({ list, defaultValue, onChange, placeholder }) => {
  const [value, setValue] = useState('');
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClickOutside = () => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleOptionClick = (data) => {
    setIsOptionsVisible(false);
    setValue(data);
    dispatch(onChange(data));
    dispatch(hasChangesSet(true));
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.dropdown}
        role="presentation"
        onClick={() => setIsOptionsVisible(!isOptionsVisible)}
      >
        {value ? (
          <div className={styles.value}>{value}</div>
        ) : (
          <div className={styles.placeholder}>{placeholder || 'Параметр не задан'}</div>
        )}
        {isOptionsVisible ? <UpIcon/> : <DownIcon />}
      </div>
      {isOptionsVisible && (
        <div className={styles.popup} ref={ref}>
          {list.map(item => (
            <div
              className={styles.item}
              key={item}
              role="presentation"
              onClick={() => handleOptionClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownBlock;
