import styles from './QuestionsListsDesktop.module.scss';
import DotsIcon from '../../../common/icons/DotsIcon/DotsIcon';
import ControlModal from '../components/ControlModal/ControlModal';
import {useSelector} from 'react-redux';

const QuestionListsDesktop = ({
  dataSource,
  firstContentIndex,
  lastContentIndex,
  isShowControl,
  onControlClick,
  activeRowId,
  onShowQlist,
  onLoadQlist,
  onDeleteQlist,
  setIsShowControl,
  onCalculateClickHandler
}) => {
  const { current } = useSelector(state => state.qlistParams);

  return (
    <>
      {dataSource.length > 0 && dataSource.slice(firstContentIndex, lastContentIndex).map((item) => (
        <div className={(current.id === item.id && !current.hasChanges) ? `${styles.row} ${styles.current}`: styles.row} key={item.id}>
          <div className={`${styles.items} ${styles.id}`}>{item.id}</div>
          <div className={styles.items}>{new Date(item.createdAt).toLocaleString('ru-Ru')}</div>
          <div className={styles.items}>{item.craneType}</div>
          <div className={styles.items}>{item.spanLength}</div>
          <div className={styles.items}>{item.liftingHeight}</div>
          <div className={styles.items}>{item.workingModeByIso}</div>
          <div className={styles.items}>{item.customer}</div>
          <div className={styles.control}>
            <div
              role="presentation"
              onClick={() => onControlClick(item.id)}
            >
              <DotsIcon color={(current.id === item.id && !current.hasChanges) ? 'white' : '#ACB1CD'} />
            </div>
            {isShowControl && activeRowId === item.id && (
              <ControlModal
                id={item.id}
                onShow={onShowQlist}
                onLoad={onLoadQlist}
                onDelete={onDeleteQlist}
                onClose={() => setIsShowControl(false)}
                onCalculateClickHandler={onCalculateClickHandler}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default QuestionListsDesktop;