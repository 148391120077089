import $api from '../http/api';
import { isAuthSet, userSet } from '../store/slices/profile/profileSlice';
import { notifySet } from '../store/slices/modals/modalSlice';
import { store } from '../store/store';

export const updateProfile = async (email, password, name, phone, company, premiumParams) => {
  const updatedProfileData = {};

  if (email) {
    updatedProfileData.email = email;
  }

  if (password) {
    updatedProfileData.password = password;
  }

  if (name) {
    updatedProfileData.name = name;
  }

  if (phone) {
    updatedProfileData.phone = phone;
  }

  if (company) {
    updatedProfileData.company = company;
  }

  if (company) {
    updatedProfileData.company = company;
  }

  try {
    const response = await $api.post(
      '/user/update',
      updatedProfileData,
      { withCredentials: true }
    );

    const user = { ...response.data.user};

    if (premiumParams?.isActive) {
      const updatedPremParams = {};

      if (premiumParams.link) {
        updatedPremParams.link = premiumParams.link;
      }

      if (premiumParams.description) {
        updatedPremParams.description = premiumParams.description;
      }

      if (premiumParams.logo) {
        updatedPremParams.logo = premiumParams.logo;
      }

      const premResponse = await $api.post(
        '/user/premium/update',
        updatedPremParams,
        { withCredentials: true }
      );

      user.premiumParams = {
        isActive: premResponse.data.isActive,
      };

      if (premResponse.data.link) {
        user.premiumParams.link = premResponse.data.link;
      }

      if (premResponse.data.description) {
        user.premiumParams.description = premResponse.data.description;
      }

      if (premResponse.data.logo) {
        user.premiumParams.logo = premResponse.data.logo;
      }
    }

    store.dispatch(userSet({ ...user, isActivated: true }));
    store.dispatch(notifySet({
      type: 'success',
      message: 'Профиль успешно обновлен!',
      isShow: true
    }));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Непредвиденная ошибка при обновлении профиля',
      isShow: true
    }));
  }
};

export const activateUser = async (token) => {
  try {
    const response = await $api.post('/auth/activate', { token });

    if (response.data.user) {
      localStorage.setItem('token', response.data.accessToken);
      store.dispatch(isAuthSet(true));
      store.dispatch(userSet(response.data.user));
      store.dispatch(notifySet({
        type: 'success',
        message: `Успешно!
        Учетная запись успешно активирована`,
        isShow: true
      }));
      window.location.replace('/');
    }
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message,
      isShow: true
    }));
    return e.response?.data?.message;
  }
};

export const passwordReset = async (email) => {
  try {
    await $api.post('/auth/reset', { email });
    localStorage.removeItem('token');
    store.dispatch(notifySet({
      type: 'success',
      message: 'Ссылка для сброса пароля успешно отправлена на указанный email',
      isShow: true
    }));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message,
      isShow: true
    }));
    return e.response?.data?.message;
  }
};

export const confirmPasswordReset = async (token, password) => {
  try {
    const response = await $api.post('/auth/reset/confirm', { token, password });
    window.location.replace('/');
    localStorage.setItem('token', response.data.accessToken);
    store.dispatch(isAuthSet(true));
    store.dispatch(userSet(response.data.user));
    store.dispatch(notifySet({
      type: 'success',
      message: 'Пароль успешно изменен!',
      isShow: true
    }));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message,
      isShow: true
    }));
    return e.response?.data?.message;
  }
};