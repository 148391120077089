import { StyleSheet, View, Font } from '@react-pdf/renderer';
import font from '../../../../fonts/Manrope/Manrope-Regular.ttf';
import logoProCranes from './img/logoProCranes.png';
import List from './List';
import Types from './Types';
import Description from './Description';

Font.register({ family: 'Manrope', fonts: [
  { src: font }, // font-style: normal, font-weight: normal
]});

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
    border: '1px solid grey',
  },
  row: {
    flexDirection: 'row',
  }

});

const Header = ({ craneType, implementation, premiumParams, isPremiumUser }) => {
  return (
    <View style={styles.column}>
      <View style={styles.row}>
        <List />
        <Description
          logo={(isPremiumUser && premiumParams.logo) ? premiumParams.logo : logoProCranes}
          label={isPremiumUser && premiumParams.company ? premiumParams.company : 'ProCranes'}
          link={isPremiumUser && premiumParams.link ? premiumParams.link : 'https://procranes.ru'}
          text={isPremiumUser && premiumParams.description ? premiumParams.description : 'Опросные листы для грузоподьемных кранов'}
        />

      </View>
      <View style={styles.row}>
        <Types
          craneType={craneType}
          implementation={implementation}
        />
      </View>
    </View>
  );
};

export default Header;
