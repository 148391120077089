const StarIcon = ({ isFill }) => {
  const fill = isFill ? '#F57C4A' : 'none';

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5089 11.221C21.2922 10.6514 20.8893 9.41223 19.9208 9.41223H16.1161C15.2433 9.41223
        14.4713 8.84622 14.2087 8.01382L12.9502 4.02367C12.6562 3.0914 11.3369 3.0914 11.0428 4.02367L9.78431
        8.01382C9.52177 8.84622 8.74975 9.41223 7.87693 9.41223H4.07223C3.1037 9.41223 2.70079 10.6514 3.48414
        11.221L6.8727 13.6849C7.60501 14.2173 7.88824 15.1754 7.56321 16.0204L6.0932 19.8423C5.73556 20.7722
        6.80903 21.596 7.61472 21.01L10.8202 18.6788C11.5215 18.1687 12.4716 18.1687 13.1729 18.6788L16.3783
        21.01C17.184 21.596 18.2575 20.7722 17.8998 19.8423L16.4298 16.0204C16.1048 15.1754 16.388 14.2173
        17.1203 13.6849L20.5089 11.221Z" stroke="#F57C4A" strokeWidth="1.25" strokeLinecap="round"/>
    </svg>
  );
};

export default StarIcon;
