const ViewIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2944 10.9499L14.1696 13.8126M7.79427 4.60425C9.35905 4.60425 10.6276 5.87277 10.6276
       7.43758M12.7526 7.43758C12.7526 4.69917 10.5327 2.47925 7.79427 2.47925C5.05586 2.47925 2.83594 4.69917
       2.83594 7.43758C2.83594 10.176 5.05586 12.3959 7.79427 12.3959C10.5327 12.3959 12.7526 10.176 12.7526
       7.43758Z" stroke="#3D487C" strokeLinecap="round"/>
    </svg>
  );
};

export default ViewIcon;
