const ErrorIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.24219" y="4.24512" width="43.5102" height="43.5102" rx="7" fill="#FFC9D3"/>
      <path d="M24.3157 26.8479L22.0885 18.5615C21.4563 16.2087 23.118 13.8667 25.4199 13.8667C27.7215 13.8667 29.3835
       16.2087 28.751 18.5615L26.5238 26.8479C26.2067 28.0285 24.6331 28.0285 24.3157 26.8479Z" stroke="#FD4264"
      strokeWidth="1.25" strokeLinecap="round"/>
      <path d="M27.7316 35.8223C27.7316 37.0988 26.6969 38.1335 25.4205 38.1335C24.1441 38.1335 23.1094 37.0988 23.1094
       35.8223C23.1094 34.5459 24.1441 33.5112 25.4205 33.5112C26.6969 33.5112 27.7316 34.5459 27.7316 35.8223Z"
      stroke="#FD4264" strokeWidth="1.25" strokeLinecap="round"/>
    </svg>
  );
};

export default ErrorIcon;