import $api from '../http/api';
import { store } from '../store/store';
import { notifySet } from '../store/slices/modals/modalSlice';
import { companyListSet, contactListSet } from '../store/slices/params/customerParamsSlice';

export const companyFind = async (searchParams, signal) => {
  try {
    const response = await $api.post(
      '/company/find',
      { ...searchParams },
      { withCredentials: true, signal }
    );
    response?.data && store.dispatch(companyListSet([...response.data]));
  } catch (e) {
    console.log(e);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении списка компаний',
      isShow: true
    }));
  }
};

export const contactFind = async (searchParams, signal) => {
  try {
    const response = await $api.post(
      '/contact/find',
      { ...searchParams },
      { withCredentials: true, signal }
    );
    response?.data && store.dispatch(contactListSet([...response.data]));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении списка контактов',
      isShow: true
    }));
  }
};

