import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  company: '',
  email: '',
  phone: '',
  newPassword: '',
  confirmPassword: '',
  isAuth: false,
  isActivated: true,
  roles: [],
  premiumParams: {
    link: '',
    logo: '',
    description: ''
  }
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    nameSet:  (state, action) => {
      state.name = action.payload;
    },
    companySet:  (state, action) => {
      state.company = action.payload;
    },
    emailSet:  (state, action) => {
      state.email = action.payload;
    },
    phoneSet:  (state, action) => {
      state.phone = action.payload;
    },
    newPasswordSet:  (state, action) => {
      state.newPassword = action.payload;
    },
    confirmPasswordSet:  (state, action) => {
      state.confirmPassword = action.payload;
    },
    isAuthSet:  (state, action) => {
      state.isAuth = action.payload;
    },
    premLinkSet:  (state, action) => {
      state.premiumParams.link = action.payload;
    },
    premLogoSet:  (state, action) => {
      state.premiumParams.logo = action.payload;
    },
    premDescriptionSet:  (state, action) => {
      state.premiumParams.description = action.payload;
    },
    userSet:  (state, action) => {
      state.name = action.payload.name || '';
      state.company = action.payload.company || '';
      state.email = action.payload.email || '';
      state.phone = action.payload.phone || '';
      state.roles = action.payload.role || ['user'];
      state.premiumParams = action.payload.premiumParams || {};
      state.isActivated = action.payload.isActivated;
    }
  }
});

export const {
  nameSet,
  companySet,
  emailSet,
  phoneSet,
  newPasswordSet,
  confirmPasswordSet,
  isAuthSet,
  userSet,
  premLinkSet,
  premLogoSet,
  premDescriptionSet
} = profileSlice.actions;

export default profileSlice.reducer;
