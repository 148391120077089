const QlistsIcon = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6C6 4.89543 6.89543 4 8 4H11.5225C12.3306 4 13.0592 4.48627 13.3693 5.23247L13.4878 5.51753C13.7979
       6.26373 14.5266 6.75 15.3347 6.75H20C21.1046 6.75 22 7.64543 22 8.75V16C22 17.1046 21.1046 18 20 18H8C6.89543
        18 6 17.1046 6 16V6Z" stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M6 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H17C18.1046 21 19 20.1046 19
       19V18" stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
};

export default QlistsIcon;