import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  floorToTracksHeight: {
    label: 'Высота от пола до головки подкрановых путей/двутавра, мм',
    value: 'не задано',
    customLabel: 'Высота от пола до головки по данным Заказчика',
    customValue: ''
  },

  heightFromRailHead: {
    label: 'Высота крана от головки рельса, мм (h)',
    value: 'не задано',
    customLabel: 'Высота крана от головки рельса по данным Заказчика, мм (h)',
    customValue: '600'
  },

  railHeadToHookHeight: {
    label: 'Расстояние от головки рельса/двутавра до нижнего положения крюка крана (h1)',
    value: 'не задано',
    customLabel: 'Расстояние по данным Заказчика',
    customValue: '1440'
  },

  craneBase: {
    label: 'База крана, мм (B)',
    value: 'не задано',
    customLabel: 'База крана по данным Заказчика',
    customValue: '700'
  },

  endBeamLength: {
    label: 'Длина концевой балки, мм (A)',
    value: 'не задано',
    customLabel: 'Длина концевой балки по данным Заказчика',
    customValue: '1710'
  },

  heightFromRailToBuffer: {
    label: 'Высота от путей до центра буфера, мм (C1)',
    value: 'не задано',
    customLabel: 'Высота от путей до центра буфера по данным Заказчика',
    customValue: '215'
  }
};

export const craneInstallationSizesSlice= createSlice({
  name: 'craneInstallationSizes',
  initialState,
  reducers:{
    floorToTracksHeightSet: (state, action) => {
      state.floorToTracksHeight.value = action.payload;
    },
    floorToTracksHeightCustomSet: (state, action) => {
      state.floorToTracksHeight.customValue = action.payload;
    },
    heightFromRailHeadSet: (state, action) => {
      state.heightFromRailHead.value = action.payload;
    },
    heightFromRailHeadCustomSet: (state, action) => {
      state.heightFromRailHead.customValue = action.payload;
    },
    railHeadToHookHeightSet: (state, action) => {
      state.railHeadToHookHeight.value = action.payload;
    },
    railHeadToHookHeightCustomSet: (state, action) => {
      state.railHeadToHookHeight.customValue = action.payload;
    },
    craneBaseSet: (state, action) => {
      state.craneBase.value = action.payload;
    },
    craneBaseCustomSet: (state, action) => {
      state.craneBase.customValue = action.payload;
    },
    endBeamLengthSet: (state, action) => {
      state.endBeamLength.value = action.payload;
    },
    endBeamLengthCustomSet: (state, action) => {
      state.endBeamLength.customValue = action.payload;
    },
    heightFromRailToBufferSet: (state, action) => {
      state.heightFromRailToBuffer.value = action.payload;
    },
    heightFromRailToBufferCustomSet: (state, action) => {
      state.heightFromRailToBuffer.customValue = action.payload;
    },
    craneInstallationSizesSet: (state, action) => {
      state.floorToTracksHeight.value = action.payload.floorToTracksHeight.value;
      state.floorToTracksHeight.customValue = action.payload.floorToTracksHeight.customValue;

      state.heightFromRailHead.value = action.payload.heightFromRailHead.value;
      state.heightFromRailHead.customValue = action.payload.heightFromRailHead.customValue;

      state.railHeadToHookHeight.value = action.payload.railHeadToHookHeight.value;
      state.railHeadToHookHeight.customValue = action.payload.railHeadToHookHeight.customValue;

      state.craneBase.value = action.payload.craneBase.value;
      state.craneBase.customValue = action.payload.craneBase.customValue;

      state.endBeamLength.value = action.payload.endBeamLength.value;
      state.endBeamLength.customValue = action.payload.endBeamLength.customValue;

      state.endBeamLength.value = action.payload.endBeamLength.value;
      state.endBeamLength.customValue = action.payload.endBeamLength.customValue;


      state.heightFromRailToBuffer.value = action.payload.heightFromRailToBuffer.value;
      state.heightFromRailToBuffer.customValue = action.payload.heightFromRailToBuffer.customValue;
    }
  }
});

export const {
  floorToTracksHeightSet,
  floorToTracksHeightCustomSet,
  heightFromRailHeadSet,
  heightFromRailHeadCustomSet,
  railHeadToHookHeightSet,
  railHeadToHookHeightCustomSet,
  craneBaseSet,
  craneBaseCustomSet,
  endBeamLengthSet,
  endBeamLengthCustomSet,
  heightFromRailToBufferSet,
  heightFromRailToBufferCustomSet,
  craneInstallationSizesSet
} = craneInstallationSizesSlice.actions;

export default craneInstallationSizesSlice.reducer;
