import styles from './Filters.module.scss';
import DatesBlock from '../DatesBlock/DatesBlock';
import DropDownBlock from '../../../../common/components/DropDownBlock/DropDownBlock';
import {craneTypeSet, customerSet, spanLengthSet} from '../../../../store/slices/filters/filter';
import ResetIcon from '../../../../common/icons/ResetIcon/ResetIcon';

const Filters = ({dropdownValues, filters, onResetClickHandler, width, setIsPopupShow}) => {
  return(
    <div className={styles.container}>
      <DatesBlock />
      <DropDownBlock
        list={dropdownValues.craneTypes}
        defaultValue={filters.craneType !== 'Не задано'
          ? filters.craneType : null}
        placeholder="Тип крана"
        onChange={craneTypeSet}
      />
      <DropDownBlock
        list={dropdownValues.spanLengths}
        defaultValue={filters.spanLength !== 'Не задано'
          ? filters.spanLength : null}
        placeholder="Длина пролета"
        onChange={spanLengthSet}
      />
      <DropDownBlock
        list={dropdownValues.customers}
        defaultValue={filters.customer !== 'Не задано'
          ? filters.customer : null}
        placeholder="Заказчик"
        onChange={customerSet}
      />
      {width > 768 && (
        <div
          className={styles.reset}
          onClick={onResetClickHandler}
        >
          <ResetIcon/>
        </div>
      )}
      {width <= 768 && (
        <div className={styles.buttons}>
          <div
            className={styles.button}
            onClick={onResetClickHandler}
          >
            Сбросить
          </div>
          <div
            className={styles.button}
            onClick={() => setIsPopupShow(false)}
          >
            Закрыть
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;