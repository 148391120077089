import $api from '../http/api';
import { store } from '../store/store';
import { notifySet } from '../store/slices/modals/modalSlice';
import { feedbackListSet } from '../store/slices/feedback/feedbackSlice';

export const feedbackArrayGet = async (email) => {
  try {
    const response = await $api.post(
      '/feedback/user',
      { email },
      { withCredentials: true }
    );

    const feedbackList = response.data.map(item => {
      return {
        id: item.id,
        name: store.getState().profile.name,
        title: item.title,
        date: item.createdAt,
        hasReadByUser: item.hasReadByUser,
        content: JSON.parse(item.content)
      };
    });

    store.dispatch(feedbackListSet(feedbackList));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении списка сообщений',
      isShow: true
    }));
  }
};

export const feedbackCreate = async (email, name, title, message) => {
  try {
    const response = await $api.post(
      '/feedback/create',
      { email, name, title, message },
      { withCredentials: true }
    );
    const currentFeedbackList = store.getState().feedback.feedbackList;
    const updatedQFeedbackList = [response.data, ...currentFeedbackList];
    store.dispatch(feedbackListSet(updatedQFeedbackList));
    store.dispatch(notifySet({
      type: 'success',
      message: 'Сообщение успешно отправлено',
      isShow: true
    }));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || e || 'Ошибка при отправке сообщения',
      isShow: true
    }));
  }
};

export const feedbackUpdate = async (paramsToUpdate) => {
  const updatedFeedbackData = {
    email: paramsToUpdate.email,
    id: paramsToUpdate.id,
    name: paramsToUpdate.name
  };

  if (paramsToUpdate.hasReadByUser === false) {
    updatedFeedbackData.hasSupportAnswer = paramsToUpdate.hasReadByUser;
  }

  if (paramsToUpdate.message) {
    updatedFeedbackData.message = paramsToUpdate.message;
  }

  try {
    const response = await $api.post(
      '/feedback/update',
      updatedFeedbackData,
      { withCredentials: true }
    );

    const currentFeedbackList = store.getState().feedback.feedbackList;
    const updatedQFeedbackList = currentFeedbackList.map((item => {
      if (item.id === paramsToUpdate.id) {
        return response.data;
      }

      return item;
    }));
    store.dispatch(feedbackListSet(updatedQFeedbackList));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении списка сообщений',
      isShow: true
    }));
  }
};

export const readFeedbackByUser = async (email, id) => {
  try {
    await $api.post(
      '/feedback/read',
      {email, id},
      { withCredentials: true }
    );
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении списка сообщений',
      isShow: true
    }));
  }
};
