import { useState } from 'react';
import SendIcon from '../../icons/SendIcon/SendIcon';
import styles from './SendBlock.module.scss';

const SendBlock = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');

  const onSendClickHandler = (value) => {
    onSendMessage(value);
    setMessage('');
  };

  const onKeyDownHandler = (key) => {
    if (key === 'Enter') {
      onSendMessage(message);
      setMessage('');
    }
  };
  
  return (
    <div className={styles.container}>
      <input
        className={styles.text}
        value={message}
        placeholder="Напишите сообщение..."
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => onKeyDownHandler(e.key)}
      />
      <div
        className={styles.send}
        onClick={() => onSendClickHandler(message)}
      >
        <SendIcon />
      </div>
    </div>
  );
};

export default SendBlock;