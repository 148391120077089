import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  controlType: {
    label: 'Тип управления краном',
    value: 'с пола пультом',
    customLabel: 'Тип управления по данным Заказчика',
    customValue: ''
  },

  speedFrequencyControl: {
    label: 'Наличие частотного привода на передвижение',
    value: 'да (стандарт)'
  },

  protectionLevel: {
    label: 'Степень защиты электрооборудования',
    value: 'не задано',
    customLabel: 'Степень защиты по данным Заказчика',
    customValue: ''
  },

  controlCircuitsVoltage: {
    label: 'Напряжение цепей управления, В',
    value: '24 (стандарт)',
    customLabel: 'Напряжение цепей управления по данным Заказчика',
    customValue: ''
  }
};

export const electroEquipSlice = createSlice({
  name: 'electroEquip',
  initialState,
  reducers: {
    controlTypeSet:  (state, action) => {
      state.controlType.value = action.payload;
    },
    controlTypeCustomSet:  (state, action) => {
      state.controlType.customValue = action.payload;
    },
    protectionLevelSet:  (state, action) => {
      state.protectionLevel.value = action.payload;
    },
    protectionLevelCustomSet:  (state, action) => {
      state.protectionLevel.customValue = action.payload;
    },
    controlCircuitsVoltageSet:  (state, action) => {
      state.controlCircuitsVoltage.value = action.payload;
    },
    controlCircuitsVoltageCustomSet:  (state, action) => {
      state.controlCircuitsVoltage.customValue = action.payload;
    },
    electroEquipSet:  (state, action) => {
      state.controlType.value = action.payload.controlType.value;
      state.controlType.customValue = action.payload.controlType.customValue;

      state.protectionLevel.value = action.payload.protectionLevel.value;
      state.protectionLevel.customValue = action.payload.protectionLevel.customValue;

      state.controlCircuitsVoltage.value = action.payload.controlCircuitsVoltage.value;
      state.controlCircuitsVoltage.customValue = action.payload.controlCircuitsVoltage.customValue;
    }
  }
});

export const {
  controlTypeSet,
  controlTypeCustomSet,
  protectionLevelSet,
  protectionLevelCustomSet,
  controlCircuitsVoltageSet,
  controlCircuitsVoltageCustomSet,
  electroEquipSet
} = electroEquipSlice.actions;

export default electroEquipSlice.reducer;
