import $api from '../http/api';
import { store } from '../store/store';
import { notifySet } from '../store/slices/modals/modalSlice';
import {hasChangesSet, idSet, qlistArraySet} from '../store/slices/qlistParams/qlistParamsSlice';
import { additionalParamsSet } from '../store/slices/params/additionalParamsSlice';
import { craneInstallationSizesSet } from '../store/slices/params/craneInstallationSizesSlice';
import { electroEquipSet } from '../store/slices/params/electroEquipSlice';
import { hoistParamsSet } from '../store/slices/params/hoistParamsSlice';
import { metalConstructionSet } from '../store/slices/params/metalConstructionSlice';
import { powerWireSet } from '../store/slices/params/powerWireSlice';
import { companyParamsSet, contactParamsSet } from '../store/slices/params/customerParamsSlice';

export const qlistArrayGet = async (email) => {
  try {
    const response = await $api.post(
      '/qlist/user',
      { email },
      { withCredentials: true }
    );
    store.dispatch(qlistArraySet(response.data));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении списка опросных листов',
      isShow: true
    }));
  }
};

export const qlistCreate = async (requestParams) => {
  try {
    const response = await $api.post(
      '/qlist/create',
      requestParams,
      { withCredentials: true }
    );
    const currentQlistArray = store.getState().qlistParams.qlistArray;
    const updatedQListArray = [response.data, ...currentQlistArray];
    store.dispatch(qlistArraySet(updatedQListArray));
    store.dispatch(notifySet({
      type: 'success',
      message: 'Опросный лист успешно сохранен',
      isShow: true
    }));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || e || 'Ошибка при сохранении параметров опросного листа',
      isShow: true
    }));
  }
};

export const qlistDelete = async (id) => {
  try {
    const response = await $api.post(
      '/qlist/delete',
      { id },
      { withCredentials: true }
    );

    if (response) {
      const currentQlistArray = store.getState().qlistParams.qlistArray;
      const updatedQListArray = currentQlistArray.filter((item => item.id !== id));
      store.dispatch(qlistArraySet(updatedQListArray));
      store.dispatch(notifySet({
        type: 'success',
        message: 'Опросный лист успешно удален',
        isShow: true
      }));
    }
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || e || 'Ошибка при удалении опросного листа',
      isShow: true
    }));
  }
};

export const qlistLoad = async (id) => {
  try {
    const response = await $api.post(
      '/qlist/load',
      { id },
      { withCredentials: true }
    );

    const craneParams = JSON.parse(response.data.craneParams);

    store.dispatch(idSet(id));
    store.dispatch(hasChangesSet(false));
    store.dispatch(metalConstructionSet(craneParams.metalConstruction));
    store.dispatch(metalConstructionSet(craneParams.metalConstruction));
    store.dispatch(hoistParamsSet(craneParams.hoistParams));
    store.dispatch(electroEquipSet(craneParams.electroEquip));
    store.dispatch(powerWireSet(craneParams.powerWire));
    store.dispatch(craneInstallationSizesSet(craneParams.craneInstallationSizes));
    store.dispatch(additionalParamsSet(craneParams.additionalParams));
    store.dispatch(companyParamsSet(response.data.customerParams.company));
    store.dispatch(contactParamsSet(response.data.customerParams.contact));
    store.dispatch(notifySet({
      type: 'success',
      message: 'Параметры опросного листа успешно загружены',
      isShow: true
    }));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || e || 'Ошибка при запросе параметров опросного листа',
      isShow: true
    }));
  }
};

