import ButtonBlock from '../../../../../../common/components/ButtonBlock/ButtonBlock';
import {
  controlCircuitsVoltageItems,
  controlTypeItems,
  protectionLevelItems,
  speedFrequencyControlItems,
} from '../../../../../../common/consts/electroEquip';
import {
  controlCircuitsVoltageCustomSet,
  controlCircuitsVoltageSet,
  controlTypeCustomSet,
  controlTypeSet,
  protectionLevelCustomSet,
  protectionLevelSet
} from '../../../../../../store/slices/params/electroEquipSlice';
import { useSelector } from 'react-redux';
import ParameterBlock from '../../../../../../common/components/ParameterBlock/ParameterBlock';
import styles from '../../Content.module.scss';
import StringBlock from '../../../../../../common/components/StringBlock/StringBlock';

const ElectroEquip = () => {
  const {
    controlType,
    speedFrequencyControl,
    protectionLevel,
    controlCircuitsVoltage,
  } = useSelector(state => state.electroEquip);

  return (
    <>
      <ParameterBlock
        title={controlType.label}
        isValid={(!!controlType.value && controlType.value !== 'другое') || !!controlType.customValue}
      >
        <ButtonBlock
          buttons={controlTypeItems}
          onChange={controlTypeSet}
          defaultValue={controlType.value}
        />
        {controlType.value === 'другое' && (
          <>
            <div className={styles.title}>{controlType.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={controlTypeCustomSet}
                defaultValue={controlType.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={speedFrequencyControl.label}
        isValid={!!speedFrequencyControl.value}
      >
        <ButtonBlock
          buttons={speedFrequencyControlItems}
          onChange={protectionLevelSet}
          defaultValue={speedFrequencyControl.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={protectionLevel.label}
        isValid={(!!protectionLevel.value && protectionLevel.value !== 'другое') || !!protectionLevel.customValue}
      >
        <ButtonBlock
          buttons={protectionLevelItems}
          onChange={protectionLevelSet}
          defaultValue={protectionLevel.value}
        />
        {protectionLevel.value === 'другое' && (
          <>
            <div className={styles.title}>{protectionLevel.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={protectionLevelCustomSet}
                defaultValue={protectionLevel.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
      <ParameterBlock
        title={controlCircuitsVoltage.label}
        isValid={(!!controlCircuitsVoltage.value && controlCircuitsVoltage.value !== 'другое') || controlCircuitsVoltage.customValue}
      >
        <ButtonBlock
          buttons={controlCircuitsVoltageItems}
          onChange={controlCircuitsVoltageSet}
          defaultValue={controlCircuitsVoltage.value}
        />
        {controlCircuitsVoltage.value === 'другое' && (
          <>
            <div className={styles.title}>{controlCircuitsVoltage.customLabel}</div>
            <div className={styles.string}>
              <StringBlock
                placeholder="введите значение"
                onChange={controlCircuitsVoltageCustomSet}
                defaultValue={controlCircuitsVoltage.customValue}
              />
            </div>
          </>
        )}
      </ParameterBlock>
    </>
  );
};

export default ElectroEquip;
