export const controlTypeItems = [
  'с пола пультом',
  'из кабины',
  'радиоуправление',
  'дублированное (с пола и радио)',
  'другое',
];

export const speedFrequencyControlItems = [
  'да (стандарт)',
  'не требуется'
];

export const protectionLevelItems = [
  'не задано',
  'IP 54 (стандарт)',
  'IP 65',
  'другое'
];

export const controlCircuitsVoltageItems = [
  '24 (стандарт)',
  '36',
  '42',
  'другое'
];

