const DotsIcon = ({ color }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 2V4M8.5 14V12M8.5 7V9" stroke={color}
        strokeWidth="2"/>
    </svg>
  );
};

export default DotsIcon;