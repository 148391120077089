import { store } from '../store/store';
import $api from '../http/api';
import {newsListSet} from '../store/slices/news/newsSlice';
import {notifySet} from '../store/slices/modals/modalSlice';

export const newsListGet = async () => {
  try {
    const response = await $api.get(
      '/news/list',
      { withCredentials: true }
    );

    const newsList = response.data.map(item => {
      return {
        id: item.id,
        title: item.title,
        date: item.date,
        content: item.content
      };
    });

    store.dispatch(newsListSet(newsList));
  } catch (e) {
    console.log(e.response?.data?.message);
    store.dispatch(notifySet({
      type: 'error',
      message: e.response?.data?.message || 'Ошибка при получении списка новостей',
      isShow: true
    }));
  }
};