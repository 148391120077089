const DotsBottomIcon = () => {
  return (
    <svg width="11" height="4" viewBox="0 0 11 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.12003 3.10121C0.828835 3.10121 0.57848 2.99822 0.368963 2.79226C0.159446 2.58274
        0.0546875 2.33061 0.0546875 2.03587C0.0546875 1.74467 0.159446 1.49609 0.368963 1.29013C0.57848
        1.08061 0.828835 0.975852 1.12003 0.975852C1.41122 0.975852 1.66158 1.08061 1.87109 1.29013C2.08061
        1.49609 2.18537 1.74467 2.18537 2.03587C2.18537 2.23118 2.13565 2.41051 2.03622 2.57386C1.94034 2.73366
        1.8125 2.86151 1.6527 2.95739C1.4929 3.05327 1.31534 3.10121 1.12003 3.10121ZM5.36808 3.10121C5.07688
        3.10121 4.82653 2.99822 4.61701 2.79226C4.40749 2.58274 4.30273 2.33061 4.30273 2.03587C4.30273 1.74467
        4.40749 1.49609 4.61701 1.29013C4.82653 1.08061 5.07688 0.975852 5.36808 0.975852C5.65927 0.975852
        5.90962 1.08061 6.11914 1.29013C6.32866 1.49609 6.43342 1.74467 6.43342 2.03587C6.43342 2.23118
        6.3837 2.41051 6.28427 2.57386C6.18839 2.73366 6.06055 2.86151 5.90075 2.95739C5.74094 3.05327
        5.56339 3.10121 5.36808 3.10121ZM9.61612 3.10121C9.32493 3.10121 9.07457 2.99822 8.86506
        2.79226C8.65554 2.58274 8.55078 2.33061 8.55078 2.03587C8.55078 1.74467 8.65554 1.49609
        8.86506 1.29013C9.07457 1.08061 9.32493 0.975852 9.61612 0.975852C9.90732 0.975852 10.1577
        1.08061 10.3672 1.29013C10.5767 1.49609 10.6815 1.74467 10.6815 2.03587C10.6815 2.23118 10.6317
        2.41051 10.5323 2.57386C10.4364 2.73366 10.3086 2.86151 10.1488 2.95739C9.98899 3.05327 9.81143 3.10121
        9.61612 3.10121Z" fill="#3D487C"/>
    </svg>
  );
};

export default DotsBottomIcon;