const SettingsIcon = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0116 21H11.7436C10.8567 21 10.1378 20.2811 10.1378 19.3942C10.1378 18.8934 9.90407 18.4212 9.5058
      18.1174L8.46873 17.3265C7.88109 16.8783 7.08362 16.8213 6.43819 17.1813L6.40287 17.201C5.54579 17.679 4.46399
      17.3893 3.96053 16.5469C3.43531 15.6681 3.74035 14.529 4.63449 14.0303L4.68046 14.0047C5.27142 13.6751 5.63768
      13.0514 5.63768 12.3748V11.6257C5.63768 10.9491 5.27142 10.3254 4.68045 9.99579L4.63449 9.97015C3.74035 9.47146
      3.43531 8.33241 3.96053 7.4536C4.46399 6.61122 5.54579 6.3215 6.40287 6.79952L6.53874 6.8753C7.1369 7.20891
      7.86518 7.2089 8.46333 6.87527L9.18739 6.47141C9.77414 6.14414 10.1378 5.52492 10.1378 4.85307C10.1378 3.82965
      10.9777 3 12.0011 3C13.0245 3 13.8644 3.82964 13.8644 4.85305C13.8644 5.52488 14.228 6.14409 14.8148
      6.47136L15.5388 6.87522C16.1369 7.20885 16.8652 7.20887 17.4633 6.87526L17.5992 6.79952C18.4562 6.3215 19.538
      6.61122 20.0415 7.4536C20.5667 8.33241 20.2617 9.47146 19.3675 9.97015L19.3215 9.99579C18.7306 10.3254 18.3643
      10.9491 18.3643 11.6257V12.3748C18.3643 13.0514 18.7306 13.6751 19.3215 14.0047L19.3675 14.0304C20.2617 14.529
      20.5667 15.6681 20.0415 16.5469C19.538 17.3893 18.4562 17.679 17.5992 17.201L17.4633 17.1252C16.8652 16.7916
      16.137 16.7916 15.5388 17.1252L14.8148 17.529C14.2281 17.8562 13.8644 18.4754 13.8644 19.1472C13.8644 20.1705
      13.0349 21 12.0116 21Z" stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M15.0001 12C15.0001 13.6568 13.6569 15 12 15C10.3432 15 9 13.6568 9 12C9 10.3431 10.3432 9 12 9C13.6569
      9 15.0001 10.3431 15.0001 12Z" stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
};

export default SettingsIcon;