export const walkwayGalleryItem = [
  'не задано',
  'требуется'
];

export const walkwayGalleryConstructionItems = [
  'проходная галерея вдоль балки',
  'проходная галерея для обслуживания светильников'
];

export const additionalItemItems = [
  'не задано',
  'требуется'
];

