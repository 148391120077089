import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Layout from './layout/Layout';
import AuthMenu from './layout/AuthMenu/AuthMenu';
import PasswordResetMenu from './layout/PasswordResetMenu/PasswordResetMenu';
import { appNameSet } from './store/slices/appParams/appParamsSlice';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    fetch('config.json')
      .then(response => response.json())
      .then(data => {
        const { appName } = data;
        document.title = appName;
        store.dispatch(appNameSet(appName));
      })
      .catch(error => console.error('Ошибка загрузки JSON:', error));
  }, []);

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={ <Layout /> }/>
            <Route path="/activation/:token" element={ <AuthMenu /> } />
            <Route path="/reset/:token" element={ <PasswordResetMenu /> } />
          </Routes>
        </Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
