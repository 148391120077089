import styles from './AuthMenu.module.scss';
import TabMenu from '../../common/components/TabMenu/TabMenu';
import {useEffect, useRef, useState} from 'react';
import { login, registration } from '../../service/auth';
import { activateUser, passwordReset } from '../../service/user';
import { modalWindowSet, notifySet } from '../../store/slices/modals/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import ModalWindow from '../../common/components/ModalWindow/ModalWindow';
import { store } from '../../store/store';
import NotifyBlock from '../../common/components/NotifyBlock/NotifyBlock';
import LogoIcon from '../../common/icons/LogoIcon/LogoIcon';

const tabList = [
  {
    label: 'Вход',
    value: 'login',
  },
  {
    label: 'Регистрация',
    value: 'registration',
  },
];

const AuthMenu = () => {
  const [activeTab, setActiveTab] = useState('login');
  const [inputValue, setInputValue] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();
  const { notify, modalWindow } = useSelector(state => state.modals);
  const { appName } = useSelector(state => state.appParams);

  const containerRef = useRef();

  useEffect(() => {
    containerRef.current.focus();
  }, []);

  useEffect(() => {
    const url = window.location.href;

    const activationString = '/activation/';
    const activationIndex = url.indexOf(activationString);

    if (activationIndex !== -1) {
      const activationToken = url.substring(activationIndex + activationString.length);

      if (activationToken) {
        activateUser(activationToken).then();
      }
    }
  },[]);
  
  const onInputChangeHandler = (key, value) => {
    setInputValue({
      ...inputValue,
      [key]: value
    });
  };

  const errorMessage = (message) => {
    dispatch(notifySet({
      type: 'error',
      message: message,
      isShow: true
    }));
  };

  const onConfirmButtonClick = () => {
    if (!inputValue.email) {
      errorMessage('Не указан e-mail');

      return;
    }

    if (!inputValue.email.match(/^\S+@\S+\.\S+$/)) {
      errorMessage('Неверный формат e-mail');

      return;
    }

    if (!inputValue.password) {
      errorMessage('Не указан пароль');

      return;
    }

    if (inputValue.password.length < 5) {
      errorMessage('Длина пароля должна быть больше пяти символов');

      return;
    }

    if (activeTab === 'login') {
      login(inputValue.email, inputValue.password).then(r => r && errorMessage(r));
    }

    if (activeTab === 'registration') {
      if (!isChecked) {
        errorMessage('Для регистрации необходимо ознакомиться и принять условия Пользовательского соглашения');

        return;
      }

      if (!inputValue.confirmPassword) {
        errorMessage('Подтвердите пароль');

        return;
      }

      if (inputValue.password !== inputValue.confirmPassword) {
        errorMessage('Пароли не совпадают');

        return;
      }

      registration(inputValue.email, inputValue.password).then();
    }
  };

  const onForgotButtonClick = () => {
    if (!inputValue.email) {
      errorMessage('Не указан e-mail');

      return;
    }

    if (!inputValue.email.match(/^\S+@\S+\.\S+$/)) {
      errorMessage('Неверный формат e-mail');

      return;
    }

    store.dispatch(modalWindowSet({
      type: 'confirm',
      page: 'auth',
      title: 'Сброс пароля',
      message: 'Вы действительно хотите сбросить пароль? Если да, то мы отправим Вам ссылку-подтверждение на указанную электронную почту',
      isShow: true
    }));
  };

  const openTerms = () => {
    window.open('/docs/user_terms.pdf', '_blank');
  };

  const openPrivacy = () => {
    window.open('/docs/privacy.pdf', '_blank');
  };

  if (!appName) {
    return (<div ref={containerRef} className={styles.spinner}/>);
  }

  return (
    <div
      ref={containerRef}
      className={styles.container}
      onKeyDown={(event) => event.key === 'Enter' && onConfirmButtonClick()}
      tabIndex={0}
    >
      {notify.params.isShow && <NotifyBlock params={notify.params} />}
      {modalWindow.params.isShow &&
        <ModalWindow
          params={modalWindow.params}
          onConfirm={() => passwordReset(inputValue.email)}
        />
      }
      <div className={styles.leftSide}>
        <div className={styles.panel}>
          <div className={styles.logo}>
            {appName && <LogoIcon size={50}/>}
            {appName}
          </div>
          <TabMenu
            tabList={tabList}
            activeTab={activeTab}
            onClick={setActiveTab}
          />
          <input
            className={styles.input}
            type="text"
            placeholder="Введите e-mail"
            value={inputValue.email}
            onChange={(e) => onInputChangeHandler('email', e.target.value)}
          />
          <input
            className={styles.input}
            type="password"
            placeholder="Введите пароль"
            value={inputValue.password}
            onChange={(e) => onInputChangeHandler('password', e.target.value)}
          />
          {activeTab === 'registration' &&
            <input
              className={styles.input}
              type="password"
              placeholder="Подтвердите пароль"
              value={inputValue.confirmPassword}
              onChange={(e) => onInputChangeHandler('confirmPassword', e.target.value)}
            />
          }
          <div className={styles.wrapper}>
            <div
              className={styles.button}
              onClick={onConfirmButtonClick}
            >
              {activeTab === 'login' ? 'OK' : 'Регистрация'}
            </div>
            {activeTab === 'login' && (
              <div
                className={styles.forgot}
                onClick={onForgotButtonClick}
              >
                Забыли пароль?
              </div>
            )}
          </div>
          {activeTab === 'registration' && (
            <div className={styles.disclaimer}>
              <input
                className={styles.checkbox}
                type="checkbox"
                onClick={() => setIsChecked(!isChecked)}
                checked={isChecked}
                readOnly={true}
              />
              <div>Я ознакомлен и принимаю условия&nbsp;
                <span className={styles.terms} onClick={openTerms}>Пользовательского соглашения&nbsp;</span>
                и&nbsp;
                <span className={styles.terms} onClick={openPrivacy}>Политики в отношении обработки персональных данных</span>
              </div>
            </div>
          )}
          {activeTab === 'login' && (
            <div className={styles.beta}>
              <div >Приложение находится в стадии бета-тестирования</div>
            </div>
          )}
        </div>
      </div>
      <img
        className={styles.banner}
        src="/img/auth.jpg"
        alt="auth"
      />
    </div>
  );
};

export default AuthMenu;