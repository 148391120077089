const ProfileIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.617188" width="50.6639" height="53" rx="7" fill="#F57C4A"/>
      <path d="M26.4251 27.625C22.8443 27.625 19.8294 30.16 18.931 33.6081C18.6302 34.7626 19.5856
       35.75 20.7295 35.75H32.1208C33.2648 35.75 34.2202 34.7626 33.9193 33.6081C33.021 30.16 30.0061
        27.625 26.4251 27.625Z" stroke="white" strokeLinecap="round"/>
      <path d="M30.0498 20.5832C30.0498 22.6773 28.4271 24.3748 26.4253 24.3748C24.4235 24.3748 22.8008
       22.6773 22.8008 20.5832C22.8008 18.4891 24.4235 16.7915 26.4253 16.7915C28.4271 16.7915 30.0498
        18.4891 30.0498 20.5832Z" stroke="white" strokeLinecap="round"/>
    </svg>
  );
};

export default ProfileIcon;
