import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {
    id: null,
    craneType: '',
    spanLength: '',
    liftingHeight: '',
    workingModeByIso: '',
    customer: null,
    hasChanges: false
  },
  qlistArray: []
};

export const qlistParamsSlice = createSlice({
  name: 'qlistParams',
  initialState,
  reducers: {
    idSet: (state, action) => {
      state.current.id = action.payload;
    },
    customerSet: (state, action) => {
      state.current.customer = action.payload;
    },
    hasChangesSet: (state, action) => {
      state.current.hasChanges = action.payload;
    },
    qlistArraySet: (state, action) => {
      state.qlistArray = action.payload;
    }
  }
});

export const {
  idSet,
  customerSet,
  hasChangesSet,
  qlistArraySet,
} = qlistParamsSlice.actions;

export default qlistParamsSlice.reducer;
