import ButtonBlock from '../../../../../../common/components/ButtonBlock/ButtonBlock';
import {
  additionalRequirementsSet,
  brandColorSet,
  craneLimitSwitchSet,
  hoistLimitSwitchSet,
  hourCounterSet,
  liftingBrakeSet,
  loadCapacityLimiterSet,
  parametersLoggerSet,
  walkwayGallerySet,
  walkwayGalleryConstructionSet
} from '../../../../../../store/slices/params/additionalParamsSlice';
import { useSelector } from 'react-redux';
import ParameterBlock from '../../../../../../common/components/ParameterBlock/ParameterBlock';
import {
  additionalItemItems, walkwayGalleryConstructionItems,
  walkwayGalleryItem,
} from '../../../../../../common/consts/additionalParams';
import {
  additionalCallButtonSet,
  additionalLightButtonSet
} from '../../../../../../store/slices/params/additionalParamsSlice';
import TextBlock from '../../../../../../common/components/TextBlock/TextBlock';

const AdditionalParams = () => {
  const {
    walkwayGallery,
    walkwayGalleryConstruction,
    craneLimitSwitch,
    hoistLimitSwitch,
    additionalCallButton,
    additionalLightButton,
    liftingBrake,
    loadCapacityLimiter,
    parametersLogger,
    hourCounter,
    brandColor,
    additionalRequirements
  } = useSelector(state => state.additionalParams);

  return (
    <>
      <ParameterBlock
        title={walkwayGallery.label}
        isValid={!!walkwayGallery.value}
      >
        <ButtonBlock
          buttons={walkwayGalleryItem}
          onChange={walkwayGallerySet}
          defaultValue={walkwayGallery.value}
        />
      </ParameterBlock>
      {walkwayGallery.value === 'требуется' &&
        <ParameterBlock
          title={walkwayGalleryConstruction.label}
          isValid={!!walkwayGalleryConstruction.value}
        >
          <ButtonBlock
            buttons={walkwayGalleryConstructionItems}
            onChange={walkwayGalleryConstructionSet}
            defaultValue={walkwayGalleryConstruction.value}
          />
        </ParameterBlock>
      }
      <ParameterBlock
        title={craneLimitSwitch.label}
        isValid={!!craneLimitSwitch.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={craneLimitSwitchSet}
          defaultValue={craneLimitSwitch.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={hoistLimitSwitch.label}
        isValid={!!hoistLimitSwitch.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={hoistLimitSwitchSet}
          defaultValue={hoistLimitSwitch.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={additionalCallButton.label}
        isValid={!!additionalCallButton.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={additionalCallButtonSet}
          defaultValue={additionalCallButton.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={additionalLightButton.label}
        isValid={!!additionalLightButton.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={additionalLightButtonSet}
          defaultValue={additionalLightButton.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={liftingBrake.label}
        isValid={!!liftingBrake.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={liftingBrakeSet}
          defaultValue={liftingBrake.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={loadCapacityLimiter.label}
        isValid={!!loadCapacityLimiter.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={loadCapacityLimiterSet}
          defaultValue={loadCapacityLimiter.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={parametersLogger.label}
        isValid={!!parametersLogger.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={parametersLoggerSet}
          defaultValue={parametersLogger.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={hourCounter.label}
        isValid={!!hourCounter.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={hourCounterSet}
          defaultValue={hourCounter.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={brandColor.label}
        isValid={!!brandColor.value}
      >
        <ButtonBlock
          buttons={additionalItemItems}
          onChange={brandColorSet}
          defaultValue={brandColor.value}
        />
      </ParameterBlock>
      <ParameterBlock
        title={additionalRequirements.label}
      >
        <TextBlock
          onChange={additionalRequirementsSet}
          defaultValue={additionalRequirements.value}
        />
      </ParameterBlock>


    </>
  );
};

export default AdditionalParams;
