export const pylonOneSpanMassParams = {
  '0.5': {
    '6': {
      craneMass: 1.26,
      hoistMass: 0.13,
      wheelLoad: 8.48
    },
    '9': {
      craneMass: 1.41,
      hoistMass: 0.13,
      wheelLoad: 8.85
    },
    '12': {
      craneMass: 1.85,
      hoistMass: 0.13,
      wheelLoad: 9.95
    },
    '15': {
      craneMass: 2.27,
      hoistMass: 0.13,
      wheelLoad: 11.00
    },
    '16.5': {
      craneMass: 2.68,
      hoistMass: 0.13,
      wheelLoad: 12.03
    },
    '18': {
      craneMass: 4,
      hoistMass: 0.13,
      wheelLoad: 15.33
    },
    '22.5': {
      craneMass: 4.3,
      hoistMass: 0.13,
      wheelLoad: 16.08
    }
  },
  '1': {
    '6': {
      craneMass: 1.26,
      hoistMass: 0.172,
      wheelLoad: 13.58
    },
    '9': {
      craneMass: 1.41,
      hoistMass: 0.172,
      wheelLoad: 13.96
    },
    '12': {
      craneMass: 1.85,
      hoistMass: 0.172,
      wheelLoad: 15.06
    },
    '15': {
      craneMass: 2.27,
      hoistMass: 0.172,
      wheelLoad: 16.11
    },
    '16.5': {
      craneMass: 2.68,
      hoistMass: 0.172,
      wheelLoad: 17.13
    },
    '18': {
      craneMass: 4,
      hoistMass: 0.172,
      wheelLoad: 20.43
    },
    '22.5': {
      craneMass: 4.3,
      hoistMass: 0.172,
      wheelLoad: 21.18
    }
  },
  '2': {
    '6': {
      craneMass: 0.96,
      hoistMass: 0.285,
      wheelLoad: 23.11
    },
    '9': {
      craneMass: 1.23,
      hoistMass: 0.285,
      wheelLoad: 23.79
    },
    '12': {
      craneMass: 1.5,
      hoistMass: 0.285,
      wheelLoad: 24.46
    },
    '15': {
      craneMass: 1.86,
      hoistMass: 0.285,
      wheelLoad: 25.36
    },
    '16.5': {
      craneMass: 3.58,
      hoistMass: 0.285,
      wheelLoad: 29.66
    },
    '18': {
      craneMass: 4.4,
      hoistMass: 0.285,
      wheelLoad: 31.71
    },
    '22.5': {
      craneMass: 5,
      hoistMass: 0.285,
      wheelLoad: 33.21
    }
  },
  '3.2': {
    '6': {
      craneMass: 1.5,
      hoistMass: 0.354,
      wheelLoad: 36.64
    },
    '9': {
      craneMass: 2,
      hoistMass: 0.354,
      wheelLoad: 37.89
    },
    '12': {
      craneMass: 2.3,
      hoistMass: 0.354,
      wheelLoad: 37.89
    },
    '15': {
      craneMass: 2.8,
      hoistMass: 0.354,
      wheelLoad: 39.89
    },
    '16.5': {
      craneMass: 3.75,
      hoistMass: 0.354,
      wheelLoad: 42.26
    },
    '18': {
      craneMass: 5.2,
      hoistMass: 0.354,
      wheelLoad: 45.89
    },
    '22.5': {
      craneMass: 6.2,
      hoistMass: 0.354,
      wheelLoad: 48.39
    }
  },
  '5': {
    '6': {
      craneMass: 1.87,
      hoistMass: 0.597,
      wheelLoad: 56.17
    },
    '9': {
      craneMass: 2.7,
      hoistMass: 0.597,
      wheelLoad: 58.24
    },
    '12': {
      craneMass: 3.2,
      hoistMass: 0.597,
      wheelLoad: 59.49
    },
    '15': {
      craneMass: 3.4,
      hoistMass: 0.597,
      wheelLoad: 59.99
    },
    '16.5': {
      craneMass: 5,
      hoistMass: 0.597,
      wheelLoad: 63.99
    },
    '18': {
      craneMass: 6,
      hoistMass: 0.597,
      wheelLoad: 66.49
    },
    '22.5': {
      craneMass: 6.4,
      hoistMass: 0.597,
      wheelLoad: 67.49
    }
  },
  '10': {
    '6': {
      craneMass: 5,
      hoistMass: 0.67,
      wheelLoad: 114.18
    },
    '9': {
      craneMass: 5.7,
      hoistMass: 0.67,
      wheelLoad: 115.93
    },
    '12': {
      craneMass: 6.5,
      hoistMass: 0.67,
      wheelLoad: 117.93
    },
    '15': {
      craneMass: 9.5,
      hoistMass: 0.67,
      wheelLoad: 125.43
    },
    '16.5': {
      craneMass: 10.5,
      hoistMass: 0.67,
      wheelLoad: 127.93
    },
    '18': {
      craneMass: 11.4,
      hoistMass: 0.67,
      wheelLoad: 130.18
    },
    '22.5': {
      craneMass: 14.25,
      hoistMass: 0.67,
      wheelLoad: 137.30
    }
  },
  '12.5': {
    '6': {
      craneMass: 6.25,
      hoistMass: 1.08,
      wheelLoad: 143.33
    },
    '9': {
      craneMass: 7.2,
      hoistMass: 1.08,
      wheelLoad: 145.70
    },
    '12': {
      craneMass: 9.5,
      hoistMass: 1.08,
      wheelLoad: 151.45
    },
    '15': {
      craneMass: 11.8,
      hoistMass: 1.08,
      wheelLoad: 160.70
    },
    '16.5': {
      craneMass: 13.2,
      hoistMass: 1.08,
      wheelLoad: 160.70
    },
    '18': {
      craneMass: 14.2,
      hoistMass: 1.08,
      wheelLoad: 163.20
    },
    '22.5': {
      craneMass: 17.8,
      hoistMass: 1.08,
      wheelLoad: 172.20
    }
  },
  '16': {
    '6': {
      craneMass: 8,
      hoistMass: 1.08,
      wheelLoad: 182.70
    },
    '9': {
      craneMass: 9.2,
      hoistMass: 1.08,
      wheelLoad: 185.70
    },
    '12': {
      craneMass: 12,
      hoistMass: 1.08,
      wheelLoad: 192.70
    },
    '15': {
      craneMass: 15,
      hoistMass: 1.08,
      wheelLoad: 200.20
    },
    '16.5': {
      craneMass: 16.8,
      hoistMass: 1.08,
      wheelLoad: 204.70
    },
    '18': {
      craneMass: 18.2,
      hoistMass: 1.08,
      wheelLoad: 208.20
    },
    '22.5': {
      craneMass: 22.8,
      hoistMass: 1.08,
      wheelLoad: 219.7
    }
  }
};

export const overheadOneSpanMassParams = {
  '0.5': {
    '6': {
      craneMass: 0.8,
      hoistMass: 0.13,
      wheelLoad: 6.16
    },
    '9': {
      craneMass: 1,
      hoistMass: 0.13,
      wheelLoad: 6.41
    },
    '12': {
      craneMass: 1.2,
      hoistMass: 0.13,
      wheelLoad: 6.41
    },
    '15': {
      craneMass: 1.5,
      hoistMass: 0.13,
      wheelLoad: 7.04
    },
    '16.5': {
      craneMass: 1.8,
      hoistMass: 0.13,
      wheelLoad: 7.41
    },
    '18': {
      craneMass: 2,
      hoistMass: 0.13,
      wheelLoad: 7.66
    },
    '22.5': {
      craneMass: 2.5,
      hoistMass: 0.13,
      wheelLoad: 8.29
    }
  },
  '1': {
    '6': {
      craneMass: 0.8,
      hoistMass: 0.172,
      wheelLoad: 11.22
    },
    '9': {
      craneMass: 1,
      hoistMass: 0.172,
      wheelLoad: 11.47
    },
    '12': {
      craneMass: 1.2,
      hoistMass: 0.172,
      wheelLoad: 11.72
    },
    '15': {
      craneMass: 1.5,
      hoistMass: 0.172,
      wheelLoad: 12.09
    },
    '16.5': {
      craneMass: 1.8,
      hoistMass: 0.172,
      wheelLoad: 12.47
    },
    '18': {
      craneMass: 2,
      hoistMass: 0.172,
      wheelLoad: 12.72
    },
    '22.5': {
      craneMass: 2.5,
      hoistMass: 0.172,
      wheelLoad: 13.34
    }
  },
  '2': {
    '6': {
      craneMass: 0.96,
      hoistMass: 0.285,
      wheelLoad: 21.56
    },
    '9': {
      craneMass: 1.23,
      hoistMass: 0.285,
      wheelLoad: 21.89
    },
    '12': {
      craneMass: 1.5,
      hoistMass: 0.285,
      wheelLoad: 22.23
    },
    '15': {
      craneMass: 1.86,
      hoistMass: 0.285,
      wheelLoad: 22.68
    },
    '16.5': {
      craneMass: 3.58,
      hoistMass: 0.285,
      wheelLoad: 24.83
    },
    '18': {
      craneMass: 4.4,
      hoistMass: 0.285,
      wheelLoad: 25.86
    },
    '22.5': {
      craneMass: 5.58,
      hoistMass: 0.285,
      wheelLoad: 27.33
    }
  },
  '3.2': {
    '6': {
      craneMass: 1.3,
      hoistMass: 0.354,
      wheelLoad: 34.07
    },
    '9': {
      craneMass: 1.7,
      hoistMass: 0.354,
      wheelLoad: 34.57
    },
    '12': {
      craneMass: 1.91,
      hoistMass: 0.354,
      wheelLoad: 34.83
    },
    '15': {
      craneMass: 2.48,
      hoistMass: 0.354,
      wheelLoad: 35.54
    },
    '16.5': {
      craneMass: 3.75,
      hoistMass: 0.354,
      wheelLoad: 37.13
    },
    '18': {
      craneMass: 5.2,
      hoistMass: 0.354,
      wheelLoad: 38.94
    },
    '22.5': {
      craneMass: 6.2,
      hoistMass: 0.354,
      wheelLoad: 37.13
    }
  },
  '5': {
    '6': {
      craneMass: 2,
      hoistMass: 0.597,
      wheelLoad: 53.25
    },
    '9': {
      craneMass: 2.4,
      hoistMass: 0.597,
      wheelLoad: 53.75
    },
    '12': {
      craneMass: 2.8,
      hoistMass: 0.597,
      wheelLoad: 54.25
    },
    '15': {
      craneMass: 3.28,
      hoistMass: 0.597,
      wheelLoad: 54.85
    },
    '16.5': {
      craneMass: 5.07,
      hoistMass: 0.597,
      wheelLoad: 57.08
    },
    '18': {
      craneMass: 6,
      hoistMass: 0.597,
      wheelLoad: 58.25
    },
    '22.5': {
      craneMass: 8.7,
      hoistMass: 0.597,
      wheelLoad: 61.62
    }
  },
  '10': {
    '6': {
      craneMass: 5,
      hoistMass: 0.67,
      wheelLoad: 107.09
    },
    '9': {
      craneMass: 5.7,
      hoistMass: 0.67,
      wheelLoad: 107.96
    },
    '12': {
      craneMass: 6.5,
      hoistMass: 0.67,
      wheelLoad: 108.96
    },
    '15': {
      craneMass: 9.5,
      hoistMass: 0.67,
      wheelLoad: 112.71
    },
    '16.5': {
      craneMass: 10.5,
      hoistMass: 0.67,
      wheelLoad: 113.96
    },
    '18': {
      craneMass: 11.4,
      hoistMass: 0.67,
      wheelLoad: 115.09
    },
    '22.5': {
      craneMass: 14.25,
      hoistMass: 0.67,
      wheelLoad: 118.65
    }
  },
  '12.5': {
    '6': {
      craneMass: 6.25,
      hoistMass: 1.08,
      wheelLoad: 134.16
    },
    '9': {
      craneMass: 7.2,
      hoistMass: 1.08,
      wheelLoad: 135.35
    },
    '12': {
      craneMass: 9.5,
      hoistMass: 1.08,
      wheelLoad: 138.23
    },
    '15': {
      craneMass: 11.8,
      hoistMass: 1.08,
      wheelLoad: 141.10
    },
    '16.5': {
      craneMass: 13.2,
      hoistMass: 1.08,
      wheelLoad: 142.85
    },
    '18': {
      craneMass: 14.2,
      hoistMass: 1.08,
      wheelLoad: 144.10
    },
    '22.5': {
      craneMass: 17.8,
      hoistMass: 1.08,
      wheelLoad: 148.60
    }
  },
  '16': {
    '6': {
      craneMass: 8,
      hoistMass: 1.08,
      wheelLoad: 171.35
    },
    '9': {
      craneMass: 9.2,
      hoistMass: 1.08,
      wheelLoad: 172.85
    },
    '12': {
      craneMass: 12,
      hoistMass: 1.08,
      wheelLoad: 176.35
    },
    '15': {
      craneMass: 15,
      hoistMass: 1.08,
      wheelLoad: 180.10
    },
    '16.5': {
      craneMass: 16.8,
      hoistMass: 1.08,
      wheelLoad: 182.35
    },
    '18': {
      craneMass: 18.2,
      hoistMass: 1.08,
      wheelLoad: 184.10
    },
    '22.5': {
      craneMass: 22.8,
      hoistMass: 1.08,
      wheelLoad: 189.85
    }
  }
};

export const overheadTwoSpanMassParams = {
  '0.5': {
    '7.5+7.5': {
      craneMass: 0.8,
      hoistMass: 0.13,
      wheelLoad: 6.16
    },
    '9.0+9.0': {
      craneMass: 1,
      hoistMass: 0.13,
      wheelLoad: 6.41
    },
    '10.5+10.5': {
      craneMass: 1.2,
      hoistMass: 0.13,
      wheelLoad: 6.66
    },
    '12.0+12.0': {
      craneMass: 1.5,
      hoistMass: 0.13,
      wheelLoad: 7.04
    },
    '16.5': {
      craneMass: 1.8,
      hoistMass: 0.13,
      wheelLoad: 7.41
    }
  },
  '1': {
    '7.5+7.5': {
      craneMass: 0.8,
      hoistMass: 0.172,
      wheelLoad: 11.22
    },
    '9.0+9.0': {
      craneMass: 1,
      hoistMass: 0.172,
      wheelLoad: 11.47
    },
    '10.5+10.5': {
      craneMass: 1.2,
      hoistMass: 0.172,
      wheelLoad: 11.72
    },
    '12.0+12.0': {
      craneMass: 1.5,
      hoistMass: 0.172,
      wheelLoad: 12.09
    },
    '16.5+16.5': {
      craneMass: 1.8,
      hoistMass: 0.172,
      wheelLoad: 12.47
    }
  },
  '2': {
    '7.5+7.5': {
      craneMass: 0.96,
      hoistMass: 0.285,
      wheelLoad: 21.56
    },
    '9.0+9.0': {
      craneMass: 1.23,
      hoistMass: 0.285,
      wheelLoad: 21.89
    },
    '10.5+10.5': {
      craneMass: 1.5,
      hoistMass: 0.285,
      wheelLoad: 22.23
    },
    '12.0+12.0': {
      craneMass: 1.86,
      hoistMass: 0.285,
      wheelLoad: 22.68
    },
    '16.5+16.5': {
      craneMass: 3.58,
      hoistMass: 0.285,
      wheelLoad: 24.83
    }
  },
  '3.2': {
    '7.5+7.5': {
      craneMass: 1.3,
      hoistMass: 0.354,
      wheelLoad: 34.07
    },
    '9.0+9.0': {
      craneMass: 1.7,
      hoistMass: 0.354,
      wheelLoad: 34.57
    },
    '10.5+10.5': {
      craneMass: 1.91,
      hoistMass: 0.354,
      wheelLoad: 34.83
    },
    '12.0+12.0': {
      craneMass: 2.48,
      hoistMass: 0.354,
      wheelLoad: 35.54
    },
    '16.5+16.5': {
      craneMass: 3.75,
      hoistMass: 0.354,
      wheelLoad: 37.13
    }
  },
  '5': {
    '7.5+7.5': {
      craneMass: 2,
      hoistMass: 0.597,
      wheelLoad: 53.25
    },
    '9.0+9.0': {
      craneMass: 2.4,
      hoistMass: 0.597,
      wheelLoad: 53.75
    },
    '10.5+10.5': {
      craneMass: 2.8,
      hoistMass: 0.597,
      wheelLoad: 54.25
    },
    '12.0+12.0': {
      craneMass: 3.28,
      hoistMass: 0.597,
      wheelLoad: 54.85
    },
    '16.5+16.5': {
      craneMass: 5.07,
      hoistMass: 0.597,
      wheelLoad: 57.08
    }
  },
  '10': {
    '7.5+7.5': {
      craneMass: 5,
      hoistMass: 0.67,
      wheelLoad: 107.09
    },
    '9.0+9.0': {
      craneMass: 5.7,
      hoistMass: 0.67,
      wheelLoad: 107.96
    },
    '10.5+10.5': {
      craneMass: 6.5,
      hoistMass: 0.67,
      wheelLoad: 108.96
    },
    '12.0+12.0': {
      craneMass: 9.5,
      hoistMass: 0.67,
      wheelLoad: 112.71
    },
    '16.5+16.5': {
      craneMass: 10.5,
      hoistMass: 0.67,
      wheelLoad: 113.96
    }
  },
  '12.5': {
    '7.5+7.5': {
      craneMass: 6.25,
      hoistMass: 1.08,
      wheelLoad: 134.16
    },
    '9.0+9.0': {
      craneMass: 7.2,
      hoistMass: 1.08,
      wheelLoad: 135.35
    },
    '10.5+10.5': {
      craneMass: 9.5,
      hoistMass: 1.08,
      wheelLoad: 138.23
    },
    '12.0+12.0': {
      craneMass: 11.8,
      hoistMass: 1.08,
      wheelLoad: 141.10
    },
    '16.5+16.5': {
      craneMass: 13.2,
      hoistMass: 1.08,
      wheelLoad: 142.85
    }
  },
  '16': {
    '7.5+7.5': {
      craneMass: 8,
      hoistMass: 1.08,
      wheelLoad: 171.35
    },
    '9.0+9.0': {
      craneMass: 9.2,
      hoistMass: 1.08,
      wheelLoad: 172.85
    },
    '10.5+10.5': {
      craneMass: 12,
      hoistMass: 1.08,
      wheelLoad: 176.35
    },
    '12.0+12.0': {
      craneMass: 15,
      hoistMass: 1.08,
      wheelLoad: 180.10
    },
    '16.5+16.5': {
      craneMass: 16.8,
      hoistMass: 1.08,
      wheelLoad: 182.35
    }
  }
};

