const QlistSaveIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1365 10V9.82843C18.1365 9.29799 17.9242 8.78929 17.5462 8.41421L13.6879 4.58579C13.3099
       4.21071 12.7972 4 12.2627 4H12.0897M18.1365 10V18C18.1365 19.1046 17.2342 20 16.1209 20H13.0975M18.1365
       10H14.1053C12.9921 10 12.0897 9.10457 12.0897 8V4M12.0897 4H8.05854C6.94535 4 6.04294 4.89543 6.04294
       6V13M6.04294 20V17.5M6.04294 20V22.5M6.04294 20H3.52344M6.04294 20H8.56244" stroke="#3D487C"
      strokeWidth="1.25" strokeLinecap="round"/>
    </svg>

  );
};

export default QlistSaveIcon;
