import styles from './CreateFeedback.module.scss';
import SendBlock from '../SendBlock/SendBlock';
import { feedbackCreate } from '../../../../service/feedback';

const CreateFeedback = ({ email, name, onClick }) => {

  const onSendMessageHandler = (message) => {
    onClick('main');
    feedbackCreate(email, name,`${message.substring(0,20)}...`, message).then();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.info}>Напишите сообщение. Мы рассмотрим его и обязательно ответим в ближайшее время</div>
      </div>
      <SendBlock onSendMessage={onSendMessageHandler} />
    </div>
  );
};

export default CreateFeedback;