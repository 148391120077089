import styles from './MenuIconLabelBlock.module.scss';

const MenuIconLabelBlock = ({ icon,  label, isLabelShow }) => {

  return (
    <div className={isLabelShow ? styles.container : `${styles.containerClose} ${styles.container}`}>
      {icon}
      <div className={isLabelShow ? styles.fadeIn : styles.fadeOut}>{label}</div>
    </div>
  );
};

export default MenuIconLabelBlock;