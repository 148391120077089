import styles from './Changelog.module.scss';
import {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import changelogFile from '../../../CHANGELOG.md';

const Changelog = () => {
  const [changelog, setChangelog] = useState([]);

  useEffect(() => {
    fetch(changelogFile)
      .then(response => response.text())
      .then(data => {
        setChangelog(data.split('    '));
      })
      .catch(error => console.error(error));
  }, []);
  
  return (
    <div className={styles.container}>
      {changelog.map((item) => {
        const parsed = item.split('##');
        return (
          <div key={parsed[0]} className={styles.block}>
            <div className={styles.title}>
              <div className={styles.left}>
                {parsed[0].split(' - ')[0]}
              </div>
              -
              <div className={styles.right}>
                {parsed[0].split(' - ')[1]}
              </div>
            </div>
            <ReactMarkdown>{parsed[1]}</ReactMarkdown>
            <ReactMarkdown>{parsed[2]}</ReactMarkdown>
            <ReactMarkdown>{parsed[3]}</ReactMarkdown>
          </div>
        );
      })}
    </div>
  );
  
};

export default Changelog;