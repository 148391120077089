const LogoIcon = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 15C0 6.71573 6.71573 0 15 0H118C126.284 0 133
       6.71573 133 15V118C133 126.284 126.284 133 118 133H15C6.71573 133 0 126.284 0 118V15ZM58.2849 28.9349L44.8069
       43.137C42.993 45.0483 44.3479 48.2021 46.983 48.2021H69.3868C71.0437 48.2021 72.3868 49.5453 72.3868
       51.2021V76.6466C72.3868 79.1814 75.3356 80.5738 77.293 78.9633L94.9061 64.4712C95.5987 63.9014 96 63.0515 96
       62.1546V31C96 29.3431 94.6569 28 93 28H60.461C59.6381 28 58.8514 28.338 58.2849 28.9349ZM38 108.098V74.57C38
       72.5655 39.001 70.6934 40.668 69.5802L60.8893 56.0772C62.2183 55.1897 64 56.1424 64 57.7404V90.2271C64 92.158
       63.0707 93.9709 61.503 95.0983L41.1677 109.722C39.8446 110.673 38 109.728 38 108.098Z" fill="#F57C4A" />
    </svg>
  );
};

export default LogoIcon;
