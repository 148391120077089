import { createSlice } from '@reduxjs/toolkit';
import {CRANE_IMPLEMENTATION, CRANE_TYPE} from '../../../common/consts/enums';

const initialState = {
  craneType: {
    label: 'Тип крана',
    value: CRANE_TYPE.overheadOneSpan
  },

  implementation: {
    label: 'Исполнение',
    value: 'Общепромышленный'
  },

  loadCapacity: {
    label: 'Грузоподъемность по ГОСТ 1575-87',
    value: '0.5'
  },

  oneSpanLength: {
    label: 'Пролет по ГОСТ 1575-87, м (Lп)',
    value: '6',
    customLabel: 'Пролет по данным Заказчика, м (Lп)',
    customValue: '6',
    dependencies: {
      craneType: [CRANE_TYPE.overheadOneSpan, CRANE_TYPE.pylon]
    }
  },

  twoSpanLength: {
    label: 'Пролет по ГОСТ 1575-87 для двухпролетного кранов, м (Lп)',
    value: '7.5+7.5',
    customLabel: 'Пролет по данным Заказчика для двухпролетного крана, м (Lп)',
    customValue: '',
    dependencies: {
      craneType: [CRANE_TYPE.overheadTwoSpan]
    }
  },

  consoleLength: {
    label: 'Длина консолей, м (Lкон)',
    value: '0',
    dependencies: {
      craneType: [CRANE_TYPE.overheadTwoSpan, CRANE_TYPE.overheadOneSpan]
    }
  },

  fullCraneLength: {
    label: 'Полная длина крана, м (Lк)',
    value: '0'
  },

  liftingHeight: {
    label: 'Высота подьема, м (H)',
    value: '6',
    customLabel: 'Высота подьема по данным Заказчика',
    customValue: ''
  },

  mainBeamType: {
    label: 'Конструкция пролетной балки',
    value: 'не задано'
  },

  deliveryFeatures: {
    label: 'Особенности доставки',
    value: 'не задано',
    customLabel: 'Особенности доставки по данным Заказчика',
    customValue: ''
  },

  workingModeByIso: {
    label: 'Режим работы по ИСО 4301/1',
    value: 'не задано',
    customLabel: 'Особый режим работы по ИСО 4301/1',
    customValue: ''
  },

  craneRailType: {
    label: 'Тип подкранового рельса/двутавра',
    value: 'не задано',
    customLabel: 'Особый тип подкранового рельса/двутавра',
    customValue: ''
  },

  workingTemperature: {
    label: 'Температура эксплуатации',
    value: 'не задано',
    customLabel: 'Температура эксплуатации по данным Заказчика',
    customValue: ''
  },

  climaticDesign: {
    label: 'Климатическое исполнение',
    value: 'на открытом воздухе (У1)',
    customLabel: 'Климатическое исполнение по данным Заказчика',
    customValue: ''
  },

  fireSafetyRequirements: {
    label: 'Требования пожароопасности',
    value: 'нет',
    dependencies: {
      implementation: [CRANE_IMPLEMENTATION.fireProof]
    }
  },

  fireSafetyClass: {
    label: 'Класс пожароопасности',
    value: 'П1',
    dependencies: {
      explosionsProtectionRequirements: ['требуется']
    }
  },

  explosionsProtectionRequirements: {
    label: 'Требования взрывозащиты Ex',
    value: '',
    dependencies: {
      implementation: [CRANE_IMPLEMENTATION.explosionProof]
    }
  },

  explosionsProtectionGroup: {
    label: 'Требования Ex по группе',
    value: 'I',
    dependencies: {
      explosionsProtectionRequirements: ['требуется']
    }
  },

  explosionsProtectionTemperature: {
    label: 'требования Ex по температуре',
    value: 'Т1 (450°С)',
    dependencies: {
      explosionsProtectionRequirements: ['требуется']
    }
  },

  seismicDesign: {
    label: 'Сейсмическое исполнение по МSK64',
    value: '6 (стандарт)',
    customLabel: 'Сейсмическое исполнение по данным Заказчика',
    customValue: ''
  },

  movementSpeed: {
    label: 'Скорость передвижения крана основная, м/мин по ГОСТ 1575-87',
    value: '20 м/мин',
    customLabel: 'Скорость передвижения по данным Заказчика, м/мин',
    customValue: ''
  },

  secondMicroSpeed: {
    label: 'Скорость передвижения крана вторая микроскорость, м/мин',
    value: 'не требуется',
    customLabel: 'Вторая микроскорость по данным Заказчика, м/мин',
    customValue: ''
  },

  specifiedCraneCapacity: {
    label: 'Установленная мощность крана, не более, кВт',
    value: 'не задано',
    customLabel: 'Установленная мощность крана по данным Заказчика, кВт',
    customValue: '3.5'
  },

  craneWeight: {
    label: 'Масса крана, не более, т',
    value: 'не задано',
    customLabel: 'Масса крана по данным Заказчика, т',
    customValue: '0.8'
  },

  wheelLoad: {
    label: 'Нагрузка на колесо, не более кН',
    value: 'не задано',
    customLabel: 'Нагрузка на колесо по данным Заказчика, кН',
    customValue: '6.16'
  }
};

export const metalConstructionSlice = createSlice({
  name: 'metalConstruction',
  initialState,
  reducers: {
    craneTypeSet:  (state, action) => {
      state.craneType.value = action.payload;
    },
    implementationSet:  (state, action) => {
      state.implementation.value = action.payload;
    },
    loadCapacitySet:  (state, action) => {
      state.loadCapacity.value = action.payload;
    },
    oneSpanLengthSet:  (state, action) => {
      state.oneSpanLength.value = action.payload;
    },
    oneSpanLengthCustomSet:  (state, action) => {
      state.oneSpanLength.customValue = action.payload;
    },
    twoSpanLengthSet:  (state, action) => {
      state.twoSpanLength.value = action.payload;
    },
    twoSpanLengthCustomSet:  (state, action) => {
      state.twoSpanLength.customValue = action.payload;
    },
    consoleLengthSet:  (state, action) => {
      state.consoleLength.value = action.payload;
    },
    fullCraneLengthSet:  (state, action) => {
      state.fullCraneLength.value = action.payload;
    },
    liftingHeightSet:  (state, action) => {
      state.liftingHeight.value = action.payload;
    },
    liftingHeightCustomSet:  (state, action) => {
      state.liftingHeight.customValue = action.payload;
    },
    mainBeamTypeSet:  (state, action) => {
      state.mainBeamType.value = action.payload;
    },
    deliveryFeaturesSet:  (state, action) => {
      state.deliveryFeatures.value = action.payload;
    },
    deliveryFeaturesCustomSet:  (state, action) => {
      state.deliveryFeatures.customValue = action.payload;
    },
    workingModeByIsoSet:  (state, action) => {
      state.workingModeByIso.value = action.payload;
    },
    workingModeByIsoCustomSet:  (state, action) => {
      state.workingModeByIso.customValue = action.payload;
    },
    craneRailTypeSet:  (state, action) => {
      state.craneRailType.value = action.payload;
    },
    craneRailTypeCustomSet:  (state, action) => {
      state.craneRailType.customValue = action.payload;
    },
    workingTemperatureSet:  (state, action) => {
      state.workingTemperature.value = action.payload;
    },
    workingTemperatureCustomSet:  (state, action) => {
      state.workingTemperature.customValue = action.payload;
    },
    climaticDesignSet:  (state, action) => {
      state.climaticDesign.value = action.payload;
    },
    climaticDesignCustomSet:  (state, action) => {
      state.climaticDesign.customValue = action.payload;
    },
    fireSafetyRequirementsSet:  (state, action) => {
      state.fireSafetyRequirements.value = action.payload;
    },
    fireSafetyClassSet:  (state, action) => {
      state.fireSafetyClass.value = action.payload;
    },
    explosionsProtectionRequirementsSet:  (state, action) => {
      state.explosionsProtectionRequirements.value = action.payload;
    },
    explosionsProtectionGroupSet:  (state, action) => {
      state.explosionsProtectionGroup.value = action.payload;
    },
    explosionsProtectionTemperatureSet:  (state, action) => {
      state.explosionsProtectionTemperature.value = action.payload;
    },
    seismicDesignSet:  (state, action) => {
      state.seismicDesign.value = action.payload;
    },
    seismicDesignCustomSet:  (state, action) => {
      state.seismicDesign.customValue = action.payload;
    },
    movementSpeedSet:  (state, action) => {
      state.movementSpeed.value = action.payload;
    },
    movementSpeedCustomSet:  (state, action) => {
      state.movementSpeed.customValue = action.payload;
    },
    secondMicroSpeedSet:  (state, action) => {
      state.secondMicroSpeed.value = action.payload;
    },
    secondMicroSpeedCustomSet:  (state, action) => {
      state.secondMicroSpeed.customValue = action.payload;
    },
    specifiedCraneCapacitySet:  (state, action) => {
      state.specifiedCraneCapacity.value = action.payload;
    },
    specifiedCraneCapacityCustomSet:  (state, action) => {
      state.specifiedCraneCapacity.customValue = action.payload;
    },
    craneWeightSet:  (state, action) => {
      state.craneWeight.value = action.payload;
    },
    craneWeightCustomSet:  (state, action) => {
      state.craneWeight.customValue = action.payload;
    },
    wheelLoadSet:  (state, action) => {
      state.wheelLoad.value = action.payload;
    },
    wheelLoadCustomSet:  (state, action) => {
      state.wheelLoad.customValue = action.payload;
    },
    metalConstructionSet:  (state, action) => {
      state.craneType.value = action.payload.craneType.value;
      state.implementation.value = action.payload.implementation.value;
      state.loadCapacity.value = action.payload.loadCapacity.value;
      state.oneSpanLength.value = action.payload.oneSpanLength.value;

      state.oneSpanLength.customValue = action.payload.oneSpanLength.customValue;
      state.oneSpanLength.value = action.payload.oneSpanLength.value;

      state.twoSpanLength.value = action.payload.twoSpanLength.value;
      state.twoSpanLength.customValue = action.payload.twoSpanLength.customValue;

      state.consoleLength.value = action.payload.consoleLength.value;
      state.fullCraneLength.value = action.payload.fullCraneLength.value;

      state.liftingHeight.value = action.payload.liftingHeight.value;
      state.liftingHeight.customValue = action.payload.liftingHeight.customValue;

      state.mainBeamType.value = action.payload.mainBeamType.value;

      state.deliveryFeatures.value = action.payload.deliveryFeatures.value;
      state.deliveryFeatures.customValue = action.payload.deliveryFeatures.customValue;

      state.workingModeByIso.value = action.payload.workingModeByIso.value;
      state.workingModeByIso.customValue = action.payload.workingModeByIso.customValue;

      state.craneRailType.value = action.payload.craneRailType.value;
      state.craneRailType.customValue = action.payload.craneRailType.customValue;

      state.workingTemperature.value = action.payload.workingTemperature.value;
      state.workingTemperature.customValue = action.payload.workingTemperature.customValue;

      state.climaticDesign.value = action.payload.climaticDesign.value;
      state.climaticDesign.customValue = action.payload.climaticDesign.customValue;

      state.fireSafetyRequirements.value = action.payload.fireSafetyRequirements.value;
      state.fireSafetyClass.value = action.payload.fireSafetyClass.value;
      state.explosionsProtectionRequirements.value = action.payload.explosionsProtectionRequirements.value;
      state.explosionsProtectionGroup.value = action.payload.explosionsProtectionGroup.value;
      state.explosionsProtectionTemperature.value = action.payload.explosionsProtectionTemperature.value;

      state.seismicDesign.value = action.payload.seismicDesign.value;
      state.seismicDesign.customValue = action.payload.seismicDesign.customValue;

      state.movementSpeed.value = action.payload.movementSpeed.value;
      state.movementSpeed.customValue = action.payload.movementSpeed.customValue;

      state.secondMicroSpeed.value = action.payload.secondMicroSpeed.value;
      state.secondMicroSpeed.customValue = action.payload.secondMicroSpeed.customValue;

      state.specifiedCraneCapacity.value = action.payload.specifiedCraneCapacity.value;
      state.specifiedCraneCapacity.customValue = action.payload.specifiedCraneCapacity.customValue;

      state.craneWeight.value = action.payload.craneWeight.value;
      state.craneWeight.customValue = action.payload.craneWeight.customValue;

      state.wheelLoad.value = action.payload.wheelLoad.value;
      state.wheelLoad.customValue = action.payload.wheelLoad.customValue;
    }
  }
});

export const {
  craneTypeSet,
  implementationSet,
  loadCapacitySet,
  oneSpanLengthSet,
  oneSpanLengthCustomSet,
  twoSpanLengthSet,
  twoSpanLengthCustomSet,
  consoleLengthSet,
  fullCraneLengthSet,
  liftingHeightSet,
  liftingHeightCustomSet,
  mainBeamTypeSet,
  deliveryFeaturesSet,
  deliveryFeaturesCustomSet,
  workingModeByIsoSet,
  workingModeByIsoCustomSet,
  craneRailTypeSet,
  craneRailTypeCustomSet,
  workingTemperatureSet,
  workingTemperatureCustomSet,
  climaticDesignSet,
  climaticDesignCustomSet,
  fireSafetyRequirementsSet,
  fireSafetyClassSet,
  explosionsProtectionRequirementsSet,
  explosionsProtectionGroupSet,
  explosionsProtectionTemperatureSet,
  seismicDesignSet,
  seismicDesignCustomSet,
  movementSpeedSet,
  movementSpeedCustomSet,
  secondMicroSpeedSet,
  secondMicroSpeedCustomSet,
  specifiedCraneCapacitySet,
  specifiedCraneCapacityCustomSet,
  craneWeightSet,
  craneWeightCustomSet,
  wheelLoadSet,
  wheelLoadCustomSet,
  metalConstructionSet
} = metalConstructionSlice.actions;

export default metalConstructionSlice.reducer;
