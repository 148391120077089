import styles from './LogoPanel.module.scss';
import OpenMenuIcon from './OpenMenuIcon/OpenMenuIcon';
import LogoIcon from '../../common/icons/LogoIcon/LogoIcon';

const LogoPanel = ({ isSideOpen, setIsSideOpen }) => {
  const onIconClickHandler = () => {
    setIsSideOpen(!isSideOpen);
  };

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <div>
          <LogoIcon size={50}/>
        </div>
        <div
          className={isSideOpen ? styles.roll : `${styles.roll} ${styles.disappear}`}
          onClick={onIconClickHandler}
        >
          <OpenMenuIcon isSideOpen={isSideOpen}/>
        </div>
        <div
          className={isSideOpen ? `${styles.close} ${styles.disappear}` : styles.close}
          onClick={() => setIsSideOpen(!isSideOpen)}
        >
          <OpenMenuIcon isSideOpen={isSideOpen}/>
        </div>
      </div>
    </div>
  );
};

export default LogoPanel;