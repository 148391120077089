import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    textTransform: 'uppercase'
  },
  text: {
    border: '1px solid grey',
    width: '100%',
    padding: '5px 10px',
  }

});

const Types = ({ craneType, implementation }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.text}>{`ТИП: ${craneType}`}</Text>
      <Text style={styles.text}>{`ИСПОЛНЕНИЕ: ${implementation}`}</Text>
    </View>
  );
};

export default Types;
