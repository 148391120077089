const CraneTypeIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="51" height="51" rx="7" fill="#F3F4F8"/>
      <path d="M25.5 22.8788L33 20.3788M25.5 22.8788L18 20.3788M25.5 22.8788V33.3788M26.1656 17.1138L32.6656
       19.4079C33.4653 19.6901 34 20.4459 34 21.2939V29.1096C34 29.8843 33.5526 30.5893 32.8516 30.9192L26.3516
       33.978C25.8122 34.2319 25.1878 34.2319 24.6484 33.978L18.1484 30.9192C17.4474 30.5894 17 29.8843
       17 29.1096V21.2939C17 20.4459 17.5347 19.6901 18.3344 19.4079L24.8344 17.1138C25.2651 16.9617
       25.7349 16.9617 26.1656 17.1138Z" stroke="#3D487C" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
};

export default CraneTypeIcon;