import styles from './QuestionListsMobile.module.scss';
import DotsIcon from '../../../common/icons/DotsIcon/DotsIcon';
import ControlModal from '../components/ControlModal/ControlModal';
import {useSelector} from 'react-redux';

const QuestionListsMobile = ({
  dataSource,
  isShowControl,
  onControlClick,
  activeRowId,
  onShowQlist,
  onLoadQlist,
  onDeleteQlist,
  setIsShowControl
}) => {
  const { current } = useSelector(state => state.qlistParams);

  return(
    <div className={styles.container}>
      {dataSource && dataSource.map((item) => {
        return(
          <div key={item.id} className={(current.id === item.id && !current.hasChanges) ? `${styles.card} ${styles.current}`: styles.card}>
            <div
              className={styles.dots}
              onClick={() => onControlClick(item.id)}
            >
              <DotsIcon color={(current.id === item.id && !current.hasChanges) ? 'white' : '#3D487C'}/>
              {isShowControl && activeRowId === item.id && (
                <div>
                  <ControlModal
                    id={item.id}
                    onShow={onShowQlist}
                    onLoad={onLoadQlist}
                    onDelete={onDeleteQlist}
                    onClose={() => setIsShowControl(false)}
                  />
                </div>
              )}
            </div>
            <div className={styles.title}>
              {`Опросный лист № ${item.id}`}
            </div>
            <div className={styles.params}>
              <div className={styles.row}>
                <div className={styles.label}>Дата</div>
                <div className={styles.value}>{new Date(item.createdAt).toLocaleString('ru-Ru')}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Тип крана</div>
                <div className={styles.value}>{item.craneType}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Пролет</div>
                <div className={styles.value}>{item.spanLength}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Высота подъема</div>
                <div className={styles.value}>{item.liftingHeight}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Режим работы</div>
                <div className={styles.value}>{item.workingModeByIso}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Заказчик</div>
                <div className={styles.value}>{item.customer}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionListsMobile;