const CraneSectionsIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="51" height="51" rx="7" fill="#F3F4F8"/>
      <rect width="51" height="51" rx="7" fill="#F3F4F8" fillOpacity="0.2"/>
      <path d="M29 19.0001V18C29 16.8954 28.1046 16 27 16H19C17.8954 16 17 16.8954 17 18V26.0001C17 27.1046
       17.8954 28 18.9999 28.0001H20M29 28.0001V25.5001M29 28.0001V30.5001M29 28.0001H26.5M29 28.0001H31.5M25
       34.0001H33C34.1046 34.0001 35 33.1047 35 32.0001V24.0001C35 22.8956 34.1046 22.0001 33 22.0001H25C23.8954
       22.0001 23 22.8956 23 24.0001V32.0001C23 33.1047 23.8954 34.0001 25 34.0001Z" stroke="#3D487C"
      strokeWidth="1.5" strokeLinecap="round"/>
    </svg>

  );
};

export default CraneSectionsIcon;