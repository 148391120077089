const DeleteIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 4.25H14.875M12.75 4.25V13.4583C12.75 14.2408 12.1158 14.875 11.3333 14.875H5.66667C4.88426
        14.875 4.25 14.2408 4.25 13.4583V4.25M12.75 4.25H4.25M4.25 4.25H2.125M7.08333 6.72917V12.3958M9.91667
        6.72917V12.3958M6.02083 2.125H10.9792" stroke="#AF2A2A" strokeLinecap="round"/>
    </svg>
  );
};

export default DeleteIcon;
