import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    gap: '10px',
    padding: 10,
    border: '1px solid grey'
  },
  column: {
    flexDirection: 'column',
    width: '60%',
    wordWrap: 'break-word'
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: '900',
    fontSize: '16px',
    marginBottom: '5px'
  },
  logo: {
    width:'50px',
    height:'50px',
    margin: 5,
  },
  link: {
    marginBottom: '5px'
  }

});

const Description = ({ logo, label, link, text }) => {
  return (
    <View style={styles.row}>
      <Image style={styles.logo} src={logo} />
      <View style={styles.column}>
        <Text style={styles.label} break>{label}</Text>
        <Link style={styles.link} src={link} break>{link}</Link>
        <Text>{text}</Text>
      </View>
    </View>
  );
};

export default Description;
