import styles from './QuestionLists.module.scss';
import { useEffect, useRef, useState } from 'react';
import {
  filterValuesSet,
  initialFilterValues,
} from '../../store/slices/filters/filter';
import EmptyTable from './components/EmptyTable/EmptyTable';
import {useSelector} from 'react-redux';
import { qlistDelete, qlistLoad } from '../../service/qlist';
import usePagination from '../../hooks/usePagination';
import PaginationPanel from './components/PaginationPanel/PaginationPanel.jsx';
import { getUniqueValues } from './utils/params';
import { store } from '../../store/store';
import QuestionListsDesktop from './desktop/QuestionListsDesktop';
import QuestionListsMobile from './mobile/QuestionListsMobile';
import Filters from './components/Filters/Filters';
import FilterIcon from '../../common/icons/FilterIcon/FilterIcon';
import { calculation } from '../../service/calcualtion';

const QuestionLists = ({ qlistArray, onViewButtonClick, guideStep, setActiveContent }) => {
  const [dataSource, setDataSource] = useState([]);
  const [isShowControl, setIsShowControl] = useState(false);
  const [activeRowId, setActiveRowId] = useState(null);
  const [tableHeight, setTableHeight] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [dropdownValues, setDropdownValues] = useState({
    craneTypes: [],
    spanLengths: [],
    customers: []
  });

  const filters = useSelector(state => state.filters);
  const ref = useRef();
  const containerRef = useRef();
  const tableRef = useRef();
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    setPage,
    totalPages,
    pagesToShow,
    isLeftDotsVisible,
    isRightDotsVisible
  } = usePagination({
    contentPerPage: Math.floor(tableHeight / 62),
    siblingCount: 1,
    count: dataSource.length
  });

  useEffect(() => {
    if (qlistArray.length) {
      let data = [ ...qlistArray ];

      const craneTypes = getUniqueValues('craneType', data);
      const spanLengths = getUniqueValues('spanLength', data);
      const customers = getUniqueValues('customer', data);

      setDropdownValues({
        craneTypes: ['Не задано', ...craneTypes],
        spanLengths: ['Не задано', ...spanLengths],
        customers: ['Не задано', ...customers]
      });

      if (filters.startDate
        || filters.endDate
        || filters.craneType
        || filters.spanLength
        || filters.customer
      ) {
        if (filters.craneType && filters.craneType !== 'Не задано') {
          data = data.filter((item) => item.craneType ===filters.craneType);
        }

        if (filters.spanLength && filters.spanLength !== 'Не задано') {
          data = data.filter((item) => item.spanLength === filters.spanLength);
        }

        if (filters.customer && filters.customer !== 'Не задано') {
          data = data.filter((item) => item.customer === filters.customer);
        }

        const filterEndDate = new Date(filters.endDate).setHours(23,59,59);

        data = data.filter((item) => new Date(item.createdAt) >= new Date(filters.startDate)
          && new Date(item.createdAt) <= new Date(filterEndDate));

        setDataSource(data);
        return;
      }

      setDataSource(data);
    }

    if (!qlistArray.length) {
      setDataSource([]);
    }
  }, [qlistArray,
    filters.startDate,
    filters.endDate,
    filters.craneType,
    filters.spanLength,
    filters.customer
  ]);

  useEffect(() => {
    if (tableRef.current) {
      const height = tableRef.current.getBoundingClientRect().height;
      setTableHeight(height);
    }
  }, [tableRef, ref]);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      setContainerWidth(width);
    }
  }, [containerRef]);

  const handleClickOutside = () => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsShowControl(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  
  const handleControlClick = (id) => {
    if (activeRowId === id) {
      setIsShowControl(false);
      setActiveRowId(null);

      return;
    }

    setIsShowControl(true);
    setActiveRowId(id);
  };

  const handleShowQlist = (id) => {
    qlistLoad(id).then(() => {
      onViewButtonClick(true);
    });
  };
  
  const handleLoadQlist = (id) => {
    qlistLoad(id).then();
  };

  const handleDeleteQlist = (id) => {
    qlistDelete(id).then();
  };

  const onCalculateClickHandler = (coefficients, id, params) => {
    setActiveContent('calculation');
    calculation(coefficients, id, {}, params).then();
  };
  
  const handleResetClickHandler = () => {
    store.dispatch(filterValuesSet(initialFilterValues));
  };
  
  return(
    <div ref={containerRef} className={guideStep === 6 ? `${styles.container} ${styles.guide}` : styles.container}>
      <div className={styles.title}>
        <div>Опросные листы</div>
        {containerWidth <= 768 &&
          <div
            className={styles.icon}
            onClick={() => setIsPopupShow(true)}
          >
            <FilterIcon />
          </div>
        }
      </div>
      {(containerWidth <= 768 && isPopupShow) && (
        <>
          <div className={styles.shadow} />
          <div className={styles.popup}>
            <Filters
              dropdownValues={dropdownValues}
              filters={filters}
              onResetClickHandler={handleResetClickHandler}
              onControlClick={handleControlClick}
              width={containerWidth}
              setIsPopupShow={setIsPopupShow}
            />
          </div>
        </>
      )}
      {containerWidth > 768 && (
        <>
          <Filters
            dropdownValues={dropdownValues}
            filters={filters}
            onResetClickHandler={handleResetClickHandler}
            onControlClick={handleControlClick}
          />
          <div className={styles.header}>
            <div className={`${styles.column} ${styles.id}`}>ID</div>
            <div className={styles.column}>Дата</div>
            <div className={styles.column}>Тип крана</div>
            <div className={styles.column}>Пролет</div>
            <div className={styles.column}>Высота подъема</div>
            <div className={styles.column}>Режим работы</div>
            <div className={styles.column}>Заказчик</div>
            <div className={styles.control} />
          </div>
        </>
      )}
      <div ref={tableRef} className={styles.table}>
        {(dataSource.length > 0 && containerWidth > 768) &&
          <QuestionListsDesktop
            dataSource={dataSource}
            firstContentIndex={firstContentIndex}
            lastContentIndex={lastContentIndex}
            isShowControl={isShowControl}
            onControlClick={handleControlClick}
            activeRowId={activeRowId}
            onShowQlist={handleShowQlist}
            onLoadQlist={handleLoadQlist}
            onDeleteQlist={handleDeleteQlist}
            onCalculateClickHandler={onCalculateClickHandler}
            setIsShowControl={setIsShowControl}
          />}
        {(dataSource.length > 0 && containerWidth <= 768) &&
          <QuestionListsMobile
            onControlClick={handleControlClick}
            dataSource={dataSource}
            onCalculateClickHandler={onCalculateClickHandler}
            activeRowId={activeRowId}
            isShowControl={isShowControl}
            onShowQlist={handleShowQlist}
            onLoadQlist={handleLoadQlist}
            onDeleteQlist={handleDeleteQlist}
            setIsShowControl={setIsShowControl}
          />
        }
        {dataSource.length === 0 && <EmptyTable isFilteredDataEmpty={!!qlistArray.length}/>}
        <div className={dataSource.slice(firstContentIndex, lastContentIndex).length === 8 ? `${styles.footer} ${styles.fullFooter}` : `${styles.footer}`} />
      </div>
      {(totalPages > 1 && containerWidth > 768) && (
        <PaginationPanel
          totalPages={totalPages}
          activePage={page}
          pagesToShow={pagesToShow}
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={setPage}
          isLeftDotsVisible={isLeftDotsVisible}
          isRightDotsVisible={isRightDotsVisible}
        />
      )}
    </div>
  );
};

export default QuestionLists;
