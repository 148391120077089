import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import overhead from './img/overhead.png';
import pylon from './img/pylon.png';
import overheadTwoSpan from './img/overheadTwoSpan.png';
import {CRANE_TYPE} from '../../../consts/enums';

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
    padding: '20px 0'
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: '900',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '5px'
  },
  img: {
    padding: 5
  }

});

const Img = ({craneType}) => {
  let type;
  let title;

  if (craneType.value === CRANE_TYPE.overheadOneSpan) {
    title = 'Габаритный чертеж однопролетного подвесного крана';
    type = overhead;
  }

  if (craneType.value === CRANE_TYPE.pylon) {
    title = 'Габаритный чертеж опорного крана';
    type = pylon;
  }

  if (craneType.value === CRANE_TYPE.overheadTwoSpan) {
    title = 'Габаритный чертеж двухпролетного подвесного крана';
    type = overheadTwoSpan;
  }
  
  return (
    <View style={styles.column}>
      <Text style={styles.label}>{title}</Text>
      <Image style={styles.img} src={type} />
    </View>
  );
};

export default Img;
