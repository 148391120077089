import styles from './SelectBlock.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hasChangesSet } from '../../../store/slices/qlistParams/qlistParamsSlice';

const SelectBlock = ({
  param,
  onChange,
  defaultValue,
  inputType,
  placeholder,
  disabled,
  onEntityParamsChange,
  dropDownList,
  onSearchEntity
}) => {
  const [value, setValue] = useState('');
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  const handleClickOutside = () => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  
  useEffect (() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    value && onSearchEntity({ [param]: value }, signal).then();

    return () => abortController.abort();
  }, [value]);

  const onInputChange = (data) => {
    setValue(data);
    setIsShowDropdown(true);
  };

  const onInputBlur = () => {
    if (value) {
      setValue(value);
      dispatch(onChange(value));
    } else {
      setValue('');
      dispatch(onChange(''));
    }
    dispatch(hasChangesSet(true));
  };

  const handleOptionClick = (data) => {
    setIsShowDropdown(false);
    dispatch(onEntityParamsChange(data));
  };

  return (
    <div 
      className={styles.container}
      onFocus={() => setIsFocus(true)} 
      onBlur={() => setIsFocus(false)}
    >
      <input
        className={styles.input}
        type={inputType}
        onChange={(e) => onInputChange(e.target.value)}
        onBlur={onInputBlur}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e) => e.key === 'Enter' && onInputBlur()}
        disabled={disabled}
      />
      {(dropDownList.length && isShowDropdown && isFocus) ? (
        <div className={styles.popup} ref={ref}>
          {dropDownList.map(item => (
            <div
              className={styles.item}
              key={item.id}
              role="presentation"
              onClick={() => handleOptionClick(item)}
            >
              {item[param]}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SelectBlock;
