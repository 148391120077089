import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appName: ''
};

export const appParamsSlice = createSlice({
  name: 'appParams',
  initialState,
  reducers: {
    appNameSet: (state, action) => {
      state.appName = action.payload;
    }
  }
});

export const {
  appNameSet
} = appParamsSlice.actions;

export default appParamsSlice.reducer;
