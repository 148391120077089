import styles from './GeometricTable.module.scss';

const GeometricTable = ({ title, params, data }) => {
  const dataSource = Object.entries(data).map(([key, value]) => ({ key, value}))
    .sort((a, b) => parseFloat(a.key) - parseFloat(b.key));

  return (
    <div className={styles.panel}>
      <div className={styles.title}>{title}</div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.label}>Грузоподьемность</div>
          {dataSource.map(item => <div className={styles.label} key={item.key}>{item.key}</div>)}
        </div>
        {params.map(row => (
          <div key={row.label} className={styles.row}>
            <div className={styles.label}>{row.label}</div>
            {dataSource.map(item => <div className={styles.field} key={item.key}>{item.value[row.param]}</div>)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeometricTable;
