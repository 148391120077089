import styles from './CraneTypeMenu.module.scss';
import { useDispatch } from 'react-redux';
import { craneTypeSet } from '../../store/slices/params/metalConstructionSlice';
import { CRANE_TYPE } from '../../common/consts/enums';

const CraneTypeMenu = ({ onChange }) => {
  const dispatch = useDispatch();
  
  const onCraneClickHandler = (craneType) => {
    dispatch(craneTypeSet(craneType));
    onChange(2);
  };
  
  return(
    <div className={styles.container}>
      <div className={styles.title}>Выберите тип крана</div>
      <div className={styles.wrapper}>
        <div className={styles.types}>
          <div
            className={styles.crane}
            onClick={() => onCraneClickHandler(CRANE_TYPE.overheadOneSpan)}
          >
            <img
              src="/img/overheadOneSpan.jpg"
              alt="overheadOneSpan"
              className={styles.img}
            />
            <div className={styles.overlay}>Подвесной однопролетный</div>
          </div>
          <div
            className={styles.crane}
            onClick={() => onCraneClickHandler(CRANE_TYPE.overheadTwoSpan)}
          >
            <img
              src="/img/overheadTwoSpan.jpg"
              alt="overheadTwoSpan"
              className={styles.img}
            />
            <div className={styles.overlay}>Подвесной двухпролетный</div>
          </div>
          <div
            className={styles.crane}
            onClick={() => onCraneClickHandler(CRANE_TYPE.pylon)}
          >
            <img
              src="/img/pylon.jpg"
              alt="pylon"
              className={styles.img}
            />
            <div className={styles.overlay}>Опорный</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CraneTypeMenu;