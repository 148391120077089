import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  feedbackList: []
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    feedbackListSet:  (state, action) => {
      state.feedbackList = [...action.payload];
    }
  }
});

export const {
  feedbackListSet
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
