const CalendarIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 5.13604H1M5.16667 2.65442V1.00001M11.8333 2.65441V1M2.66667 1.82721H2.58333C1.70888 1.82721 1 2.53089 1 3.39891V13.4081C1 14.3218 1.74619 15.0625 2.66667 15.0625H14.3333C15.2538 15.0625 16 14.3218 16 13.4081V3.39891C16 2.53089 15.2911 1.82721 14.4167 1.82721H14.3333M7.66667 1.82721H9.33333" stroke="#3D487C" strokeWidth="0.8" strokeLinecap="round"/>
      <path d="M4.75 7.8625V9.1375C4.75 9.30319 4.88431 9.4375 5.05 9.4375H6.325C6.49069 9.4375 6.625 9.30319 6.625 9.1375V7.8625C6.625 7.69681 6.49069 7.5625 6.325 7.5625H5.05C4.88431 7.5625 4.75 7.69681 4.75 7.8625Z" stroke="#3D487C" strokeWidth="0.6"/>
      <path d="M7.5625 7.8625V9.1375C7.5625 9.30319 7.69681 9.4375 7.8625 9.4375H9.1375C9.30319 9.4375 9.4375 9.30319 9.4375 9.1375V7.8625C9.4375 7.69681 9.30319 7.5625 9.1375 7.5625H7.8625C7.69681 7.5625 7.5625 7.69681 7.5625 7.8625Z" stroke="#3D487C" strokeWidth="0.6"/>
      <path d="M7.5625 10.675V11.95C7.5625 12.1157 7.69681 12.25 7.8625 12.25H9.1375C9.30319 12.25 9.4375 12.1157 9.4375 11.95V10.675C9.4375 10.5093 9.30319 10.375 9.1375 10.375H7.8625C7.69681 10.375 7.5625 10.5093 7.5625 10.675Z" stroke="#3D487C" strokeWidth="0.6"/>
      <path d="M10.375 10.675V11.95C10.375 12.1157 10.5093 12.25 10.675 12.25H11.95C12.1157 12.25 12.25 12.1157 12.25 11.95V10.675C12.25 10.5093 12.1157 10.375 11.95 10.375H10.675C10.5093 10.375 10.375 10.5093 10.375 10.675Z" stroke="#3D487C" strokeWidth="0.6"/>
      <path d="M10.375 7.8625V9.1375C10.375 9.30319 10.5093 9.4375 10.675 9.4375H11.95C12.1157 9.4375 12.25 9.30319 12.25 9.1375V7.8625C12.25 7.69681 12.1157 7.5625 11.95 7.5625H10.675C10.5093 7.5625 10.375 7.69681 10.375 7.8625Z" stroke="#3D487C" strokeWidth="0.6"/>
    </svg>

  );
};

export default CalendarIcon;