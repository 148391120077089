import styles from '../../Profile.module.scss';
import StringBlock from '../../../../common/components/StringBlock/StringBlock';
import { emailSet, phoneSet } from '../../../../store/slices/profile/profileSlice';
import { useSelector } from 'react-redux';

const Contacts = () => {
  const { email, phone } = useSelector(state => state.profile);

  return(
    <div className={styles.block}>
      <div className={styles.input}>
        <StringBlock
          label="Электронная почта"
          onChange={emailSet}
          defaultValue={email}
          placeholder="Введите email"
        />
      </div>
      <div className={styles.input}>
        <StringBlock
          label="Контактный номер"
          onChange={phoneSet}
          defaultValue={phone}
          placeholder="Введите номер"
        />
      </div>
    </div>
  );
};

export default Contacts;
