import styles from './Content.module.scss';
import { useEffect, useRef, useState } from 'react';
import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';
import MetalConstruction from './Sections/MetalConstruction/MetalConstruction';
import ElectroEquip from './Sections/ElectroEquip/ElectroEquip';
import HoistParams from './Sections/HoistParams/HoistParams';
import PowerWire from './Sections/PowerWire/PowerWire';
import AdditionalParams from './Sections/AdditionalParams/AdditionalParams';
import CraneInstallationSizes from './Sections/CraneInstallationSizes/CraneInstallationSizes';
import ExportIcon from '../../../../common/icons/ExportIcon/ExportIcon';
import { useSelector } from 'react-redux';
import QlistSaveIcon from '../../../../common/icons/QlistSaveIcon/QlistSaveIcon';
import PdfDocument from '../../../../common/components/PdfDocument/PdfDocument';
import { getPdfFileName } from '../../utils/getPdfFileName';
import ViewIcon from '../../../../common/icons/ViewIcon/ViewIcon';
import CustomerParams from './Sections/CustomerParams/CustomerParams';

const Content = ({
  activeSubMenuIndex,
  setMarkedSubMenuIndex,
  setActiveSubMenuIndex,
  setActiveContent,
  onViewButtonClick,
  screenWidth,
  guideStep
}) => {
  const [scrollRect, setScrollRect] = useState(null);
  const scrollBlockRef = useRef(null);

  useEffect(() => {
    setScrollRect(scrollBlockRef.current.getBoundingClientRect());
  }, []);

  const pdfParams = {
    metalConstruction: useSelector(state => state.metalConstruction),
    electroEquip: useSelector(state => state.electroEquip),
    hoistParams: useSelector(state => state.hoistParams),
    powerWire: useSelector(state => state.powerWire),
    craneInstallationSizes: useSelector(state => state.craneInstallationSizes),
    additionalParams: useSelector(state => state.additionalParams),
    profile: useSelector(state => state.profile),
    qlistParams: useSelector(state => state.qlistParams),
    customerParams: useSelector(state => state.customerParams)
  };
  const { craneType } = useSelector(state => state.metalConstruction);
  const { id } = useSelector(state => state.qlistParams.current);

  const metalConstructionRef = useRef(null);
  const electroEquipRef = useRef(null);
  const hoistParametersRef = useRef(null);
  const powerWireRef = useRef(null);
  const installationSizesRef = useRef(null);
  const additionalParamsRef = useRef(null);
  const customerParamsRef = useRef(null);

  const sectionBlocks = [
    {
      id: 1,
      label: 'Параметры металлоконструкции крана',
      block: <MetalConstruction />,
      ref: metalConstructionRef
    },
    {
      id: 2,
      label: 'Параметры электрооборудования крана',
      block: <ElectroEquip />,
      ref: electroEquipRef
    },
    {
      id: 3,
      label: 'Параметры тали',
      block: <HoistParams />,
      ref: hoistParametersRef
    },
    {
      id: 4,
      label: 'Параметры токоподвода',
      block: <PowerWire />,
      ref: powerWireRef
    },
    {
      id: 5,
      label: 'Установочные размеры крана',
      block: <CraneInstallationSizes />,
      ref: installationSizesRef
    },
    {
      id: 6,
      label: 'Дополнительные опции',
      block: <AdditionalParams />,
      ref: additionalParamsRef
    },
    {
      id: 7,
      label: 'Заказчик',
      block: <CustomerParams />,
      ref: customerParamsRef
    }
  ];

  const isActiveRef = (rect) => {
    const halfScrollHeight = scrollRect.height / 2;
    const halfScrollBorderHeight = scrollRect.top + halfScrollHeight;

    return rect.top <= halfScrollBorderHeight || (scrollRect.bottom - rect.top) > halfScrollBorderHeight;
  };

  const handleScroll = () => {
    const metalConstructionRect = metalConstructionRef.current.getBoundingClientRect();
    const electroEquipRect = electroEquipRef.current.getBoundingClientRect();
    const hoistParametersRect = hoistParametersRef.current.getBoundingClientRect();
    const powerWireRect = powerWireRef.current.getBoundingClientRect();
    const installationSizesRect = installationSizesRef.current.getBoundingClientRect();
    const additionalParamsRect = additionalParamsRef.current.getBoundingClientRect();
    const customerParamsRect = customerParamsRef.current.getBoundingClientRect();

    if (isActiveRef(metalConstructionRect)) {
      setMarkedSubMenuIndex(1);
    }

    if (isActiveRef(electroEquipRect)) {
      setMarkedSubMenuIndex(2);
    }

    if (isActiveRef(hoistParametersRect)) {
      setMarkedSubMenuIndex(3);
    }

    if (isActiveRef(powerWireRect)) {
      setMarkedSubMenuIndex(4);
    }

    if (isActiveRef(installationSizesRect)) {
      setMarkedSubMenuIndex(5);
    }

    if (isActiveRef(additionalParamsRect)) {
      setMarkedSubMenuIndex(6);
    }

    if (isActiveRef(customerParamsRect)) {
      setMarkedSubMenuIndex(7);
    }
  };

  useEffect(() => {
    if (activeSubMenuIndex.next) {
      sectionBlocks[activeSubMenuIndex.next - 1].ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setActiveSubMenuIndex({ current: activeSubMenuIndex.next, next: null });
    }
  }, [activeSubMenuIndex]);

  const craneId = id ? `/ опросный лист №${id}` : '';

  const pdfStyles = StyleSheet.create({
    viewer: {
      width: '100%',
      height: '90%'
    }
  });

  return (
    <div className={styles.container}>
      {(screenWidth >= 768 || !screenWidth) && (
        <div className={guideStep === 3 ? `${styles.header} ${styles.guide}` : styles.header}>
          <div>{`Мостовой ${craneType.value.toLowerCase()} кран ${craneId}`}</div>
          <div className={styles.wrapper}>
            <div
              onClick={() => onViewButtonClick(true)}
              role="presentation"
              className={styles.buttonContainer}
            >
              <ViewIcon/>
              <div className={styles.button}>
                Просмотреть
              </div>
            </div>
            <PDFDownloadLink
              style={pdfStyles.color}
              document={<PdfDocument params={pdfParams}/>}
              fileName={getPdfFileName(craneType.value)}
              className={styles.buttonContainer}
            >
              <ExportIcon/>
              <div className={styles.button}>
                Скачать
              </div>
            </PDFDownloadLink>
            <div
              onClick={() => setActiveContent('qlistSaveForm')}
              role="presentation"
              className={styles.buttonContainer}
            >
              <QlistSaveIcon/>
              <div className={styles.button}>
                Сохранить
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={guideStep === 2 ? `${styles.content} ${styles.guide}` : styles.content}>
        <div
          className={styles.scroll}
          onScroll={handleScroll}
          ref={scrollBlockRef}
        >
          {sectionBlocks.map(item => (
            <div
              ref={item.ref}
              className={styles.section}
              key={item.id}
            >
              <div className={styles.param}>
                <div className={styles.label}>
                  {item.label}
                </div>
                {item.block}
              </div>
            </div>
          ))}
        </div>
        {screenWidth <= 768 && (
          <div className={styles.save}>
            <PDFDownloadLink
              style={pdfStyles.color}
              document={<PdfDocument params={pdfParams} />}
              fileName={getPdfFileName(craneType.value)}
              className={styles.buttonContainer}
            >
              <ExportIcon />
              <div className={styles.button}>
                Скачать
              </div>
            </PDFDownloadLink>
            <div
              onClick={() => setActiveContent('qlistSaveForm')}
              role="presentation"
              className={styles.buttonContainer}
            >
              <QlistSaveIcon />
              <div className={styles.button}>
                Сохранить
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
