import styles from './News.module.scss';
import { useEffect, useRef, useState } from 'react';
import NewsLog from './Newslog/Newslog';
import Changelog from './Changelog/Changelog';
import CloseIcon from './icons/CloseIcon/CloseIcon';

const News = ({ setActiveLayoutContent, newsTab }) => {
  const [activeContent, setActiveContent] = useState(newsTab);
  const [isChecked, setIsChecked] = useState(false);
  const [width, setWidth] = useState(null);
  const containerRef = useRef();

  const onChangeCheckbox = (value) => {
    setIsChecked(value);

    if(value){
      localStorage.setItem('isNewsShow', 'false');
    }
    if(!value){
      localStorage.setItem('isNewsShow', 'true');
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      setWidth(width);
    }
  }, [containerRef]);

  return(
    <div
      className={styles.container}
      ref={containerRef}
    >
      <div className={styles.left}>
        <div className={styles.title}>
          <div>Что нового?</div>
          {width >= 768 && (<div className={styles.subtitle}>Мы постоянно вносим изменения в работу сайта для улучшения работы и удобства пользователей</div>)}
        </div>
        {width >= 768 && (<div
          className={styles.checkbox}
          onClick={() => onChangeCheckbox(!isChecked)}
        >
          <input
            type="checkbox"
            checked={isChecked}
            readOnly={true}
          />
          <div>Больше не показывать</div>
        </div>)}
      </div>
      <div className={styles.content}>
        <div className={styles.buttonbar}>
          <div
            className={`${styles.button} ${activeContent === 'news' && styles.active}`}
            onClick={() => setActiveContent('news')}
          >Новости</div>
          <div
            className={`${styles.button} ${activeContent === 'changelog' && styles.active}`}
            onClick={() => setActiveContent('changelog')}
          >Список изменений</div>
        </div>
        {activeContent === 'news' && (
          <NewsLog />
        )}
        {activeContent === 'changelog' && (
          <Changelog />
        )}
      </div>
      <div
        className={styles.close}
        onClick={() => setActiveLayoutContent('craneType')}
      >
        <CloseIcon />
      </div>
    </div>
  );
};

export default News;