import styles from '../QlistSaveForm.module.scss';
import StringBlock from '../../StringBlock/StringBlock';
import { useSelector } from 'react-redux';
import { customerSet } from '../../../../store/slices/qlistParams/qlistParamsSlice';
import { CRANE_TYPE } from '../../../consts/enums';

const QlistParams = () => {
  const { company: customerCompany, contact: customerContact } = useSelector(state => state.customerParams);
  const { company: userCompany } = useSelector(state => state.profile);
  const {
    craneType,
    liftingHeight,
    oneSpanLength,
    twoSpanLength,
    workingModeByIso
  } = useSelector(state => state.metalConstruction);

  const liftingHeightValue = liftingHeight.value !== 'другое'
    ? liftingHeight.value
    : liftingHeight.customValue;

  const getSpanLength = () => {
    if (craneType.value === CRANE_TYPE.overheadTwoSpan) {
      return twoSpanLength.value !== 'другое' ? twoSpanLength.value : twoSpanLength.customValue;
    }

    return oneSpanLength.value !== 'другое' ? oneSpanLength.value : oneSpanLength.customValue;
  };

  const workingModeByIsoValue = workingModeByIso.value !== 'другое'
    ? workingModeByIso.value
    : workingModeByIso.customValue;

  return(
    <div className={styles.block}>
      <div className={styles.input}>
        <StringBlock
          label="Тип крана"
          defaultValue={craneType.value}
          disabled
        />
      </div>
      <div className={styles.input}>
        <StringBlock
          label="Пролет"
          defaultValue={getSpanLength}
          disabled
        />
      </div>
      <div className={styles.input}>
        <StringBlock
          label="Высота подьема, м (H)"
          defaultValue={liftingHeightValue}
          disabled
        />
      </div>
      <div className={styles.input}>
        <StringBlock
          label="Режим работы"
          defaultValue={workingModeByIsoValue}
          disabled
        />
      </div>
      <div className={styles.input}>
        <StringBlock
          label="Заказчик"
          onChange={customerSet}
          defaultValue={
            customerCompany.name.value
            || customerContact.name.value
            || userCompany
          }
          placeholder="Введите название компании-заказчика"
        />
      </div>
    </div>
  );
};

export default QlistParams;
