import StringBlock from '../../../../common/components/StringBlock/StringBlock';
import { nameSet, companySet } from '../../../../store/slices/profile/profileSlice';
import { useSelector } from 'react-redux';
import styles from '../../Profile.module.scss';

const Person = () => {
  const { name, company } = useSelector(state => state.profile);

  return(
    <div className={styles.block}>
      <div className={styles.input}>
        <StringBlock
          label="Ваше имя"
          onChange={nameSet}
          defaultValue={name}
          placeholder="Введите имя"
        />
      </div>
      <div className={styles.input}>
        <StringBlock
          label="Имя компании"
          onChange={companySet}
          defaultValue={company}
          placeholder="Введите имя компании"
        />
      </div>
    </div>
  );
};

export default Person;
