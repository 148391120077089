import styles from './Layout.module.scss';
import SideMenu from './SideMenu/SideMenu';
import {useState, useEffect, useRef} from 'react';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import Content from '../modules/singleGirder/components/Content/Content';
import Profile from '../modules/Profile/Profile';
import QuestionLists from '../modules/QuestionLists/QuestionLists';
import GostParamsSection from '../modules/GostParamsSection/GostParamsSection';
import LogoPanel from './LogoPanel/LogoPanel';
import { useSelector } from 'react-redux';
import AuthMenu from './AuthMenu/AuthMenu';
import { checkAuth } from '../service/auth';
import NotifyBlock from '../common/components/NotifyBlock/NotifyBlock';
import CraneTypeMenu from '../modules/CraneTypeMenu/CraneTypeMenu';
import QlistSaveForm from '../common/components/QlistSaveForm/QlistSaveForm';
import { qlistArrayGet } from '../service/qlist';
import News from './News/News';
import Feedback from '../modules/Feedback/Feedback';
import { feedbackArrayGet } from '../service/feedback';
import PdfViewModal from '../common/components/PdfViewModal/PdfViewModal';
import packageJson from '../../package.json';
import { newsListGet } from '../service/news';
import Guide from '../common/components/Guide/Guide';
import Calculation from '../modules/Calculation/Calculation';

const Layout = () => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [activeContent, setActiveContent] = useState(localStorage.getItem('lastSide'));
  const [markedSubMenuIndex, setMarkedSubMenuIndex] = useState();
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState({
    current: 1,
    next: null
  });
  const [isShowViewModal, setIsShowViewModal] = useState(false);
  const [isSideOpen, setIsSideOpen] = useState();
  const [newsTab, setNewsTab] = useState();
  const [containerWidth, setContainerWidth] = useState();
  const [guideStep, setGuideStep] = useState(null);

  const { isAuth, email, isActivated } = useSelector(state => state.profile);
  const { notify } = useSelector(state => state.modals);
  const { qlistArray } = useSelector(state => state.qlistParams);
  const { feedbackList } = useSelector(state => state.feedback);
  const { newsList } = useSelector(state => state.news);

  const ref = useRef();

  useEffect(() => {
    if (!localStorage.getItem('lastNewsId') && newsList.length > 0){
      localStorage.setItem('lastNewsId', `${newsList[0].id}`);
      setActiveContent('news');
      setNewsTab('news');
    }

    if ((localStorage.getItem('lastNewsId') && newsList.length > 0) && localStorage.getItem('lastNewsId') !== `${newsList[0].id}`) {
      localStorage.setItem('lastNewsId', `${newsList[0].id}`);
      if (localStorage.getItem('isNewsShow') !== 'false') {
        setActiveContent('news');
        setNewsTab('news');
      }
    }
  }, [newsList]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      checkAuth().then();
    }

    if (!localStorage.getItem('isSideOpen')) {
      localStorage.setItem('isSideOpen', 'true');
      setIsSideOpen(true);
    }

    if (localStorage.getItem('isSideOpen') === 'true') {
      setIsSideOpen(true);
    }

    if (localStorage.getItem('isSideOpen') === 'false') {
      setIsSideOpen(false);
    }

    if (!localStorage.getItem('lastSide')) {
      localStorage.setItem('lastSide','craneType');
      if (!activeContent) {
        setActiveContent('craneType');
        setActiveMenuIndex(1);
      }
    }

    if (localStorage.getItem('lastSide') === 'craneType') {
      setActiveMenuIndex(1);
    }

    if (localStorage.getItem('lastSide') === 'specifications') {
      setActiveMenuIndex(2);
    }

    if (localStorage.getItem('lastSide') === 'gosts') {
      setActiveMenuIndex(3);
    }

    if (!localStorage.getItem('userVersion')){
      localStorage.setItem('userVersion', `${packageJson.version}`);
      localStorage.setItem('isNewsShow', 'true');
      setActiveContent('news');
      setNewsTab('changelog');
    }

    if (localStorage.getItem('userVersion') && localStorage.getItem('userVersion') !== `${packageJson.version}`) {
      localStorage.setItem('userVersion', `${packageJson.version}`);
      if (localStorage.getItem('isNewsShow') !== 'false') {
        setActiveContent('news');
        setNewsTab('changelog');
      }
    }

    if (ref.current) {
      const width = ref.current.getBoundingClientRect().width;
      setContainerWidth(width);
    }
  }, []);

  useEffect(() => {
    if (email) {
      qlistArrayGet(email).then();
      feedbackArrayGet(email).then();
      newsListGet().then();
    }
  }, [email]);

  useEffect(() => {
    guideStep === 1 && setActiveContent('specifications');
    guideStep === 5 && setActiveContent('profile');
    guideStep === 6 && setActiveContent('questionList');
    guideStep === 7 && setActiveContent('gosts');
    guideStep === 8 && setActiveContent('feedback');
    guideStep === 9 && setActiveContent('craneType');
  }, [guideStep]);

  useEffect(() => {
    !isActivated && setGuideStep(1);
  }, [isActivated]);

  useEffect(() => {
    if(ref.current){
      const width = ref.current.getBoundingClientRect().width;
      setContainerWidth(width);

      if (width <= 768 && activeContent !== 'specifications') {
        setIsSideOpen(false);
      }
    }

    if (activeContent !== localStorage.getItem('lastSide')) {
      localStorage.setItem('lastSide',`${activeContent}`);
    }
  }, [activeContent]);

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.getBoundingClientRect().width;

      if (width <= 768) {
        setIsSideOpen(false);
      }
    }
  }, [activeSubMenuIndex]);

  if (isSideOpen) {
    localStorage.setItem('isSideOpen', 'true');
  }
  if (isSideOpen === false) {
    localStorage.setItem('isSideOpen', 'false');
  }

  if (!isAuth) {
    return (
      <>
        {notify.params.isShow && <NotifyBlock params={notify.params} />}
        <AuthMenu />
      </>
    );
  }

  const onMenuClickHandler = (menuItem) => {
    setActiveMenuIndex(menuItem);

    if (menuItem === 1) {
      setActiveContent('craneType');
    }

    if (menuItem === 2) {
      setActiveContent('specifications');
    }

    if (menuItem === 3) {
      setActiveContent('gosts');
    }

    if (menuItem === 4) {
      setActiveContent('calculation');
    }
  };

  const onProfileMenuClick = (menuItem) => {
    setActiveContent(menuItem);
    setActiveMenuIndex(null);
  };

  return (
    <div ref={ref} className={styles.container}>
      {guideStep && <Guide guideStep={guideStep} setGuideStep={setGuideStep}/>}
      {notify.params.isShow && <NotifyBlock params={notify.params} />}
      {isShowViewModal && <PdfViewModal onClose={setIsShowViewModal}/>}
      <div className={`${guideStep === 4 && styles.guide} ${!isSideOpen && styles.close} ${styles.side}`}>
        <LogoPanel isSideOpen={isSideOpen} setIsSideOpen={setIsSideOpen}/>
        <SideMenu
          activeMenuIndex={activeMenuIndex}
          onMenuClick={onMenuClickHandler}
          onSubMenuClick={setActiveSubMenuIndex}
          markedSubMenuIndex={markedSubMenuIndex}
          isLabelShow={isSideOpen}
        />
        <ProfileMenu
          onMenuClick={onProfileMenuClick}
          isSideOpen={isSideOpen}
          guideStep={guideStep}
        />
      </div>
      <div className={styles.panel}>
        {activeContent === 'news' && (<News setActiveLayoutContent={setActiveContent} newsTab={newsTab} />)}
        {activeContent === 'craneType' && (<CraneTypeMenu onChange={onMenuClickHandler} />)}
        {activeContent === 'specifications' && (
          <div className={styles.content}>
            <Content
              activeSubMenuIndex={activeSubMenuIndex}
              setMarkedSubMenuIndex={setMarkedSubMenuIndex}
              setActiveSubMenuIndex={setActiveSubMenuIndex}
              setActiveContent={setActiveContent}
              onViewButtonClick={setIsShowViewModal}
              screenWidth={containerWidth}
              guideStep={guideStep}
            />
          </div>
        )}
        {activeContent === 'profile' && (
          <Profile guideStep={guideStep}/>
        )}
        {activeContent === 'questionList' && (
          <QuestionLists
            qlistArray={qlistArray || []}
            onViewButtonClick={setIsShowViewModal}
            guideStep={guideStep}
            setActiveContent={setActiveContent}
          />
        )}
        {activeContent === 'gosts' && (
          <div className={styles.guide}>
            <div className={styles.title}>
              Справочник ГОСТов
            </div>
            <GostParamsSection />
          </div>
        )}
        {activeContent === 'calculation' && <Calculation />}
        {activeContent === 'qlistSaveForm' && (
          <QlistSaveForm onClose={() => setActiveContent('specifications')}/>
        )}
        {activeContent === 'feedback' && (
          <Feedback data={[...feedbackList]} guideStep={guideStep} />
        )}
      </div>
    </div>
  );
};

export default Layout;
