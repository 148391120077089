import { useSelector } from 'react-redux';
import ParameterBlock from '../../../../../../common/components/ParameterBlock/ParameterBlock';
import {
  companyEmailSet,
  companyInnSet,
  companyNameSet, companyParamsSet,
  companyPhoneSet, contactEmailSet, contactNameSet, contactParamsSet, contactPhoneSet
} from '../../../../../../store/slices/params/customerParamsSlice';
import SelectBlock from '../../../../../../common/components/SelectBlock/SelectBlock';
import { companyFind, contactFind } from '../../../../../../service/find';

const CustomerParams = () => {
  const {
    company,
    companyList,
    contact,
    contactList
  } = useSelector(state => state.customerParams);


  return (
    <div>
      <ParameterBlock
        title="Компания"
        isValid={company.name.value}
      >
        <SelectBlock
          placeholder="Введите название компании"
          param="name"
          onChange={companyNameSet}
          defaultValue={company.name.value}
          onEntityParamsChange={companyParamsSet}
          dropDownList={companyList}
          onSearchEntity={companyFind}
        />
      </ParameterBlock>
      <ParameterBlock
        title={company.inn.label}
        isValid={company.inn.value}
      >
        <SelectBlock
          placeholder="Введите ИНН компании"
          param="inn"
          onChange={companyInnSet}
          defaultValue={company.inn.value}
          onEntityParamsChange={companyParamsSet}
          dropDownList={companyList}
          onSearchEntity={companyFind}
        />
      </ParameterBlock>
      <ParameterBlock
        title={company.phone.label}
        isValid={company.phone.value}
      >
        <SelectBlock
          placeholder="Введите телефоны компании"
          param="phone"
          onChange={companyPhoneSet}
          defaultValue={company.phone.value}
          onEntityParamsChange={companyParamsSet}
          dropDownList={companyList}
          onSearchEntity={companyFind}
        />
      </ParameterBlock>
      <ParameterBlock
        title={company.email.label}
        isValid={company.email.value}
      >
        <SelectBlock
          placeholder="Введите E-Mail компании"
          param="email"
          onChange={companyEmailSet}
          defaultValue={company.email.value}
          onEntityParamsChange={companyParamsSet}
          dropDownList={companyList}
          onSearchEntity={contactFind}
        />
      </ParameterBlock>

      <ParameterBlock
        title="Контакт"
        isValid={contact.name.value}
      >
        <SelectBlock
          placeholder="Введите ФИО контакта"
          param="name"
          onChange={contactNameSet}
          defaultValue={contact.name.value}
          onEntityParamsChange={contactParamsSet}
          dropDownList={contactList}
          onSearchEntity={contactFind}
        />
      </ParameterBlock>
      <ParameterBlock
        title={contact.phone.label}
        isValid={contact.phone.value}
      >
        <SelectBlock
          placeholder="Введите телефоны контакта"
          param="phone"
          onChange={contactPhoneSet}
          defaultValue={contact.phone.value}
          onEntityParamsChange={contactParamsSet}
          dropDownList={contactList}
          onSearchEntity={contactFind}
        />
      </ParameterBlock>
      <ParameterBlock
        title={contact.email.label}
        isValid={contact.email.value}
      >
        <SelectBlock
          placeholder="Введите E-Mail контакта"
          param="email"
          onChange={contactEmailSet}
          defaultValue={contact.email.value}
          onEntityParamsChange={contactParamsSet}
          dropDownList={contactList}
          onSearchEntity={contactFind}
        />
      </ParameterBlock>
    </div>
  );
};

export default CustomerParams;
