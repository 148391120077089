import styles from './ParameterBlock.module.scss';

const ParameterBlock = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{props.title}</div>
      </div>
      {props.children}
      <div className={styles.footer}>
        <div className={styles.success}>
          {props.isValid && 'Заполнено'}
        </div>
      </div>
    </div>
  );
};

export default ParameterBlock;
