import styles from './PasswordResetMenu.module.scss';
import { useEffect, useState } from 'react';
import { notifySet } from '../../store/slices/modals/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPasswordReset } from '../../service/user';
import NotifyBlock from '../../common/components/NotifyBlock/NotifyBlock';

const PasswordResetMenu = () => {
  const [token, setToken] = useState('');
  const [inputValue, setInputValue] = useState({
    password: '',
    confirmPassword: ''
  });
  const dispatch = useDispatch();
  const { notify } = useSelector(state => state.modals);
  
  useEffect(() => {
    const url = window.location.href;

    const passwordResetString = '/reset/';
    const passwordResetIndex = url.indexOf(passwordResetString);
    const passwordResetToken = url.substring(passwordResetIndex + passwordResetString.length);

    if (passwordResetIndex === -1 || !passwordResetToken.length) {
      window.location.replace('/');
    }

    setToken(passwordResetToken);
  },[]);
  
  const onInputChangeHandler = (key, value) => {
    setInputValue({
      ...inputValue,
      [key]: value
    });
  };

  const errorMessage = (message) => {
    dispatch(notifySet({
      type: 'error',
      message: message,
      isShow: true
    }));
  };

  const onConfirmButtonClick = () => {
    if (!inputValue.password) {
      errorMessage('Не указан пароль');

      return;
    }

    if (inputValue.password.length < 5) {
      errorMessage('Длина пароля должна быть больше пяти символов');

      return;
    }

    if (!inputValue.confirmPassword) {
      errorMessage('Подтвердите пароль');

      return;
    }

    if (inputValue.password !== inputValue.confirmPassword) {
      errorMessage('Пароли не совпадают');

      return;
    }

    confirmPasswordReset(token, inputValue.password).then();
  };

  return (
    <div className={styles.container}>
      {notify.params.isShow && <NotifyBlock params={notify.params} />}
      <div className={styles.panel}>
        <div>Введите новый пароль</div>
        <input
          className={styles.input}
          type="password"
          placeholder="Введите пароль"
          value={inputValue.password}
          onChange={(e) => onInputChangeHandler('password', e.target.value)}
        />
        <input
          className={styles.input}
          type="password"
          placeholder="Подтвердите пароль"
          value={inputValue.confirmPassword}
          onChange={(e) => onInputChangeHandler('confirmPassword', e.target.value)}
        />
        <div
          className={styles.button}
          onClick={onConfirmButtonClick}
        >
          OK
        </div>
      </div>
      <img
        className={styles.banner}
        src="/img/auth.jpg"
        alt="auth"
      />
    </div>
  );
};

export default PasswordResetMenu;
