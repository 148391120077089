import {StyleSheet, Text, View} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: '15px 0'
  },

  field: {
    width: '100%',
    flexDirection: 'row',
    wrap: 'false'
  },

  label: {
    borderTop: '1px solid grey',
    borderLeft: '2px solid grey',
    borderBottom: '1px solid grey',
    padding: '5px 10px',
    width: '65%',
  },

  value: {
    borderLeft: '1px solid grey',
    borderRight: '2px solid grey',
    borderBottom: '1px solid grey',
    borderTop: '1px solid grey',
    padding: '5px 10px',
    width: '35%',

    wordWrap: 'break-word',
    textAlign: 'center',
  },

  title: {
    border: '2px solid grey',
    padding: '5px 10px',
    width: '100%',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '15px',
  }
});

const Params = ({ params }) => {
  const getIsShowParam = (field, group) => {
    const isShowParam = Object.entries(field.dependencies).map(([key, value]) => !value.includes(group[key].value));

    return isShowParam.includes(false);
  };

  const arrayGen = (header, group) => {
    const editedGroup = Object.values(group).map((item, index) => {
      return (
        <>
          {(!item.dependencies || getIsShowParam(item, group)) && (
            <View style={styles.field} wrap={false} key={index}>
              <Text style={styles.label}>{item.label}</Text>
              <Text style={styles.value}>{item.customValue ? item.customValue : item.value}</Text>
            </View>
          )}
        </>
      );
    });

    return [ header, ...editedGroup];
  };

  const titleGen = (title) => {
    return (
      <>
        <View style={styles.field} wrap={false}>
          <Text style={styles.title}>{title}</Text>
        </View>
      </>
    );
  };

  const metalConstructionHeader = titleGen('Металлоконструкция');
  const electroEquipHeader = titleGen('Электрооборудование крана');
  const hoistParamsHeader = titleGen('Требования для электрической тали');
  const powerWireHeader = titleGen('Параметры токоподвода');
  const craneInstallationSizesHeader = titleGen('Установочные размеры крана');
  const additionalParams = titleGen('Дополнительные опции на кран');


  const commonArray = [
    ...arrayGen(metalConstructionHeader, params.metalConstruction),
    ...arrayGen(electroEquipHeader, params.electroEquip),
    ...arrayGen(hoistParamsHeader, params.hoistParams),
    ...arrayGen(powerWireHeader, params.powerWire),
    ...arrayGen(craneInstallationSizesHeader, params.craneInstallationSizes),
    ...arrayGen(additionalParams, params.additionalParams)
  ];

  return(
    <View style={styles.column}>
      {commonArray.map((item, index) => {
        return (
          <View key={index}>
            {item}
          </View>
        );
      })}
    </View>
  );
};

export default Params;
