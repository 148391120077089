import styles from './PaginationPanel.module.scss';
import LeftIcon from './icons/LeftIcon/LeftIcon';
import DotsBottomIcon from './icons/DotsBottomIcon/DotsBottomIcon';
import RightIcon from './icons/RightIcon/RightIcon';

const PaginationPanel = ({
  activePage,
  totalPages,
  prevPage,
  nextPage,
  setPage,
  isLeftDotsVisible,
  isRightDotsVisible,
  pagesToShow
}) => {
  const getButtonStyle = (pageNumber) => activePage === pageNumber
    ? `${styles.page} ${styles.active}`
    : `${styles.page}`;

  return (
    <div className={styles.container}>
      <div
        className={styles.page}
        role="presentation"
        onClick={prevPage}
      >
        <LeftIcon />
      </div>
      <div
        className={getButtonStyle(1)}
        role="presentation"
        onClick={() => setPage(1)}
      >
        1
      </div>
      {isLeftDotsVisible && (
        <div className={styles.dots}>
          <DotsBottomIcon />
        </div>
      )}
      {pagesToShow.map(item => {
        return (
          <div
            key={item}
            className={getButtonStyle(item)}
            role="presentation"
            onClick={() => setPage(item)}
          >
            {item}
          </div>
        );
      })}
      {isRightDotsVisible && (
        <div className={styles.dots}>
          <DotsBottomIcon />
        </div>
      )}
      <div
        className={getButtonStyle(totalPages)}
        role="presentation"
        onClick={() => setPage(totalPages)}
      >
        {totalPages}
      </div>
      <div
        className={styles.page}
        role="presentation"
        onClick={nextPage}
      >
        <RightIcon />
      </div>
    </div>
  );
};

export default PaginationPanel;